import React, {useContext, useState} from 'react';
import {Alert, IconButton, Snackbar, CircularProgress} from "@mui/material";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {MoreVert} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import PopUpModal from "../../../../../../common/PopUpModal";
import {httpsCallable} from "firebase/functions";
import {FirebaseContext} from "../../../../../../../contexts/FirebaseContext";

function PopOverCommentMenu({docId, commentId}) {
    const appFirebase = useContext(FirebaseContext);
    const deleteCommentFunction = httpsCallable(appFirebase.function, "document-deleteComment");
    const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
    const [deletedCommentSuccess, setDeletedCommentSuccess] = useState(false);
    const [loading, setLoading] = useState(false); // <-- Add this for loading state

    return (
        <div>
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        <IconButton
                            color="primary"
                            disableRipple={true}
                            sx={{color: "#6d6d6d"}}
                            {...bindTrigger(popupState)}
                            className="float-right"
                        >
                            <MoreVert/>
                        </IconButton>
                        <Menu {...bindMenu(popupState)} disableScrollLock={true}>
                            <div
                                className="text-grays-headingTextGray flex font-grotesk-normal hover:bg-grays-hoverGray pt-1 pb-2 pr-2 pl-1 cursor-pointer">
                                <div className="pl-3 pt-1">
                                    {
                                        loading ? <CircularProgress size={20}/>
                                            :
                                            <DeleteForeverIcon fontSize={"small"}/>
                                    }
                                </div>
                                <button
                                    className="pt-1 px-3"
                                    onClick={() => setOpenDeletePopUp(true)}
                                    disabled={loading} // <-- Add this to disable button during loading
                                >
                                    Delete comment {/* <-- Display spinner during loading */}
                                </button>
                            </div>
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>
            <PopUpModal
                open={openDeletePopUp}
                handleClose={() => {
                    setOpenDeletePopUp(false)
                }}
                text={{
                    heading: "Delete Comment",
                    body: ["Are you sure you want to delete this message?", "This cannot be undone."]
                }}
                buttonOnClick={() => {
                    setLoading(true); // <-- Start loading when delete action starts
                    deleteCommentFunction({docId: docId, commentId: commentId})
                        .then(result => {
                            setDeletedCommentSuccess(true);
                            setLoading(false); // <-- Stop loading when delete action is successful
                        })
                        .catch(error => {
                            const code = error.code;
                            const message = error.message;
                            const details = error.details;
                            console.error(`Error deleting comment. Code: ${code}. Message: ${message}. Details: ${details}`);
                            setLoading(false); // <-- Stop loading when there's an error
                        });
                }}
                buttonText={"Delete"}>
            </PopUpModal>

            <Snackbar
                open={deletedCommentSuccess}
                onClose={() => setDeletedCommentSuccess(false)}
                autoHideDuration={6000}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            >
                <Alert
                    severity="success"
                    onClose={() => setDeletedCommentSuccess(false)}
                >
                    <div className="font-grotesk-normal">
                        Successfully deleted comment.
                    </div>
                </Alert>
            </Snackbar>
        </div>
    );
}

export default PopOverCommentMenu;
