import React, {useEffect, useState} from 'react';
import {onValue, ref} from "firebase/database";
import DownloadIcon from "@mui/icons-material/Download";
import {IconButton} from "@mui/material";
import {FmdGood} from "@mui/icons-material";
import "../../../../viewers/Annotations/annotation.scss"
import Point3DComment from "./Point3DComment";
import ChatCommentAuthor from "./ChatCommentAuthor";
import moment from 'moment';

function Comment(props) {
    const {commentId, database, docId, uid} = props;
    const [authorName, setAuthorName] = useState("");
    const [authorUid, setAuthorUid] = useState("");
    const [text, setText] = useState("");
    const [timestamp, setTimeStamp] = useState("");
    const [formattedDate, setFormattedDate] = useState("");
    const [commentData, setCommentData] = useState({});
    const [isAuthorCurrentUser, setIsAuthorCurrentUser] = useState(false);
    const [showTimeStamp, setShowTimeStamp] = useState(true);


    useEffect(() => {
        const commentUrl = '/comments/' + docId + '/' + commentId;
        const commentRef = ref(database, commentUrl);
        onValue(commentRef, (snapshot) => {
            const currentComment = snapshot.val();
            setAuthorUid(currentComment.createdBy);
            (uid === currentComment.createdBy) ? setIsAuthorCurrentUser(true) : setIsAuthorCurrentUser(false);
            setText(currentComment.text);
            setTimeStamp(currentComment['dateCreated']);
            setCommentData(currentComment);
            // setFormattedDate(new Date(currentComment['dateCreated']).toDateString());

            const date = moment(currentComment['dateCreated']);
            const formattedDate = date.format('MMM D h:mm A');
            setFormattedDate(formattedDate);

        });
    }, []);

    useEffect(() => {

    }, [isAuthorCurrentUser]);

    useEffect(() => {
        // return () => {
        if (authorUid) {
            onValue(ref(database, ('/users/' + authorUid)), (snapshotName => {
                setAuthorName(snapshotName.val().firstname +
                    (snapshotName.val().lastname ? (" " + snapshotName.val().lastname) : ""));
            }))
        }
    }, [authorUid]);
    return (
        <div>
            <div key={commentId} onClick={() => setShowTimeStamp(!showTimeStamp)}>
                {
                    commentData['point'] === true ?
                        <Point3DComment
                            authorName={authorName}
                            text={text}
                            formattedDate={formattedDate}
                            commentId={commentId}
                            docId={docId}
                            isAuthorCurrentUser={isAuthorCurrentUser}
                        />
                        :
                        <>
                            <CommentBubble
                                isAuthorCurrentUser={isAuthorCurrentUser}
                                showTimeStamp={showTimeStamp}
                                formattedDate={formattedDate}
                                text={text}
                                authorName={authorName}
                                commentId={commentId}
                                docId={docId}
                            />
                        </>
                }

            </div>
        </div>
    );
}

export default Comment;

function CommentBubble(props) {
    const {
        isAuthorCurrentUser,
        formattedDate,
        text,
        authorName,
        commentId,
        docId
    } = props;
    const displayAuthor = isAuthorCurrentUser ? "You" : authorName;
    return (
        <div>
            {
                isAuthorCurrentUser ?
                    <ChatCommentAuthor
                        displayAuthor={displayAuthor}
                        formattedDate={formattedDate}
                        text={text}
                        commentId={commentId}
                        docId={docId}
                    />
                    :
                    <div className="mr-20">
                        <div className="m-3 my-5 mx-5">
                            <div className="flex flex-row mx-5">
                                <div className="block text-gray-100 text-xs mx-1">{displayAuthor}</div>
                                <div className="block text-gray-100 text-xs mx-1">⋅</div>
                                <div className="block text-gray-100 text-xs mx-1">{formattedDate}</div>
                            </div>
                            <div className="bg-bubbleGray rounded-2xl p-3">
                                <div className="flex  justify-between">
                                    <div className="flex flex-row">
                                        <div className="ml-2 mr-6">
                                            <span className="select-text block text-white font-grotesk-normal">{text}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
            }

        </div>
    );

}


function CommentAuthor(props) {
    const {isAuthorCurrentUser, database, createdBy, point} = props;
    const [author, setAuthor] = useState("");
    useEffect(() => {
        onValue(ref(database, ('/users/' + createdBy)), (snapshotName => {
            setAuthor(snapshotName.val().firstname + " " + snapshotName.val().lastname);
        }))
    }, [createdBy]);
    return (
        <div>
            {point ? <></> :
                <>
                    {isAuthorCurrentUser ?
                        <div className="author outgoing">
                            You
                        </div>
                        :
                        <div className="author incoming">
                            {author}
                        </div>
                    }</>
            }

        </div>
    );
}

function PointCommentAuthor(props) {

}