import {databaseTypesConstants as dbConst} from "./databaseConstants";
import {get, ref} from "firebase/database";

export const isDocumentPublic = async (appFirebase, docId) => {
    if (appFirebase && docId) {
        const isPublicQuery = `/${dbConst.DOCUMENT_ACCESS.name}/${docId}/public`;
        const snapshot = await get(ref(appFirebase.database, isPublicQuery));
        if (snapshot.exists()) {
            return snapshot.val();
        }
    }
    return null;
}

export const getUserPlan = async (appFirebase) => {
    if (appFirebase) {
        const userId = appFirebase.auth.currentUser.uid;
        if (userId) {
            const query = `/${dbConst.SUBSCRIPTION.name}/${userId}/${dbConst.SUBSCRIPTION.options.PLAN}`;
            const snapshot = await get(ref(appFirebase.database, query));
            if (snapshot.exists()) {
                return snapshot.val();
            }
        }
    }
    return null;
}

export const formatBytes = (x) => {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
        n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}