import Joyride, {ACTIONS, EVENTS, STATUS} from 'react-joyride';
import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {databaseTypesConstants as dbConst} from "../../../firebase/databaseConstants";
import {FirebaseContext} from "../../../contexts/FirebaseContext";
import {addDemoFile, setTourInteractionFlag} from "./onBoardingTourFunctions";
import {CircularProgress, createTheme, ThemeProvider} from "@mui/material";
import {modalMaterialTheme} from "../muiThemes";

const PAGE_INDEX_NAVIGATE_VIEWER = 5;
const PAGE_INDEX_NAVIGATE_VIEWER_DEMO_EXISTS = 4;
const PAGE_INDEX_POSITION_DEMO = 3;
const PAGE_INDEX_CREATE_DEMO_FILE = 3;


function OnboardingTour({startOnboardingTour = false, demoDocId = null}) {
    const appFirebase = useContext(FirebaseContext);
    const [tourState, setTourState] = useState({
        run: startOnboardingTour,
        continuous: true,
    });
    const [canProceed, setCanProceed] = useState(null);
    const [stepIndex, setStepIndex] = useState(0);
    const stepsDefault = [
        {
            content:
                <div>
                    <h1 className="pb-2 text-lg font-grotesk-bold">Welcome to Jinolo! 🎉</h1>
                    <p>Hello and welcome! We're excited to show you around. Click 'Next' to get started.</p>
                </div>,
            placement: 'center',
            target: 'body',

        },
        {
            content:
                <div>
                    <h1 className="pb-2 text-lg font-grotesk-bold">Your Home Screen</h1>
                    <p>This is your home screen, where all your uploaded files live.</p>
                </div>,
            placement: 'center',
            target: 'body',
        },
        {
            content:
                <div>
                    <p>Click here to upload new files, or simply drag and drop.</p>
                </div>,
            target: '.onboarding-tour-second-step',
        },
        {
            content:
                <div>
                    <h1 className="pb-2 text-lg font-grotesk-bold">Welcome to the 3D Viewer 🖥</h1>
                    <p>Here, you can view, comment, and inspect your file in detail.</p>
                </div>,
            placement: 'center',
            target: 'body',

        },
        {
            content:
                <div>
                    <p className="pb-4">Click the comment icon to expose the chat panel. Great for feedback and
                        discussions!</p>
                </div>,
            target: '.onboarding-tour-comment-icon',
        },
        {
            content:
                <div>
                    <p className="pb-4">Click here to invite people to view or edit this file. Easily share your designs
                        via email or a link!</p>
                </div>,
            target: '.onboarding-tour-share-icon',
        },
        {
            content:
                <div className="flex flex-col items-center">
                    <h1 className="pb-2 text-lg font-grotesk-bold">Go Premium for More 🌟</h1>
                    <p className="text-md font-grotesk-normal">Get more storage, faster support, and exclusive features
                        by upgrading to our premium plans.</p>
                    <div className="flex flex-col py-3 text-md font-grotesk-normal">
                        <div className="flex">
                            <div className="w-6">✓</div>
                            <div>Increased Storage</div>
                        </div>
                        <div className="flex">
                            <div className="w-6">✓</div>
                            <div>Secure Sharing</div>
                        </div>
                        <div className="flex">
                            <div className="w-6">✓</div>
                            <div>Exclusive Features</div>
                        </div>

                    </div>
                    <button
                        className={`font-bold text-xs text-white tracking-wide h-9 block
                        uppercase whitespace-nowrap bg-primary cursor-pointer 
                        rounded-full px-12 border-0 focus:outline-none my-2`}
                        onClick={handleUpgradeClick}
                    >
                        Upgrade
                    </button>

                    <p className="pt-2 text-xs font-grotesk-normal">
                        <a
                            href="https://jinolo.com/pricing"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Check out our <span className="text-accent underline">pricing page</span> for more details
                        </a>
                    </p>
                </div>,
            styles: {
                options: {
                    primaryColor: '#b3b3b3',
                }
            },
            placement: 'center',
            target: 'body',
        },
        {
            content:
                <div>
                    <h1 className="pb-2 text-lg font-grotesk-bold"> You're All Set! 🎉</h1>
                    <p>Thanks for taking the tour! You're now ready to make the most out of Jinolo. Happy designing!</p>
                </div>,
            placement: 'center',
            target: 'body',
            showProgress: false,
            locale: {last: 'Start collaborating'}
        },
    ];

    const stepNoDemo = [
        {
        content:
            <div>
                <h1 className="pb-2 text-lg font-grotesk-bold">Ready for a Demo? 🎨</h1>
                <p>If you're new here, let us set you up with a demo file to get started. Click 'Next' to create your
                    demo file.</p>
            </div>,
        placement: 'center',
        target: 'body',
    },
    {
        content:
            <div>
                <h1 className="pb-2 text-lg font-grotesk-bold">Creating Your Demo File 🛠</h1>
                <p>Hang tight, it'll be ready soon!</p>
                <div className="flex justify-center pt-3">
                    <ThemeProvider theme={modalMaterialTheme}>
                        <CircularProgress className="align-self-center"/>
                    </ThemeProvider>
                </div>
            </div>,
        placement: 'center',
        target: 'body',
        showProgress: false,
        locale: { next: <span style={{ display: "none" }} /> },
        styles: {
            options: {
                primaryColor: '#ffffff',
            },
            buttonNext: {
                borderRadius: 0,
                paddingLeft: 0,
                paddingRight: 0
            }
        }
    }
    ];

    const stepDemo = [
        {
            content:
                <div>
                    <p className="pb-4">We've added a demo file for you to explore! Click on it to view.</p>
                </div>,
            target: '.onboarding-tour-demo-file',
        }
    ];

    const [steps, setSteps] = useState([...stepsDefault]);  // Initialize with the default steps


    useEffect(() => {
        let newSteps = [...stepsDefault];  // Clone the default steps
        if (demoDocId === null) {
            // Insert the optional step at PAGE_INDEX_POSITION_DEMO index
            newSteps.splice(PAGE_INDEX_POSITION_DEMO, 0, ...stepNoDemo);
        }
        else {
            newSteps.splice(PAGE_INDEX_POSITION_DEMO, 0, ...stepDemo);
        }
        setSteps(newSteps);

    }, [demoDocId]);

    // Inside your component
    const navigate = useNavigate();

    const handleJoyrideCallback = async data => {
        const {status, index, type, action} = data;
        if (index === 0) {
            window.analytics.track('Onboarding Tour Started');
        }
        if (demoDocId === null && index === PAGE_INDEX_CREATE_DEMO_FILE && type === "step:after") {
            setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
            setCanProceed(false);
            const docId = await addDemoFile(appFirebase);
            console.log(docId)
            navigate(`/files/${docId}`);
            // await new Promise(resolve => setTimeout(resolve, 3000));
            setCanProceed(true);
            // setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
        }
        else {
            if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
                setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
            }
        }
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setTourState({...tourState, run: false});
            if (status === STATUS.FINISHED) {
                await setTourInteractionFlag(appFirebase, "end", index);
                // Analytics tracking who finished the tour
                window.analytics.track('Onboarding Tour Finished', {
                    upgradeClick: false
                });
            } else if (status === STATUS.SKIPPED) {
                await setTourInteractionFlag(appFirebase, "skip", index);
                window.analytics.track('Onboarding Tour Skipped', {
                    stage: index
                });
            }
        }
        if (demoDocId) {
            if (index === PAGE_INDEX_NAVIGATE_VIEWER_DEMO_EXISTS && type === "step:before") {
                console.log("navigating now")
                navigate(`/files/${demoDocId}`);
            }
        }
        console.log(index)
    }




    async function handleUpgradeClick() {
        setTourState({...tourState, run: false});
        await setTourInteractionFlag(appFirebase, "upgrade"); // Assuming "end" means the tour ended because of an upgrade
        navigate('/checkout');
        window.analytics.track('Onboarding Tour Finished', {
            upgradeClick: true
        });
    }


    return (
        <div>

            <Joyride
                steps={steps}
                hideCloseButton
                showSkipButton={true}
                showProgress
                disableOverlayClose
                disableScrolling
                styles={{
                    options: {
                        primaryColor: '#00c4cc',
                    },
                    buttonNext: {
                        borderRadius: 18,
                        paddingLeft: 15,
                        paddingRight: 15
                    }
                }}
                run={tourState.run}
                continuous={tourState.continuous}
                stepIndex={stepIndex}
                callback={handleJoyrideCallback}
            />
        </div>
    );
}

export default OnboardingTour;


