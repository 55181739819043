import {databaseTypesConstants as dbConst} from "../../../firebase/databaseConstants";
import {ref, set} from "firebase/database";
import {httpsCallable} from "firebase/functions";

export const setTourInteractionFlag = async (appFirebase, state, step) => {
    // Validate the userId (make sure it's not null or undefined)
    const userId = appFirebase.auth.currentUser.uid
    if (!userId) {
        console.error('Invalid user ID');
        return;
    }
    let flagName;
    let upgradeFlag;

    switch (state) {
        case "skip":
            flagName = `${dbConst.USER_MARKETING.options.ONBOARD_TOUR_SKIPPED}`
            break;
        case "end":
            flagName = `${dbConst.USER_MARKETING.options.ONBOARD_TOUR_COMPLETED}`
            break;
        case "upgrade":
            upgradeFlag = `${dbConst.USER_MARKETING.options.ONBOARD_UPGRADE_CLICK}`
            flagName = `${dbConst.USER_MARKETING.options.ONBOARD_TOUR_COMPLETED}`
            break;
        default:
            flagName = null;
    }

    // Define the database reference
    const userTourRef = ref(appFirebase.database, `/${dbConst.USER_MARKETING.name}/${userId}/${flagName}`);
    const stepRef = ref(appFirebase.database, `/${dbConst.USER_MARKETING.name}/${userId}/${dbConst.USER_MARKETING.options.ONBOARD_TOUR_STEP}`);

    // Set the flag to true
    try {
        if (flagName) {
            await set(userTourRef, true);
            if (upgradeFlag) {
                await set(ref(appFirebase.database, `/${dbConst.USER_MARKETING.name}/${userId}/${upgradeFlag}`), true);
            }

            if (step && state !== "end") {
                await set(stepRef, step);
            }
        }
    } catch (error) {
        console.error('Failed to set user tour interaction flag', error);
    }
};

export const addDemoFile = async (appFirebase) => {
    // Check if the user is authenticated
    if (appFirebase.auth.currentUser) {
        try {
            // Reference to your Cloud Function
            const addDemoFile = httpsCallable(appFirebase.function,'userManagement-addDemoFileToSpecificUser');

            // Call the Cloud Function
            const result = await addDemoFile();

            // Read the result from the Cloud Function
            return result.data.docId;

        } catch (error) {
            // Handle the error
            const code = error.code;
            const message = error.message;
            console.error(`Error occurred: Code: ${code}, Message: ${message}`);


        }
    } else {
        console.error('User is not authenticated.');
    }
}
