import React, {useContext, useEffect, useState} from 'react';
import CheckoutButton from "./checkoutButton";
import Checkout from "./checkout";
import {onValue, ref} from "firebase/database";
import {FirebaseContext} from "../../../contexts/FirebaseContext";

function CheckoutPage({ signedIn, setSignInStatus, uiConfig}) {
    const [subscription, setSubscription] = useState({});
    const appFirebase = useContext(FirebaseContext);

    useEffect(() => {
        // console.log(signedIn)
        if (signedIn) {
            const uid = appFirebase.auth.currentUser.uid;
            const subscriptionRef = ref(appFirebase.database, `/subscription/${uid}`);
            return onValue(subscriptionRef, (snapshot) => {
                setSubscription(snapshot.val().subscriptionPlan);
            });
        }
    }, [signedIn]);

    return (
        <div>
            {
                subscription < 1 ?
                    <Checkout
                        appFirebase={appFirebase}
                    />
                    :
                    <div>
                        <div className=" md:py-40 py-20 px-5 items-center">
                            <div className="flex items-center justify-center cursor-pointer">
                                <div className="font-grotesk-bold text-navy text-3xl pt-7 pb-5 px-3">Upgrade your Plan</div>
                            </div>
                            <div className="border bottom-2 border-grays-lineGray md:mx-20 lg:mx-30"/>

                            <div className="mt-10 text-bubbleGray font-grotesk-normal text-center text-base max-w-md mx-auto px-5">
                                You are already subscribed to the {subscription === 1 ? "Individual" : "Business"} Plan.
                            </div>
                            {subscription === 1 && <div className="mt-10 text-bubbleGray font-grotesk-normal text-center text-base max-w-md mx-auto px-5">
                                If you would like to upgrade to the Business Plan, contact us at <b>hello@jinolo.com</b>
                            </div>}

                        </div>


                    </div>
            }
        </div>

    );
}

export default CheckoutPage;