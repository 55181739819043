import React, {useContext, useEffect, useState} from 'react';
import "../../../style/userFileDisplay.scss";
import {onValue, ref} from "firebase/database";
import theme from "../../../style/theme";
import styled from "styled-components";
import SettingsLineItem from "./SettingsLineItem";
import "../../../style/userFileDisplay.scss";
import * as DocumentPermissions from "../../../firebase/permissionValues";
import {Link} from "react-router-dom";
import {FirebaseContext} from "../../../contexts/FirebaseContext";
import {formatBytes} from "../../../firebase/commonFunctions";
import {subscriptionPlanStrings} from "../../../firebase/databaseConstants";

const databaseConstants = {
    FIRSTNAME: "firstname",
    LASTNAME: "lastname",
    EMAIL: "email",
};


function SettingsPage(props) {
    const [user, setUser] = useState({});
    const [subscription, setSubscription] = useState({});
    const {
        signedIn,
    } = props;
    const appFirebase = useContext(FirebaseContext);


    function changeLastName() {

    }

    function changeEmail() {

    }

    function changePassword(newPassword) {
        appFirebase.auth.currentUser.updatePassword(newPassword).then(() => {
            // Update successful.
            // Display top notification that update is successful
        }).catch((error) => {
            // An error ocurred
            // Display top notification that update not successful
        });
    }

//Adding Segment Analytics page function on mount
    useEffect(() => {
        return () => {
            //Segment pageview call
            window.analytics.page();
        };
    }, []);

    useEffect(() => {
        // console.log(signedIn)
        if (signedIn) {
            const uid = appFirebase.auth.currentUser.uid;
            const usersRef = ref(appFirebase.database, `/users/${uid}`);
            return onValue(usersRef, (snapshot) => {
                setUser(snapshot.val());
                // console.log(user);
            });

        } else {
            setUser({});
        }


    }, [signedIn]);
    useEffect(() => {
        // console.log(signedIn)
        if (signedIn) {
            const uid = appFirebase.auth.currentUser.uid;
            const subscriptionRef = ref(appFirebase.database, `/subscription/${uid}`);
            return onValue(subscriptionRef, (snapshot) => {
                setSubscription(snapshot.val());
                // console.log(user);
            });

        } else {
            setUser({});
        }


    }, [signedIn]);


    return (
        <div>
            <div className="userFileDisplayOuterContainer">
                <div className="userHomeContainer">
                    <PageHeading>
                        Settings
                    </PageHeading>

                    <Line/>
                    {signedIn ?
                        <>
                            <PageSubHeading>
                                Your Account
                            </PageSubHeading>
                            <SettingsLineItem
                                labelText={"First Name"}
                                value={`${user.firstname}`}
                                appFirebase={appFirebase}
                                databaseConstant={databaseConstants.FIRSTNAME}
                                type={"details"}
                            />

                            <SettingsLineItem
                                labelText={"Last Name"}
                                value={`${user.lastname}`}
                                appFirebase={appFirebase}
                                databaseConstant={databaseConstants.LASTNAME}
                                type={"details"}
                            />

                            <SettingsLineItem
                                labelText={"Email"}
                                value={`${user.email}`}
                                appFirebase={appFirebase}
                                databaseConstant={databaseConstants.EMAIL}
                                type={"details"}
                            />

                            <SettingsLineItem
                                labelText={"Password"}
                                value={"Change password"}
                                appFirebase={appFirebase}
                                editFunction={() => {
                                }}
                                type={"password"}
                            />

                            <div className="my-6"/>
                            <Line/>
                            <PageSubHeading>
                                Subscription Plan
                            </PageSubHeading>

                            <SettingsLineItem className="pb-5"
                                              labelText={"Plan"}
                                              value={subscription.subscriptionPlan > 0 ? `Subscribed to Jinolo ${subscriptionPlanStrings[subscription.subscriptionPlan]} Plan` : "You are on the Jinolo Free Plan"}
                                              displayButton={false}
                                // editButtonText={subscription.subscriptionPlan === 0 ? "Upgrade" : "Manage"}
                                // editFunction={() => {}
                            />
                            <Link to={`/settings/subscription`} style={{textDecoration: 'none'}}>
                                <SettingsNavigation>
                                    Subscription settings >
                                </SettingsNavigation>
                            </Link>

                            {/*<SettingsLineItem*/}
                            {/*    labelText={"Payment and Billing"}*/}
                            {/*    value={user.paymentDetails ? "Currently paying with card" : "No payment method"}*/}
                            {/*    editButtonText={!user.paymentDetails ? "Add" : "Edit"}*/}
                            {/*    editFunction={() => {*/}
                            {/*    }}/>*/}

                            {/*<SettingsLineItem*/}
                            {/*    labelText={"Storage"}*/}
                            {/*    value={`Used ${formatBytes(subscription.storageUsed)} out of ${formatBytes(subscription.storageAvailable)}`}*/}
                            {/*    editButtonText={"Upgrade"}*/}
                            {/*    editFunction={() => {*/}
                            {/*    }}/>*/}
                            <SettingsLineItem
                                labelText={"Storage"}
                                value={subscription.subscriptionPlan <= 0
                                    ? "You have 3 free files on the Jinolo Free Plan"
                                    : `Used ${formatBytes(subscription.storageUsed)} out of ${formatBytes(subscription.storageAvailable)}`
                                }
                                displayButton={false}
                                // editButtonText={"Upgrade"}
                                // editFunction={() => {
                                // }}/>
                            />

                            {/*<Line/>*/}
                            {/*<PageSubHeading>*/}
                            {/*    Email*/}
                            {/*</PageSubHeading>*/}
                            {/*<SettingsLineItem labelText={"File notifications"} value={"Comments from others"} editDisplay={false}/>*/}
                            <div className="my-6"/>
                            <Line/>

                            {/*<SettingsLineItem*/}
                            {/*    labelText={"Delete Account"}*/}
                            {/*    value={"By deleting your account, you'll no longer be able to access any of your files or log in to Jinolo."}*/}
                            {/*    editButtonText={"Delete"}*/}
                            {/*    editFunction={() => {*/}
                            {/*    }}*/}
                            {/*/>*/}

                        </>
                        :
                        <div className="m-10">
                            <div className="noDocumentsNotification">You need to be signed in to view Account
                                Settings.
                            </div>
                        </div>

                    }


                </div>
            </div>
        </div>
    );
}

export default SettingsPage;

const PageHeading = styled.div`
  font-family: ${theme.font.bold};
  font-size: 28px;
  line-height: 30px;
  text-align: left;
  padding-left: 20px;
  color: #4d4d4d;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

const Line = styled.div`
  border-bottom: 2px solid #b3b3b3;
`;

const PageSubHeading = styled.div`
  font-family: ${theme.font.medium};
  font-size: 24px;
  line-height: 30px;
  padding-top: 10px;
  text-align: left;
  padding-left: 20px;
  color: #4d4d4d;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

const SettingsNavigation = styled.div`
  font-family: ${theme.font.medium};
  font-size: 14px;
  line-height: 30px;
  text-align: left;
  padding-left: 25px;
  color: #838383;
  margin-top: -20px;
  padding-bottom: 10px;
`;
