import React, {useEffect, useState, useContext} from 'react';
import {Link} from "react-router-dom";
import {Alert, CircularProgress, IconButton, ListItem, Snackbar} from "@mui/material";
import {onValue, ref, get, off} from "firebase/database";
import {MoreVert, Undo, Share} from "@mui/icons-material";
import Menu from '@mui/material/Menu';
import {formatBytes} from "../../../firebase/commonFunctions";
import ShareModal from "../../common/ShareModal/ShareModal";
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state';
import {httpsCallable} from 'firebase/functions';
import {getDownloadURL, getMetadata, ref as Sref} from "firebase/storage";
import {databaseTypesConstants as dbConst, databaseTypesConstants} from "../../../firebase/databaseConstants";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DescriptionIcon from '@mui/icons-material/Description';
import DragHandleIcon from '@mui/icons-material/DragHandle';

function isOwner(permissionObject) {
    if (permissionObject) {
        if (permissionObject.owner === true) {
            return true;
        }
    }
    return false;
}

async function fetchFileSize(fileStorageRef) {
    return new Promise((resolve, reject) => {
        // Get metadata properties
        getMetadata(fileStorageRef)
            .then((metadata) => {
                // Metadata now contains the metadata for 'images/forest.jpg'
                resolve(metadata.size);
            })
            .catch((error) => {
                // Uh-oh, an error occurred!
            });
    });
}

function DocumentListItem(props) {
    const {
        docId,
        appFirebase,
        fileName,
        author,
        authorUid,
        date,
        isBeingDragged,
        isRoot,
        currentFolderId
    } = props;
    // console.log(draggingOver);
    const deleteDocument = httpsCallable(appFirebase.function, 'upload-deleteDocument')
    const moveFolderFunction = httpsCallable(appFirebase.function, 'folders-moveFileToFolder')
    const [permissionObject, setPermissionObject] = useState(null);
    const [pidState, setPidState] = useState(null);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loadingMoveBack, setLoadingMoveBack] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isDemo, setIsDemo] = useState(false);
    const [size, setSize] = useState(null);

    useEffect(() => {
        const dbRef = ref(appFirebase.database, `${dbConst.DOCUMENTS.name}/${docId}`);

        const handleValueChange = (snap) => {
            const value = snap.val();

            const processingStatus = value[dbConst.DOCUMENTS.options.PROCESSING_COMPLETED]
            const demoStatus = value[dbConst.DOCUMENTS.options.DEMO_FILE]

            // If the value is `false`, set `isProcessing` to true, otherwise set it to false.
            setIsProcessing(processingStatus === false);

            if (demoStatus) {
                setIsDemo(demoStatus)
            }
        };

        return onValue(dbRef, handleValueChange);
    }, [isDemo]);



    useEffect(() => {
        return onValue(ref(appFirebase.database, `documentAccess/${docId}`), (docSnapshot) => {
            const pid = docSnapshot.val()[appFirebase.auth.currentUser.uid];
            setPidState(pid)

        });
    }, []);
    useEffect(async () => {
        const docSize = await fetchFileSize(Sref(appFirebase.storage, `${authorUid}/${docId}`));
        setSize(docSize)
    }, []);

    useEffect(() => {
        if (pidState) {
            return onValue(ref(appFirebase.database, `/permissions/${pidState}`), (snapshot) => {
                setPermissionObject(snapshot.val())
            });
        }
    }, [pidState]);


    // useEffect(() => {
    //     if (!loadingDelete) {
    //         window.location.reload(); // refresh the page
    //     }
    // }, [loadingDelete]);

    function handleDocumentShare() {
        setOpenShareModal(true)
    }

    const handleDeleteDocument = async () => {
        if (loadingDelete) return;
        try {
            setLoadingDelete(true);
            await deleteDocument({docId: docId});
            // handle success case
        } catch (error) {
            // handle error case
        } finally {
            setLoadingDelete(false);
            window.location.reload();
        }
    };

    // useEffect(() => {
    //     return onValue(ref(appFirebase.database, `documents/${docId}`), (docSnapshot) => {
    //         const doc = docSnapshot.val()
    //         const newDocEntry = {
    //             id: docId,
    //             fileName: doc[databaseTypesConstants.DOCUMENTS.options.NAME],
    //             author: author,
    //             createdDate: date,
    //         }
    //         console.log(`called onDetailChange with ${newDocEntry.fileName}`)
    //         onDetailChange(newDocEntry)
    //     });
    // }, []);

    async function moveFileToParentBack() {
        if (loadingMoveBack) return;
        const parentSnapshot = await get(
            ref(appFirebase.database, `${dbConst.FOLDERS.name}/${currentFolderId}/${dbConst.FOLDERS.options.PARENT}`))
        const parentFolderId = parentSnapshot.val()
        if (parentFolderId !== null) {
            try {
                // console.log("starting move back")
                setLoadingMoveBack(true)
                const moveFolderData = {
                    destinationFolderId: parentFolderId,
                    docId: docId,
                    parentFolderId: currentFolderId
                }
                await moveFolderFunction(moveFolderData);
                // await appFirebase.moveFileToFolder(folderId, filteredDocs[source.index].id, rootFolderId);
            } catch (error) {
                console.log(error)
            } finally {
                // Unset the state once the move is completed
                // console.log("move back completed")
                setLoadingMoveBack(false)

            }
            // appFirebase.moveFileToFolder(parentFolderId, docId, currentFolderId).then(r => {
            // })
        }
    }


    async function onClickDownload(docId) {
        const urlRef = await appFirebase.getDocumentUrlRef(docId);
        getDownloadURL(Sref(appFirebase.storage, urlRef))
            .then((url) => {
                window.open(url, '_blank');
            })
            .catch(() => {
                // Handle any errors
            });
    }

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const linkTo = `/files/${docId}`
    return (
        <div
            className={`${isDemo && 'onboarding-tour-demo-file'} rounded-xl px-1 ${isProcessing ? 'bg-lightGray pointer-events-none' : 'hover:bg-grays-hoverGray hover:text-navy'}`}
            style={{ opacity: isProcessing ? 0.5 : 1 }}
        >
            <div
                className="flex pb-1 justify-between border-bottom border-grays-lineGray hover:text-grays-headingTextGray border-b-2 pt-4 py-1">
                <div className="w-full ">
                    <div className="grid grid-cols-4">
                        <div className="flex text-textGray pl-1" onMouseEnter={handleMouseEnter}
                             onMouseLeave={handleMouseLeave}>
                            {/*<div className="flex text-textGray pl-1">*/}
                            <div className="pt-1">

                                {(isHovered || isBeingDragged) ? <DragHandleIcon/> : <DescriptionIcon/>}
                            </div>
                            <Link to={linkTo}>

                                <div
                                    className="font-grotesk-semi-bold cursor-pointer  text-lg pl-3 whitespace-normal break-words break-all">
                                    {fileName}
                                </div>
                            </Link>
                        </div>
                        <div
                            className="font-grotesk-normal cursor-pointer text-textGray text-lg pl-10 place-self-center whitespace-normal break-words">
                            {date}
                        </div>
                        <div
                            className="font-grotesk-normal cursor-pointer text-textGray text-lg pl-10 place-self-center whitespace-normal break-words">
                            {!size? "-" : formatBytes(size)}

                        </div>
                        <div
                            className="font-grotesk-normal cursor-pointer text-textGray text-lg pl-10 place-self-center whitespace-normal break-words">
                            {author}
                        </div>
                    </div>
                </div>

                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <React.Fragment>
                            <IconButton
                                color="primary"
                                disableRipple={true}
                                sx={{color: "#6d6d6d"}}
                                {...bindTrigger(popupState)}

                            >
                                {isProcessing ? <CircularProgress color="inherit" size={24} /> : <MoreVert />}
                            </IconButton>


                            <Menu {...bindMenu(popupState)} disableScrollLock={true}>

                                {/*<div></div>*/}
                                <Link to={linkTo}>
                                    <div
                                        className="text-grays-headingTextGray flex font-grotesk-normal hover:bg-grays-hoverGray pt-1 pb-2 pr-2 pl-1 cursor-pointer">
                                        <div className="pl-3 pt-1">
                                            <RemoveRedEyeIcon fontSize={"small"}/>
                                        </div>
                                        <div className="pt-1 px-3">View</div>
                                    </div>
                                </Link>
                                <div
                                    className="text-grays-headingTextGray flex font-grotesk-normal hover:bg-grays-hoverGray pt-1 pb-2 pr-2 pl-1 cursor-pointer">
                                    <div className="pl-3 pt-1">
                                        <DownloadIcon fontSize={"small"}/>
                                    </div>
                                    <div className="pt-1 px-3" onClick={
                                        () => onClickDownload(docId)}>Download
                                    </div>
                                </div>
                                {!isRoot &&
                                    <div
                                        className="text-grays-headingTextGray flex font-grotesk-normal hover:bg-grays-hoverGray pt-1 pb-2 pr-2 pl-1 cursor-pointer">
                                        <div className="pl-3 pt-1">
                                            <Undo fontSize={"small"}/>
                                        </div>
                                        <button className="pt-1 px-3" disabled={loadingMoveBack}
                                                onClick={
                                                    () => moveFileToParentBack()}
                                        >
                                            Move file back
                                        </button>
                                    </div>
                                }
                                {isOwner(permissionObject) &&
                                    <div
                                        className="text-grays-headingTextGray flex font-grotesk-normal hover:bg-grays-hoverGray pt-1 pb-2 pr-2 pl-1 cursor-pointer">
                                        <div className="pl-3 pt-1">
                                            <Share fontSize={"small"}/>
                                        </div>
                                        <button className="pt-1 px-3" disabled={loadingDelete}
                                                onClick={() => handleDocumentShare()}
                                            //  onClick={
                                            // () => deleteDocument({docId: docId})}
                                        >
                                            Share file
                                        </button>
                                    </div>
                                }
                                {/*{!isDemo && (isOwner(permissionObject) ?*/}
                                {/*    <div*/}
                                {/*        className="text-grays-headingTextGray flex font-grotesk-normal hover:bg-grays-hoverGray pt-1 pb-2 pr-2 pl-1 cursor-pointer">*/}
                                {/*        <div className="pl-3 pt-1">*/}
                                {/*            <DeleteForeverIcon fontSize={"small"}/>*/}
                                {/*        </div>*/}
                                {/*        <button className="pt-1 px-3" disabled={loadingDelete}*/}
                                {/*                onClick={handleDeleteDocument}*/}
                                {/*            //  onClick={*/}
                                {/*            // () => deleteDocument({docId: docId})}*/}
                                {/*        >*/}
                                {/*            Delete file*/}
                                {/*        </button>*/}
                                {/*    </div> :*/}
                                {/*        //TODO: Add logic to remove shared*/}
                                {/*        */}
                                {/*        <div*/}
                                {/*            className="text-grays-headingTextGray flex font-grotesk-normal hover:bg-grays-hoverGray pt-1 pb-2 pr-2 pl-1 cursor-pointer">*/}
                                {/*            <div className="pl-3 pt-1">*/}
                                {/*                <ClearIcon fontSize={"small"}/>*/}
                                {/*            </div>*/}
                                {/*            <button className="pt-1 px-3" disabled={loadingDelete}*/}
                                {/*                // onClick={handleDeleteDocument}*/}
                                {/*                    onClick={*/}
                                {/*                        () => console.log("Remove shared document")}*/}
                                {/*            >*/}
                                {/*                Remove*/}
                                {/*            </button>*/}
                                {/*        </div>*/}
                                {/*)*/}
                                {/*}*/}
                                {(!isDemo && isOwner(permissionObject)) &&
                                <div
                                    className="text-grays-headingTextGray flex font-grotesk-normal hover:bg-grays-hoverGray pt-1 pb-2 pr-2 pl-1 cursor-pointer">
                                    <div className="pl-3 pt-1">
                                        {loadingDelete ? <CircularProgress color="inherit" size="20px" /> : <DeleteForeverIcon fontSize={"small"}/>}
                                    </div>
                                    <button className="pt-1 px-3" disabled={loadingDelete}
                                            onClick={handleDeleteDocument}
                                        //  onClick={
                                        // () => deleteDocument({docId: docId})}
                                    >
                                        Delete file
                                    </button>
                                </div>}

                                {(isDemo && isOwner(permissionObject)) &&
                                    //TODO: Add modal to ask confirming delete and remove
                                    <div
                                        className="text-grays-headingTextGray flex font-grotesk-normal hover:bg-grays-hoverGray pt-1 pb-2 pr-2 pl-1 cursor-pointer">
                                        <div className="pl-3 pt-1">
                                            <ClearIcon fontSize={"small"}/>
                                        </div>
                                        <button className="pt-1 px-3" disabled={loadingDelete}
                                                onClick={handleDeleteDocument}
                                        >
                                            Remove demo
                                        </button>
                                    </div>
                                }

                                {/*<MenuItem onClick={popupState.close}>My account</MenuItem>*/}
                                {/*<MenuItem onClick={popupState.close}>Logout</MenuItem>*/}
                            </Menu>
                        </React.Fragment>
                    )}
                </PopupState>
            </div>
            <ShareModal handleClose={() => setOpenShareModal(false)} open={openShareModal} appFirebase={appFirebase}
                        docId={docId} docName={fileName}/>
        </div>
    );
}

export default DocumentListItem;