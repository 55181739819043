import React, { createContext, useContext, useState } from 'react';

export const SidePanelModes = {
    NONE: "NONE",
    COMMENTS: "COMMENTS",
    UPLOAD: "UPLOAD",
    PART_LIST: "PART_LIST",
    // Add more as needed
};

const SidePanelContext = createContext();

export const useSidePanel = () => {
    const context = useContext(SidePanelContext);
    if (!context) {
        throw new Error("useSidePanel must be used within a SidePanelProvider");
    }
    return context;
};

export const SidePanelProvider = ({ children }) => {
    const [currentSidePanelMode, setCurrentSidePanelMode] = useState(SidePanelModes.COMMENTS);
    const [openSidePanelState, setOpenSidePanelState] = useState(true);

    const toggleSidePanel = (newMode) => {
        if (currentSidePanelMode === newMode) {
            // Close the panel if it's already open with the current mode
            setCurrentSidePanelMode(SidePanelModes.NONE);
            setOpenSidePanelState(false);
        } else {
            // Otherwise, open the panel with the new mode
            setCurrentSidePanelMode(newMode);
            setOpenSidePanelState(true);
        }
    };

    const openComments = () => toggleSidePanel(SidePanelModes.COMMENTS);
    const openUpload = () => toggleSidePanel(SidePanelModes.UPLOAD);
    const openPartList = () => toggleSidePanel(SidePanelModes.PART_LIST);

    const closeSidePanel = () => {
        setOpenSidePanelState(false);
        setCurrentSidePanelMode(SidePanelModes.NONE);
    };

    const value = {
        currentSidePanelMode,
        openSidePanelState,
        openComments,
        openUpload,
        openPartList,
        closeSidePanel
    };

    return <SidePanelContext.Provider value={value}>{children}</SidePanelContext.Provider>;
};
