import React, {useContext, useEffect, useState} from "react";
import {useFilePicker} from "use-file-picker";
import AddIcon from "@mui/icons-material/Add";
import {onValue, ref, update} from "firebase/database";
import {databaseTypesConstants} from "../../../firebase/databaseConstants";
import PopUpModal from "../../common/PopUpModal";
import LoadingModal from "./LoadingModal";
import {httpsCallable} from "firebase/functions"
import axios from "axios";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import Menu from "@mui/material/Menu";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CreateNewFolderModal from "./CreateNewFolderModal";
import { useNavigate } from "react-router-dom";
import {SignUpModalContext} from "../../../contexts/signUpModalContext";
import useUpload from "./hooks/useUpload";
import {FirebaseContext} from "../../../contexts/FirebaseContext";

// const fs = require('fs/promises');

function UploadButton({signedIn}) {
    const [unableToUploadNoStorage, setUnableToUploadNoStorage] = useState(null);
    const [unableToUploadFreeFileLimitReached, setUnableToUploadFreeFileLimitReached] = useState(null);
    const [unableToCreateFolderOnFree, setUnableToCreateFolderOnFree] = useState(null);
    const [uploadFailure, setUploadFailure] = useState(false);
    const [showCreateNewFolderModal, setShowCreateNewFolderModal] = useState(false);
    const [filename, setFileName] = useState("");
    const [userSubscriptionPlan, setUserSubscriptionPlan] = useState(null);
    const [openFileSelector, {plainFiles, clear}] = useFilePicker({
        accept: [".stl", ".pdf", ".3dxml", ".glb", ".gltf", ".obj", ".stp", ".step", ".ifc"],
    });
    const appFirebase = useContext(FirebaseContext);
    const { startedUpload, setStartedUpload, uploadSuccess, setUploadSuccess, startUploadFile, uploadPercentage } = useUpload();
    const {state, dispatch} = useContext(SignUpModalContext);
    let reader = new FileReader();
    const handleClickUpgrade = async () => {
        const upgradeToTrial = httpsCallable(appFirebase.function, 'payment-upgradeToTrial');
        onValue(ref(appFirebase.database, `${databaseTypesConstants.SUBSCRIPTION.name}/${appFirebase.auth.currentUser.uid}`), async (snapshot) => {
            const user = snapshot.val();
            if (user[databaseTypesConstants.SUBSCRIPTION.options.TRIAL] === 0) {
                upgradeToTrial().then();
            }
        }, {
            onlyOnce: true,
        });

    }
    axios.defaults.headers = {
        "Content-Type": "application/octet-stream",
        // ...
    }
    const navigate = useNavigate();

    const handleOpenLogInModal = () => {
        dispatch({type: 'OPEN_MODAL'});
    }

    useEffect(() => {
        // Function to handle the file uploads sequentially
        const uploadFilesSequentially = async () => {
            if (signedIn) {
                for (const file of plainFiles) {
                    setStartedUpload(true);
                    try {
                        await startUploadFile(file);
                        // Break the loop if the upload limit is reached
                        if (unableToUploadFreeFileLimitReached) {
                            break;
                        }
                    } catch (error) {
                        // Handle errors or break the loop as needed
                    }
                }
            }
        };

        // Call the function to start the upload process
        uploadFilesSequentially().then(r => {});
    }, [plainFiles, signedIn]);


        useEffect(() => {
            if (signedIn) {
                const planRef = ref(appFirebase.database, `${databaseTypesConstants.SUBSCRIPTION.name}/${appFirebase.auth.currentUser.uid}/${databaseTypesConstants.SUBSCRIPTION.options.PLAN}`);
                return onValue(planRef, (snapshot => {
                    setUserSubscriptionPlan(snapshot.val())
                }))
            }
    }, []);


    function handleUploadFile() {

        if (signedIn) {
            onValue(ref(appFirebase.database, `${databaseTypesConstants.SUBSCRIPTION.name}/${appFirebase.auth.currentUser.uid}`), (snapshot) => {
                const user = snapshot.val();
                if (user[databaseTypesConstants.SUBSCRIPTION.options.STORAGE_USED] < user[databaseTypesConstants.SUBSCRIPTION.options.STORAGE_TOTAL]) {
                    openFileSelector();
                } else {
                    // console.log("You reached your limit");
                    setUnableToUploadNoStorage(true);
                    // setUnableToUploadNotPro(false);
                }
            }, {
                onlyOnce: true,
            });

        } else {
            handleOpenLogInModal();
        }
    }

    function handleCreateNewFolder() {
        if (signedIn) {
            onValue(ref(appFirebase.database, `${databaseTypesConstants.SUBSCRIPTION.name}/${appFirebase.auth.currentUser.uid}`), (snapshot) => {
                const user = snapshot.val();
                if (user[databaseTypesConstants.SUBSCRIPTION.options.PLAN] > 0) {
                    setShowCreateNewFolderModal(true);
                } else {
                    setUnableToCreateFolderOnFree(true);
                    // setUnableToUploadNotPro(false);
                }
            }, {
                onlyOnce: true,
            });
        } else {
            handleOpenLogInModal();
        }
    }

    return (<div className="pt-2 px-3 pb-10">
        {/*<div className="uploadButton" onClick={handleUploadFile}>*/}
        {/*    <AddIcon/>*/}
        {/*</div>*/}

        <PopupState variant="popover" popupId="upload-popup-menu">
            {(popupState) => (
                <React.Fragment>


                    <div
                        className="font-grotesk-bold border border-2 border-grays-lineGray cursor-pointer w-36 h-16 rounded-full text-grays-headingTextGray hover:bg-grays-hoverGray"
                        {...bindTrigger(popupState)}
                    >
                        <div className="onboarding-tour-second-step flex align-items-center align-self-center py-5 pl-5">
                            <AddIcon/>
                            <div className="pl-3">
                                New
                            </div>
                        </div>
                    </div>

                    <Menu {...bindMenu(popupState)} disableScrollLock={true}>

                        <div
                            className={`y flex font-grotesk-normal hover:bg-grays-hoverGray pr-5 pl-1 cursor-pointer  ${userSubscriptionPlan > 0 ? 'pt-1 pb-2' : 'pt-2 pb-3'} `}
                            onClick={() => {
                                handleUploadFile();
                                popupState.close();
                            }}
                        >

                            <div className="pl-3 pt-1 text-textGray">
                                <UploadFileIcon fontSize={"small"}/>
                            </div>
                            <div className="pt-1 px-4 text-grays-headingTextGray">Upload file</div>
                        </div>

                        <div
                            className="y flex font-grotesk-normal hover:bg-grays-hoverGray pt-1 pb-2 pr-5 pl-1 cursor-pointer"
                            onClick={() => {
                                handleCreateNewFolder()
                                popupState.close();
                            }}
                        >
                            <div className="pl-3 pt-1 text-textGray">
                                <CreateNewFolderIcon fontSize={"small"}/>
                            </div>
                            <div className="pt-1 px-4 text-grays-headingTextGray">New folder</div>

                            { userSubscriptionPlan <=0 && <div className="mt-1 pb-1 rounded-full bg-primary text-center text-sm font-semibold tracking-wide font-grotesk-normal text-white"><div className="pt-1 px-4"> Upgrade</div></div>}
                        </div>

                    </Menu>
                </React.Fragment>
            )}
        </PopupState>


        {signedIn && <PopUpModal
            open={unableToUploadNoStorage}
            handleClose={() => {
                setUnableToUploadNoStorage(false)
            }}
            text={{
                heading: "Upgrade Required",
                body: ["You've reached the storage limit and need to upgrade your plan to continue uploading files.",
                    "Upgrade now to unlock additional storage capacity and access all the benefits of our premium plan, including:",
                    "• Increased storage capacity",
                    "• Unlimited folders and reviewers",
                    "• Private file sharing",
                    "Upgrade today and take your productivity to the next level!"
                ]
            }}
            buttonOnClick={() => {
                navigate('/checkout');
            }}
            buttonText={"Upgrade"}>

        </PopUpModal>}
        {signedIn && <PopUpModal
            open={unableToUploadFreeFileLimitReached}
            handleClose={() => {
                setUnableToUploadFreeFileLimitReached(false)
            }}
            text={{
                heading: "Upgrade Required",
                body: ["You've reached the 3-file limit of your current plan. To continue uploading, an upgrade is needed.",
                    "Upgrade now to unlock additional storage capacity and embrace the full potential of our premium plans, including:",
                    "• Increased storage capacity",
                    "• Secure, private file sharing",
                    "• Wider range of functionalities including unlimited folders",
                    "Upgrade today and take your productivity to the next level!"
                ]
            }}
            buttonOnClick={() => {
                navigate('/checkout');
            }}
            buttonText={"Upgrade"}>

        </PopUpModal>}
        {signedIn && <PopUpModal
            open={unableToCreateFolderOnFree}
            handleClose={() => {
                setUnableToCreateFolderOnFree(false)
            }}
            text={{
                heading: "Upgrade Required",
                body: ["You need to upgrade to a paid plan before you can create a folder.",
                    "Upgrade now to unlock this feature and access all the benefits of our premium plan, including:",
                    "• Unlimited folders",
                    "• Private file sharing",
                    "• Increased storage capacity",
                "Upgrade today and take your productivity to the next level!"
                ]
            }}
            buttonOnClick={() => {
                navigate('/checkout');
            }}
            buttonText={"Upgrade"}>

        </PopUpModal>}
        {<LoadingModal
            uploadStatus={true}
            open={startedUpload}
            // open={true}
            percentage = {uploadPercentage}
            // percentage = {50}
            handleClose={() => {
                setUploadSuccess(false)
            }}
            filename={filename}
            uploadSuccess={true}
            buttonText={"Cancel"}>

        </LoadingModal>}
        {
            showCreateNewFolderModal &&
            <CreateNewFolderModal
                open={showCreateNewFolderModal}
                appFirebase={appFirebase}
                handleClose={() => setShowCreateNewFolderModal(false)}
            />
        }
    </div>);
}

export default UploadButton;
