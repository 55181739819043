// 3D Comment in the Chat
import React from 'react';
import PopOverCommentMenu from "./PopOverCommentMenu";

function Point3DComment({authorName, text, formattedDate, commentId, docId, isAuthorCurrentUser}) {
    return (
        <div className="m-3 my-5 mx-5">
            <div className="bg-white rounded-3xl p-3 border border-accent">
                <div className="flex  justify-between">
                    <div className="flex flex-row">
                        <div className="px-2">
                            <div className='annotation-marker-comment'/>
                        </div>
                        <div className="ml-2 mr-6">
                            <div className="font-semibold">{authorName}</div>
                            <span className="block text-gray-500">{text}</span>
                        </div>
                    </div>
                    <div className="ml-2 ">
                        <div className="block text-gray-100 text-xs mr-7">{formattedDate}</div>
                        {isAuthorCurrentUser && <PopOverCommentMenu commentId={commentId} docId={docId}/>}
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Point3DComment;