import styled from "styled-components"
import theme from "../../style/theme"


export const Nav = styled.nav`
  padding: 16px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 65;
  background: ${theme.color.background.navy};
  transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
`

export const StyledContainer = styled.div`
max-width: 1200px;
width: 100%;
margin: 0 auto;
padding: 0 16px;
display: flex;
justify-content: space-between;
align-items: center;
  
`
// export const StyledContainer = styled(Container)`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `

export const NavListWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;

    ${({mobile}) =>
    mobile &&
    `
        flex-direction: column;
        margin-top: 1em;

        > ${NavItem} {
          margin: 0;
          margin-top: 0.75em;
        }
      `};
  }
`

export const NavItem = styled.li`
  margin: 0 0.75em;
  font-family: ${theme.font.medium};
  ${theme.font_size.xsmall};

  a {
    text-decoration: none;
    opacity: 0.9;
    color: ${theme.color.accent};
  }

  &.active {
    a {
      opacity: 1;
    }
  }
`

export const MobileMenu = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: ${theme.color.regular};
`

export const Brand = styled.div`
  font-family: ${theme.font.extrabold};
  ${theme.font_size.regular};
  color: ${theme.color.primary};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      color: ${theme.color.primary};
      text-decoration: none;
    }
  }
`

export const ButtonFilled = styled.div`
    @media (max-width: ${theme.screen.sm}) {
      width: 80px;
      font-size: 11px;
      margin-left: 10px;
      padding: 8px 2px;
    }
    text-align: center;
    font-family: ${theme.font.semibold};
    ${theme.font_size.small};
    color: white;
    background: ${theme.color.secondary};
    border-radius: 18px;
    padding: 8px 16px;
    text-transform: uppercase;
    font-size: 12px;
    border: 2px solid ${theme.color.primary};
    margin-left: 10px;
    width: 100px;
    height: 35px;
    cursor: pointer;
`

export const ButtonOutline = styled.div`
    @media (max-width: ${theme.screen.sm}) {
        width: 80px;
        font-size: 11px;
        margin-left: 2px;
        padding: 8px 2px;
      
    }
    text-align: center;
    border: 2px solid ${theme.color.primary};
    font-family: ${theme.font.semibold};
    ${theme.font_size.small};
    color: ${theme.color.primary};
    background: none;
    border-radius: 18px;
    padding: 8px 16px;
    text-transform: uppercase;
    font-size: 12px;
    margin-left: 10px;
    width: 100px;
    height: 35px;
    cursor: pointer;
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
    @media (max-width: 1250px) {
        padding-right: 30px;
    }
`


export const Mobile = styled.div`
  display: none;

  @media (max-width: ${theme.screen.xs}) {
    display: block;
  }

  ${props =>
    props.hide &&
    `
    display: block;

    @media (max-width: ${props.theme.screen.xs}) {
      display: none;
    }
  `}
`
export const EmailInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.color.secondary};
  border-image: initial;
  border-radius: 23px;
  padding: 8px 16px;
  outline: 0;
  &:focus {
    box-shadow: inset ${theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

export const SubmitButton = styled.button`
  font-weight: 500;
  font-size: 13px;
  color: white;
  letter-spacing: 1px;
  height: 40px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${theme.color.secondary};
  border-radius: 23px;
  padding: 0 20px;
  border-width: 0;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0;
`