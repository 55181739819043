import React, {useContext, useEffect, useState} from 'react';
import {onValue, ref} from "firebase/database";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DocumentDisplay from "../DocumentDisplay";
import {databaseTypesConstants as dbConst} from "../../../../firebase/databaseConstants";
import {useParams, Link} from "react-router-dom";
import {FirebaseContext} from "../../../../contexts/FirebaseContext";

function FolderViewPage({signedIn}) {
    const [documentIds, setDocumentIds] = useState([]);
    const [folderIds, setFolderIds] = useState([]);
    const [folderName, setFolderName] = useState(null);
    const [parent, setParent] = useState(null);
    const appFirebase = useContext(FirebaseContext);

    const {folderId} = useParams();

    //Adding Segment Analytics page function on mount
    useEffect(() => {
        return () => {
            //Segment pageview call
            window.analytics.page();
        };
    }, []);

    useEffect(() => {
        if (signedIn) {
            const uid = appFirebase.auth.currentUser.uid;
            //Can change documentStoreString when how to store docs
            const fileDestinationPath = `/${dbConst.FOLDERS.name}/${folderId}/`
            return onValue(ref(appFirebase.database, fileDestinationPath), (snapshot) => {
                const userDocumentData = snapshot.val();
                setFolderName(userDocumentData[dbConst.FOLDERS.options.NAME])
                setParent(userDocumentData[dbConst.FOLDERS.options.PARENT])
                //Querying DID strings to get info about each document and put into object
                const ids = []
                // let folders = []
                for (const docId in userDocumentData[dbConst.FOLDERS.options.DOCUMENTS]) {
                    // if (docId === "folders") {
                    //     // folders =
                    // }
                    ids.push(docId);
                }
                setDocumentIds(ids);
            });
        } else {
            setDocumentIds([]);
        }
    }, [signedIn]);


    useEffect(() => {
        if (signedIn) {
            const uid = appFirebase.auth.currentUser.uid;
            //Can change documentStoreString when how to store docs
            return onValue(ref(appFirebase.database, `${dbConst.ROOT_DIRECTORY.name}/${uid}`),
                (snapshot) => {
                    if (snapshot.val() !== null) {
                        const ids = []
                        // console.log(snapshot.val())
                        onValue(ref(appFirebase.database,
                                `${dbConst.FOLDERS.name}/${snapshot.val()}/${dbConst.FOLDERS.options.FOLDERS}`),
                            (folderSnapshot) => {
                                if (folderSnapshot.val() !== null) {
                                    // let folders = []
                                    for (const folderId in folderSnapshot.val()) {
                                        // if (docId === "folders") {
                                        //     // folders =
                                        // }
                                        ids.push(folderId);
                                    }
                                    // console.log(ids)
                                    setFolderIds(ids);
                                }
                            })
                    }



                });
        } else {
            setDocumentIds([]);
        }
    }, [signedIn]);
    const linkTo = `/`
    return (
        <div>
            <div className="userFileDisplayOuterContainer">
                <div className="userHomeContainer">
                    <div className="flex justify-items-start font-grotesk-bold text-grays-headingTextGray text-3xl">
                        <Link to={linkTo}>
                            <div className="rounded-full px-4 py-1 cursor-pointer hover:bg-grays-hoverGray">
                                All documents
                            </div>
                        </Link>

                        <div className="pl-2">
                        <ChevronRightIcon style={{fontSize: "xx-large"}}/>
                        </div>
                        <div className="px-4 py-1">
                            {folderName}
                        </div>
                    </div>

                    <DocumentDisplay
                        appFirebase={appFirebase}
                        documentIds={documentIds}
                        folderIds={[]}
                        title={""}
                        rootFolderId={folderId}
                        isRoot={false}
                    />
                </div>
            </div>
        </div>
    );
}

export default FolderViewPage;