// toolConstants.js

export const SECTION_TOOL = "sectionTool";
export const MEASURE_TOOL = "measureTool";
export const EXPLODE_TOOL = "explodeTool";
export const SELECT_TOOL = "selectTool";
export const COMMENT_TOOL = "commentTool";
export const FIT_TOOL = "fitTool";
export const PEN_TOOL = "penTool";
export const COMPONENT_TOOL = "componentTool";
export const IS_OPEN = "isOpen";
// ... add other tool names as necessary
