import React, {useState} from 'react';
import {ButtonOutline} from "./style";
import ShareModal from "../common/ShareModal/ShareModal";

function ShareButton(props) {
    const { appFirebase, docId } = props;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div>
            <ButtonOutline onClick={handleOpen}>Share</ButtonOutline>
            <ShareModal handleClose={handleClose} open={open} appFirebase = {appFirebase} docId={docId}/>
        </div>
    );
}

export default ShareButton;