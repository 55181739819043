import {
    STLLoaderPlugin,
    XML3DLoaderPlugin,
    GLTFLoaderPlugin,
    OBJLoaderPlugin,
    XKTLoaderPlugin,
    WebIFCLoaderPlugin
} from "@xeokit/xeokit-sdk";
import * as WebIFC from "web-ifc";


export const loadModelByType = (viewerInstance, setViewer, setModel, fileEnding, url) => {

    return new Promise((resolve, reject) => {
            if (fileEnding) {
                let newModel = null;
                switch (fileEnding) {
                    case "stl":
                    case "stb":
                        const stlLoader = new STLLoaderPlugin(viewerInstance);
                        newModel = stlLoader.load({ // Model is an Entity
                            id: "myStlModel",
                            src: url,
                            edges: true,
                            smoothNormals: true,                // Default
                            smoothNormalsAngleThreshold: 20,    // Default
                            splitMeshes: true                   // Default
                        });
                        setModel(newModel);
                        setViewer(viewerInstance);
                        resolve(newModel);
                        break;
                    case "3dxml":
                        const XmlPlugin = new XML3DLoaderPlugin(viewerInstance, {
                            id: "XML3DLoader",  // Default value
                            workerScriptsPath: "../assets/zipjs/" // Path to zip.js workers dir
                        });
                        newModel = XmlPlugin.load({          // Returns an Entity that represents the model
                            id: "myXmlModel",
                            src: url,
                            edges: true
                        });
                        setModel(newModel);
                        setViewer(viewerInstance);
                        resolve(newModel);
                        break;
                    case "gltf":
                    case "glb":
                        //GLTF file loader
                        const gltfLoader = new GLTFLoaderPlugin(viewerInstance);
                        newModel = gltfLoader.load({                                    // Returns an Entity that represents the model
                            id: "myGltfModel",
                            src: url,
                            // metaModelSrc: "./models/gltf/OTCConferenceCenter/metaModel.json",     // Creates a MetaModel (see below)
                            edges: true
                        });
                        setModel(newModel);
                        setViewer(viewerInstance);
                        resolve(newModel);
                        break;
                    case "obj":
                        //OBJ file loader
                        const objLoader = new OBJLoaderPlugin(viewerInstance);
                        newModel = objLoader.load({                                    // Returns an Entity that represents the model
                            id: "myObjModel",
                            src: url,
                            edges: true
                        });
                        setModel(newModel);
                        setViewer(viewerInstance);
                        resolve(newModel);
                        break;
                    case "ifc":
                            const IfcAPI = new WebIFC.IfcAPI();
                            IfcAPI.SetWasmPath("../assets/", true);
                            IfcAPI.Init().then(r => {
                                const ifcLoader = new WebIFCLoaderPlugin(viewerInstance, {
                                    WebIFC,
                                    IfcAPI// Pass the initialized IfcAPI
                                });
                                newModel = ifcLoader.load({
                                    id: "myIFCModel",
                                    src: url,
                                    excludeTypes: ["IfcSpace"],
                                    edges: true
                                });

                                setModel(newModel);
                                setViewer(viewerInstance);
                                resolve(newModel);
                            });
                        break;
                    case "xkt":
                        //OBJ file loader
                        // console.log("loading xkt");
                        const xktLoader = new XKTLoaderPlugin(viewerInstance);
                        newModel = xktLoader.load({                                    // Returns an Entity that represents the model
                            id: "myXktModel",
                            src: url,
                            edges: false,
                            reuseGeometries: false
                        });
                        setModel(newModel);
                        setViewer(viewerInstance);
                        resolve(newModel);
                        break;
                    default:
                        break;

                }
            }
        }
    )
}