import React from 'react';
import {Switch} from "@mui/material";

function PermissionSettings({allowDownload, setAllowDownload}) {
    return (
        <div>
            <div className="flex justify-between">
                <div className="pb-3 pt-2">
                    <p className="text-base font-grotesk-normal text-grays-headingTextGray">Allow download</p>
                </div>


                <Switch
                    sx={{float: "right"}}
                    checked={allowDownload}
                    onChange={() => setAllowDownload(!allowDownload)}
                    inputProps={{'aria-label': 'controlled'}}
                />

            </div>
        </div>
    );
}

export default PermissionSettings;