import './index.css';
import React, {useEffect, useState, useReducer} from "react";
import FireBaseUsage from "./firebase/initFirebase";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import UserFileDisplayPage from "./components/pages/home/UserFileDisplayPage";
import {onAuthStateChanged, sendEmailVerification} from "firebase/auth";
import {BrowserRouter, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import DocumentViewPage from "./components/pages/file/DocumentViewPage";
import TopNavbar from "./components/navbar/TopNavbar";
import SettingsPage from "./components/pages/settings/SettingsPage";
import {httpsCallable} from "firebase/functions";
import SubscriptionPage from "./components/pages/settings/subSettings/SubscriptionPage";
import CheckoutPage from "./components/pages/checkout/checkoutPage";
import FolderViewPage from "./components/pages/home/folderDisplay/FolderViewPage";
import {CircularProgress, createTheme, ThemeProvider} from "@mui/material";
import {grey} from "@mui/material/colors";
import HubspotChat from "./components/common/HubspotChat";
import {signUpReducer, initialState, init} from './reducers/signUpModalReducer';
import {SignUpModalContext} from "./contexts/signUpModalContext"; // import our reducer and initial state
import {FirebaseContext} from "./contexts/FirebaseContext";
import PopUpModal from "./components/common/PopUpModal";
import {getUserPlan} from "./firebase/commonFunctions";
import OnboardingTour from "./components/common/onboardingTour/onboardingTour";
import {onValue, ref} from "firebase/database";
import {databaseTypesConstants as dbConst, databaseTypesConstants} from "./firebase/databaseConstants";

function App() {
    // Initialising firebase object
    const appFirebase = new FireBaseUsage();
    const [signedIn, setSignInStatus] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [emailVerified, setEmailVerified] = useState(null);
    const addUserNameOnSignUp = httpsCallable(appFirebase.function, 'addUserNameOnSignUp');
    const [loading, setLoading] = useState(true);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [openTrialEndedModal, setOpenTrialEndedModal] = useState(false);
    const [startOnboardingTour, setStartOnboardingTour] = useState(null);
    const [demoDocId, setDemoDocId] = useState(null);
    const [isPageReady, setIsPageReady] = useState(false);
    useEffect(() => {
        // Add logic here to determine if the page is "ready"
        setIsPageReady(true);
    }, []);
    const location = useLocation();
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(signUpReducer, initialState, init); // use imported reducer and initial state
    // Setting sign in state to firebase auth observer
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(appFirebase.auth, (user) => {
            setSignInStatus(!!user);
            setLoading(false);
            if (user) {
                setUserEmail(user.email);
                // Adding Segment Analytics on successful login
                window.analytics.identify(user.uid, {
                    userId: user.uid,
                    email: user.email,
                    name: user.displayName,
                });
            }
        });

        return () => {
            unsubscribe();
        };
    }, [signedIn]);

    useEffect(() => {
        if (appFirebase.auth.currentUser) {
            setEmailVerified(appFirebase.auth.currentUser.emailVerified)
        }
    }, []);

    useEffect(() => {
        if (appFirebase.auth.currentUser) {
            const userTourRef = ref(appFirebase.database, `/${dbConst.USER_MARKETING.name}/${appFirebase.auth.currentUser.uid}`);

            return onValue(userTourRef, (snapshot) => {


                if (!snapshot.val()) {
                    setStartOnboardingTour(true);

                } else {
                    // Starting tour if not finished and not skipped
                    if (!snapshot.val()[dbConst.USER_MARKETING.options.ONBOARD_TOUR_COMPLETED]
                        && !snapshot.val()[dbConst.USER_MARKETING.options.ONBOARD_TOUR_SKIPPED]) {
                        setStartOnboardingTour(true);
                    }
                    else {
                        setStartOnboardingTour(false);
                    }
                    const demoDocIdVal = snapshot.val()[dbConst.USER_MARKETING.options.DEMO_FILE_DOC_ID]
                    if (demoDocIdVal) {
                        setDemoDocId(demoDocIdVal);
                    }
                }
            });
        }
    }, [appFirebase.auth.currentUser]);


    /* Adding logic to only render the HubSpot chat in the home page */
    useEffect(() => {
        location.pathname === "/" ? setIsChatOpen(true) : setIsChatOpen(false);
    }, [location.pathname]);


    useEffect(async () => {
        const plan = await getUserPlan(appFirebase);
        if (plan < 0) {
            setOpenTrialEndedModal(true)
        }
    }, [signedIn]);


    function resendVerificationEmail() {
        sendEmailVerification(appFirebase.auth.currentUser)
            .then(() => {
                // console.log(`email verification sent to ${appFirebase.auth.currentUser.email}`)
                // // Email verification sent!
                // // ...
            });
    }

    const modalMaterialTheme = createTheme({
        palette: {
            primary: {
                main: "#00c4cc",
            },
            secondary: {
                main: grey[100],
            },
        },
    });

    // console.log("Sign in status", signedIn);
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: '/',
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                dispatch({type: 'CLOSE_MODAL'});
                setSignInStatus(true);
                addUserNameOnSignUp().then({});
                // await appFirebase.onUserCreation();
            },
        },
    };

    if (loading) {
        return (<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <ThemeProvider theme={modalMaterialTheme}>
                <CircularProgress className="align-self-center"/>
            </ThemeProvider>
        </div>);
    }

    return (
        <div>
            <FirebaseContext.Provider value={appFirebase}>
                <SignUpModalContext.Provider value={{state, dispatch}}>
                    {(startOnboardingTour && isPageReady) && <OnboardingTour startOnboardingTour={true} demoDocId={demoDocId}/>}
                    <Routes>

                        <Route exact path="/" element={
                            <div>

                                <TopNavbar
                                    signedIn={signedIn}
                                    setSignInStatus={setSignInStatus}
                                    uiConfig={uiConfig}
                                    displayAvatar={true}
                                />
                                <UserFileDisplayPage
                                    signedIn={signedIn}
                                />
                                {/*<VerifyEmailModal open={!emailVerified && signedIn} onClickResend={resendVerificationEmail} email={userEmail}/>*/}
                            </div>

                        }>

                        </Route>
                        <Route exact path="/settings" element={
                            <div>
                                <TopNavbar
                                    signedIn={signedIn}
                                    setSignInStatus={setSignInStatus}
                                    uiConfig={uiConfig}
                                    displayAvatar={true}
                                />
                                <SettingsPage
                                    signedIn={signedIn}
                                    setSignInStatus={setSignInStatus}
                                    uiConfig={uiConfig}
                                />
                            </div>
                        }>

                        </Route>
                        <Route exact path="/settings/subscription" element={
                            <div>
                                <TopNavbar
                                    signedIn={signedIn}
                                    setSignInStatus={setSignInStatus}
                                    uiConfig={uiConfig}
                                    displayAvatar={true}
                                />
                                <SubscriptionPage
                                    signedIn={signedIn}
                                />
                            </div>
                        }>
                        </Route>
                        <Route path="/files/:did" element={
                            <div>

                            <DocumentViewPage
                                signedIn={signedIn}
                                setSignInStatus={setSignInStatus}
                                uiConfig={uiConfig}
                            />
                            </div>
                        }
                        >
                        </Route>
                        <Route exact path="/folders/:folderId" element={
                            <div>
                                <TopNavbar
                                    signedIn={signedIn}
                                    setSignInStatus={setSignInStatus}
                                    uiConfig={uiConfig}
                                    displayAvatar={true}
                                />
                                <FolderViewPage
                                    signedIn={signedIn}
                                />
                                {/*<VerifyEmailModal open={!emailVerified && signedIn} onClickResend={resendVerificationEmail} email={userEmail}/>*/}
                            </div>

                        }>

                        </Route>
                        <Route exact path="/checkout" element={
                            <div>
                                <TopNavbar
                                    signedIn={signedIn}
                                    setSignInStatus={setSignInStatus}
                                    uiConfig={uiConfig}
                                    displayAvatar={true}
                                />
                                <CheckoutPage
                                    signedIn={signedIn}
                                    setSignInStatus={setSignInStatus}
                                    uiConfig={uiConfig}
                                />
                            </div>
                        }>
                        </Route>
                    </Routes>
                    {
                        signedIn &&
                        <PopUpModal
                            open={openTrialEndedModal}
                            handleClose={() => {
                                setOpenTrialEndedModal(false)
                            }}
                            text={{
                                heading: "Your Trial Has Ended - Continue Your Journey with Jinolo!",
                                body: ["Your one-month free trial with Jinolo has concluded. We hope you've enjoyed collaborating, creating, and communicating on our platform.",
                                    "Let's keep the innovation going! With a Jinolo subscription, you can continue to:",
                                    "✓ Save time & effort by viewing and commenting on 3D models directly in your browser.",
                                    "✓ Stay organized by keeping all your designs and discussions in one place.",
                                    "✓ Collaborate seamlessly by sharing 3D CAD models with clients, suppliers, and reviewers with just a click.",
                                    "Subscribe now for ongoing access to your files and to continue enjoying the benefits."
                                ]
                            }}
                            buttonOnClick={() => {
                                navigate('/checkout');
                            }}
                            buttonText={"Subscribe Now"}>
                        </PopUpModal>
                    }
                </SignUpModalContext.Provider>
            </FirebaseContext.Provider>
            {/*{<HubspotChat isChatOpen={isChatOpen}/>}*/}
        </div>
    );
}

export default App;



