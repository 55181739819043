import React, {useEffect, useState} from 'react';
import {AccountCircle, ArrowBackIos, Public} from "@mui/icons-material";
import {get, off, onValue, ref} from "firebase/database";
import {databaseTypesConstants as dbConst} from "../../../firebase/databaseConstants";
import {CircularProgress, ThemeProvider} from "@mui/material";
import {modalMaterialTheme} from "../muiThemes"


function PeopleAccess({docName, docId, setShowPeopleAccessScreen, appFirebase}) {
    const [userAccessObjects, setUserAccessObjects] = useState({});
    const [tempUserAccessEmails, setTempUserAccessEmails] = useState({});
    const [docOwner, setDocOwner] = useState(null);
    const [docOwnerObject, setDocOwnerObject] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isPublic, setIsPublic] = useState(null);

    const getUserObjectFromId = async (viewerId) => {
        let object = {}
        const userInfoRef = ref(appFirebase.database, `${dbConst.USERS.name}/${viewerId}`);
        const userInfoSnapshot = await get(userInfoRef);
        if (userInfoSnapshot.exists()) {
            object = {
                name: userInfoSnapshot.val()['firstname'] + " " + userInfoSnapshot.val()['lastname'],
                email: userInfoSnapshot.val().email,
                id: viewerId,
            }
        }
        return object;
    }
    const processUserIds = async (userIds) => {
        let newObjects = {};
        let tempUserAccessEmails = {};

        for (const key of Object.keys(userIds)) {
            if (key === 'public') {
                setIsPublic(true);
            } else if (key === 'tempUsers') {
                const subObjectKeys = Object.keys(userIds[key]);
                for (let email of subObjectKeys) {
                    tempUserAccessEmails[email] = true;
                }
                setTempUserAccessEmails(tempUserAccessEmails);
            } else if (key !== docOwner) {
                if (!(key in newObjects)) {
                    newObjects[key] = await getUserObjectFromId(key);
                }
            }
        }

        setUserAccessObjects(prevObjs => {
            return {...prevObjs, ...newObjects}
        });

        setLoading(false);
    };


    useEffect(async () => {
        if (appFirebase.auth.currentUser) {
            setLoading(true);
            const docAccessRef = ref(appFirebase.database, `${dbConst.DOCUMENT_ACCESS.name}/${docId}`);
            const docOwnerRef = ref(appFirebase.database, `${dbConst.DOCUMENTS.name}/${docId}/${dbConst.DOCUMENTS.options.CREATED_BY}`);
            const handleDocAccessSnapshot = async (snapshot) => {
                const docAccess = snapshot.val();
                if (docAccess !== null) {
                    await processUserIds(docAccess)
                }
            }
            const handleDocOwnerSnapshot = async (snapshot) => {
                const value = snapshot.val();
                if (value !== null) {
                    setDocOwner(value)
                    const ownerData = await getUserObjectFromId(value);
                    setDocOwnerObject(ownerData);
                }
            }
            await onValue(docOwnerRef, handleDocOwnerSnapshot)
            await onValue(docAccessRef, handleDocAccessSnapshot);

            return () => {
                off(docOwnerRef, 'value', handleDocOwnerSnapshot);
                off(docAccessRef, 'value', handleDocAccessSnapshot);
            }
        }
    }, []);
    console.log(loading)
    return (
        <div>
            <div className="mr-4 flex ">
                <ArrowBackIos className="cursor-pointer" onClick={() => setShowPeopleAccessScreen(false)}
                              sx={{color: "#898989", marginRight: 1, marginTop: 2}}/>
                <div>
                    <div className="mt-2 font-grotesk-bold text-xl text-grays-headingTextGray">People with access</div>
                    <div className="font-grotesk-normal text-sm text-grays-headingTextGray pb-3">Manage who can
                        view {docName}</div>
                    {
                        loading ?
                            <div className="flex justify-end items-end">
                                {/*<div className="">*/}
                                <ThemeProvider theme={modalMaterialTheme}>
                                    <CircularProgress className="align-self-center"/>
                                </ThemeProvider>
                            </div>
                            :
                            <div>
                                {
                                    isPublic &&
                                    <UserDisplay
                                        key={"public"}
                                        isPublic={true}
                                    />
                                }
                                {
                                    userAccessObjects && Object.values(userAccessObjects).map((userObj, index) => (
                                        <UserDisplay
                                            key={userObj.id}
                                            name={userObj.name}
                                            email={userObj.email}
                                            isOwner={userObj.id === docOwner}
                                        />
                                    ))
                                }
                                {
                                    Object.keys(tempUserAccessEmails).length > 0 &&
                                    <div>
                                        <div className="font-grotesk-bold text-md text-grays-headingTextGray">Invited
                                        </div>
                                        {Object.keys(tempUserAccessEmails).map(email => (
                                            <TempUserDisplay email={email}/>
                                        ))}
                                    </div>
                                }

                            </div>
                    }
                </div>

            </div>
        </div>
    );
}

export default PeopleAccess;


function TempUserDisplay({email = "public", isPublic = false}) {
    return (
        <div>
            <div className="flex items-center py-1">
                <div className="mr-4">
                    <AccountCircle fontSize="large" sx={{color: "#898989"}}/>
                </div>
                <div>
                    <div
                        className="font-grotesk-normal text-sm text-grays-headingTextGray">{email.replaceAll(",", ".")}
                    </div>
                </div>
            </div>
        </div>
    );
}

function UserDisplay({name = "", email = "", isOwner = false, isPublic = false}) {
    if (isPublic) {
        name = "Public";
        email = "Anyone with the link can view"
    }
    return (
        <div className="flex justify-between">
            <div className="flex items-center py-1">
                <div className="mr-4">
                    {isPublic ?
                        <Public fontSize="large" sx={{color: "#898989"}}/>
                        :
                        <AccountCircle fontSize="large" sx={{color: "#898989"}}/>
                    }
                </div>

                <div className="flex justify-between">
                    <div>
                        <div
                            className="font-grotesk-bold text-md text-grays-headingTextGray">{name}
                        </div>
                        <div
                            className="font-grotesk-normal text-sm text-grays-headingTextGray">{email.replaceAll(",", ".")}
                        </div>
                    </div>
                    <div className="">
                        {isOwner && <div
                            className="absolute right-6 bg-grays-hoverGray
                            rounded-full px-4 py-1 mt-1 font-grotesk-normal text-sm
                            text-grays-headingTextGray align-self-end">
                            Owner
                        </div>}
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>
    );
}
