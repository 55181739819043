import React, {useEffect, useState} from 'react';
import {List, TextField} from "@mui/material";
import Comment from "./Comments/Comment";
import {onValue, ref} from "firebase/database";
import CloseIcon from '@mui/icons-material/Close';
import {useSidePanel} from "../../../../../../contexts/SidePanelRightContext";
import { ReactComponent as CommentSVG } from '../../../../../../assets/no_comments_file_page.svg';

const textFieldStyle = {
    position: "fixed",
    bottom: 10,
    right: 24,
    backgroundColor: '#f4f4f4',
    width: 400,
};
const textFieldStyleForPoint = {
    position: "fixed",
    bottom: 10,
    right: 24,
    backgroundColor: '#ae14d7',
    width: 400,
};

function CommentChannel(props) {
    const {signedIn, appFirebase, docId, onClickComment} = props;
    const [commentIds, setCommentIds] = useState([]);
    const { closeSidePanel } = useSidePanel();
    const keyPress = (e) => {
        if (e.keyCode === 13) {
            // console.log('value', e.target.value);
            const commentText = e.target.value;
            if (commentText.replace(/ /g, '') !== '') {
                appFirebase.addComment(e.target.value, docId);
                e.target.value = "";
                //Adding tracking to Segment Analytics
                window.analytics.track('Add Comment', {
                    "Author": appFirebase.auth.currentUser.uid,
                });
                // onValue(ref(appFirebase.database, '/documents/' + docId), (snapshot) => {
                //     window.analytics.track('Comment', {
                //         "FileName": snapshot.val().name,
                //         "FileType": snapshot.val().name.split(".").pop(),
                //         "Author": appFirebase.auth.currentUser.uid,
                //     })});
            }

        }
    }
    useEffect(() => {
        if (signedIn) {
            const documentCommentsUrl = '/comments/' + docId;
            const commentsRef = ref(appFirebase.database, documentCommentsUrl);
            const unsubscribe = onValue(commentsRef, (snapshot) => {
                const commentData = snapshot.val();
                //Querying DID strings to get info about each document and put into object
                const ids = []
                for (const commentId in commentData) {
                    ids.push(commentId);
                }
                setCommentIds(ids);
            });
            // Return a cleanup function from useEffect that calls the unsubscribe function
            return () => {
                unsubscribe();
            };
        } else {
            setCommentIds([]);
        }
    }, [signedIn]);

    return (
        <>
            <div className="commentContainer">
                <div className="flex justify-between mt-3">
                    <div/>
                    <div className="font-grotesk-bold text-large text-grays-headingTextGray text-center">
                        Comments
                    </div>
                    <div onClick={closeSidePanel}>
                        <div>
                            <CloseIcon className="cursor-pointer text-sm text-textGray"/>
                        </div>
                    </div>
                </div>
                { !signedIn ?
                    <div className="absolute flex flex-col justify-center items-center px-4" style={{ top: '45%' }}>
                        <CommentSVG className="w-32 h-32 mx-2 my-2" />
                        <span className="text-center font-grotesk-normal text-xl pt-5 px-5 text-grays-headingTextGray">
                            Only signed-in users can view and add comments. Log in to join the conversation.</span>
                    </div>

                    :
                    <div>
                        {commentIds.length === 0 ?
                            <div className="absolute flex flex-col justify-center items-center px-4" style={{ top: '45%' }}>
                                <CommentSVG className="w-32 h-32 mx-2 my-2" />
                                <span className="text-center font-grotesk-normal text-xl pt-5 px-5 text-grays-headingTextGray">
                            Seems like there are no comments yet. How about getting the conversation started?</span>
                            </div> :
                            <List>
                                {commentIds.map(commentId =>
                                    <Comment
                                        key={commentId}
                                        commentId={commentId}
                                        docId={docId}
                                        uid={appFirebase.auth.currentUser.uid}
                                        database={appFirebase.database}
                                    />)}
                            </List>
                        }
                            <TextField
                                label="Add Comment"
                                id="fullWidth"
                                style={textFieldStyle}
                                onKeyDown={keyPress}
                            />
                    </div>
                }
            </div>
        </>
    );
}

export default CommentChannel;