import React, {useEffect, useState} from 'react';
import "./annotation.scss";
import {TextField} from "@mui/material";
import {onValue, ref} from "firebase/database";
import {destroyUnusedAnnotation} from "../CADViewerUtils/AnnotationHandling";
const textFieldStyle = {
    position: "fixed",
    bottom: 10,
    right: 24,
    backgroundColor: '#f4f4f4',
    width: 400,
};
function findLabelPosition(worldPos) {
    console.log(worldPos);
    const left = Math.abs(20 + Math.floor( worldPos[0] + 20) )+ "px";
    // const left = 20 + Math.floor( 20) + "px";
    const top = Math.abs(Math.floor( worldPos[0] - 17)) + "px";
    // const z = 90005 + 0 + "px";
    return {
        left: left,
        top: top,
    }
}
function AnnotationTextBox({appFirebase, docId, canvasPos, worldPos, setStartCommentState, onClickAnnotationsRef, annotationCounterRef}) {
    const [typingOn, setTypingOn] = useState(false);
    const keyPress = (e) => {
        if (e.keyCode === 13) {
            // console.log('value', e.target.value);
            const positionCoordinates = {
                left: canvasPos[0] + 35 + 'px',
                top: canvasPos[1] -17 + 'px',
            }
            window.analytics.track('Add 3D Comment', {
            });
            appFirebase.addPointComment(e.target.value, canvasPos, worldPos, docId);
            e.target.value = "";
            destroyUnusedAnnotation( onClickAnnotationsRef, annotationCounterRef );
            setStartCommentState(false);
            // startCommentRef.current = false;

        }
    }
    // console.log(authorName);
    return (
        <div>
            <div className='annotation-label-oncreation' style={{left: canvasPos[0] + 35 + 'px', top: canvasPos[1] -17 + 'px'}}>
                {/*<div className='annotation-label-oncreation'>*/}
                <div className='font-grotesk-bold font-xl m-2 w-fit pb-2'>{appFirebase.auth.currentUser.displayName}</div>
                <TextField
                    label="Add Comment"
                    id="fullWidth"
                    // style={textFieldStyle}
                    onKeyDown={keyPress}
                />
                {/*<div className='font-grotesk-normal m-2 w-fit'>{{description}}</div>*/}
            </div>
        </div>
    );
}

export default AnnotationTextBox;
