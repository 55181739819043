import React, {Component, useState} from 'react';
import {Alert, Box, createTheme, LinearProgress, Modal, ThemeProvider} from "@mui/material";
import 'firebase/compat/auth';
import styled from "styled-components";
import theme from "../../../style/theme";
import CloseIcon from '@mui/icons-material/Close';
import * as PropTypes from "prop-types";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 4,
    borderRadius: 3,
    outline: 0,
};

function LoadingModal (props) {
    const {
        uploadStatus,
        handleClose,
        open,
        filename,
        uploadSuccess,
        uploadFailure,
        percentage
    } = props;
    const [keepOpen, setKeepOpen] = useState(uploadStatus);
    const onClickX = () => {
        setKeepOpen(false)
    }
    const loadingTheme = createTheme({
        palette: {
            primary: {
                main: '#121640'
            },
            secondary: {
                main: "#e43397"
            }
        }
    });


    // const handleClose() {}
    return (
        <div>
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    <div className="cursor-pointer">
                        <CloseIcon onClick={() => onClickX()}
                                   sx={{color: "#898989", float: "right", marginRight: 1, marginTop: 1}}/></div>

                    <div className="py-10 px-10 align-content-center">
                        <PageSubHeading>Uploading new file to Jinolo </PageSubHeading>
                        {uploadStatus && <div>
                        <ThemeProvider theme={loadingTheme}>
                            <LinearProgress variant="determinate" value={percentage} />
                            <div className="text-textGray font-grotesk-normal text-center text-sm mt-2">
                                {percentage} %
                            </div>
                        </ThemeProvider>
                        </div>}
                        {uploadStatus && <Words >{filename}</Words>}
                        {(filename.endsWith("stp") || filename.endsWith("step")) && <div className="pt-2 font-grotesk-normal font-navy">Upload may take a few minutes. The file will appear on the homepage when upload is completed.</div> }
                        {/*{uploadSuccess && <Alert severity="success">Successfully uploaded {filename}</Alert>}*/}
                        {uploadFailure && <Alert severity="error">An error has occurred.</Alert>}

                    </div>
                </Box>
            </Modal>

        </div>
    );
}

export default LoadingModal;

const PageSubHeading = styled.div`
  font-family: ${theme.font.medium};
  font-size: 24px;
  letter-spacing: -1px;
  line-height: 30px;
  padding-top: 10px;
  text-align: center;
  padding-left: 20px;
  color: #4d4d4d;
  margin-bottom: 10px;
  padding-bottom: 25px;
`;

const Words = styled.div`
  font-family: ${theme.font.normal};
  font-size: 15px;
  text-align: left;
  color: #4d4d4d;
  margin-bottom: 10px;
  a {
    color: ${theme.color.accent};
    cursor: pointer;
    text-decoration: underline ${theme.color.accent};
    font-family: ${theme.font.semibold};
  }

`;

const ButtonFilled = styled.div`
  @media (max-width: ${theme.screen.sm}) {
    //width: 50px;
    font-size: 11px;
  }
  background: ${theme.color.primary};
  border: 2px solid ${theme.color.primary};
  padding: 8px 16px;
  font-family: ${theme.font.bold};
  float: right;
  text-align: center;
  font-family: ${theme.font.semibold};
  ${theme.font_size.small};
  color: white;
  border-radius: 18px;
  text-transform: uppercase;
  font-size: 12px;
  margin-left: 10px;
  width: auto;
  height: 35px;
  cursor: pointer;

  :hover {
    background-color: #f4f4f4;
    border: 2px solid ${theme.color.primary};
    color: ${theme.color.primary};
  }
`