// Comment in the chat written by the authenticated user
import React from 'react';
import PopOverCommentMenu from "./PopOverCommentMenu";

function ChatCommentAuthor({displayAuthor, formattedDate, text, commentId, docId}) {
    return (
        <div className="ml-20">
            <div className="m-3 my-5 mx-5">
                <div className="flex flex-row mx-5">
                    <div className="block text-gray-100 text-xs mx-1">{displayAuthor}</div>
                    <div className="block text-gray-100 text-xs mx-1">⋅</div>
                    <div className="block text-gray-100 text-xs mx-1">{formattedDate}</div>
                </div>
                <div className="bg-primary rounded-2xl p-3">
                    <div className="flex justify-between">
                        <div className="flex flex-row">
                            <div className="ml-2 mr-6">
                                <span className="select-text pt-1 block text-white font-grotesk-semi-bold">{text}</span>
                            </div>
                        </div>
                        <PopOverCommentMenu commentId={commentId} docId={docId}/>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ChatCommentAuthor;