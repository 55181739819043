import React, {useCallback, useState} from "react";
import {useSortableData} from "./hooks/useSortableData";
import {useFilterData} from "./hooks/useFilterData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faCloudArrowUp} from "@fortawesome/free-solid-svg-icons";
import FolderListItem from "./FolderListItem";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import DocumentListItem from "./DocumentListItem";
import {Alert, Snackbar} from "@mui/material";
import {httpsCallable} from "firebase/functions";
import {useDropzone} from "react-dropzone";
import useUpload from "./hooks/useUpload";

function DocumentList(props) {
    const {
        files,
        appFirebase,
        handleDetailChange,
        title,
        rootFolderId,
        isRoot,
        setDocMoveCompleted
    } = props;

    const [searchQuery, setSearchQuery] = useState('');
    const [showErrorDelete, setShowErrorDelete] = useState(false);
    const [isMoving, setIsMoving] = useState(false);
    const [folderIdMoving, setFolderIdMoving] = useState(null);
    const {items, requestSort, sortConfig} = useSortableData(files);
    const filteredItems = useFilterData(items, searchQuery);
    const moveFolderFunction = httpsCallable(appFirebase.function, 'folders-moveFileToFolder')
    const { startedUpload, setStartedUpload, uploadSuccess, setUploadSuccess, startUploadFile } = useUpload();

    const onDrop = useCallback(async (acceptedFiles) => {
        // Function to handle the file uploads sequentially
        const uploadFilesSequentially = async () => {
            setStartedUpload(true);
            for (const file of acceptedFiles) {
                try {
                    await startUploadFile(file);
                    // Break the loop if the upload limit is reached
                    // if (unableToUploadFreeFileLimitReached) {
                    //     break;
                    // }
                } catch (error) {
                    // Handle specific errors or break the loop if needed
                }
            }
            setStartedUpload(false);
        };

        // Start the upload process
        await uploadFilesSequentially();
    }, [startUploadFile]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, noClick: true});

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };
    const handleDragStart = (index) => {

        console.log(index)
    }

    const handleDragEnd = async (result) => {
        const {source, destination} = result;
        if (!destination) {
            return;
        }
        const filteredDocs = filteredItems.filter(item => item.type === "doc")
        if (isMoving === false && source.droppableId !== destination.droppableId && destination.droppableId.startsWith("folder-")) {
            // Set the state to indicate that a file is being moved
            setIsMoving(true);
            let folderId = destination.droppableId;
            setFolderIdMoving(folderId);

            folderId = folderId.replace("folder-", "");
            try {
                const moveFolderData = {
                    destinationFolderId: folderId,
                    docId: filteredDocs[source.index].id,
                    parentFolderId: rootFolderId
                }
                await moveFolderFunction(moveFolderData);
                // await appFirebase.moveFileToFolder(folderId, filteredDocs[source.index].id, rootFolderId);
            } catch (error) {
                setShowErrorDelete(true);
            } finally {
                // Unset the state once the move is completed

                setIsMoving(false);
                setDocMoveCompleted(true);
                // window.location.reload();
            }
        } else if (source.droppableId === destination.droppableId && destination.droppableId === "file-list") {
            const offset = filteredItems.filter(item => item.type === "folder").length;
            const startIndex = source.index + offset;
            const endIndex = destination.index + offset;
            let temp = filteredItems[startIndex];
            filteredItems[startIndex] = filteredItems[endIndex];
            filteredItems[endIndex] = temp;
        }
    };

    return (
        <div>

            <div className="flex justify-between">
                <div className="font-grotesk-bold text-grays-headingTextGray text-3xl pl-4 mb-8">
                    {title}
                </div>
                <input
                    type="text"
                    placeholder="Search files..."
                    value={searchQuery}
                    onChange={handleSearch}
                    className="py-1 border border-2 border-grays-lineGray focus:outline-none focus:border-grays-headingTextGray px-3 rounded-full mr-4 h-10 align-self-end"
                />
            </div>
            {/*border-grays-background*/}
            <div {...getRootProps()} style={{ minHeight: '60vh' }} className={`border-2 rounded-lg ${isDragActive ?
                `border-primary
                 bg-lights-lightPrimary` :
                `border-grays-background`}`
            }>
                <input {...getInputProps()}/>
                {(files.length === 0) ?
                    <div className="noDocumentsNotification">You have no uploaded documents.</div>
                    :
                    <div className="grid grid-cols-1">
                        <div className="px-8 pb-2">
                            <div className="grid grid-cols-4 font-grotesk-bold text-grays-headingTextGray">
                                <div className="">
                                    <button
                                        onClick={() => requestSort('fileName')}
                                    >
                                        File Name
                                        {sortConfig && sortConfig.key === 'fileName' && (
                                            <span>
                {sortConfig.direction === 'ascending' ?
                    <FontAwesomeIcon icon={faArrowUp} className="pl-1 text-gray-200 text-sm"/> :
                    <FontAwesomeIcon icon={faArrowDown} className="pl-1 text-gray-200 text-sm"/>}
              </span>
                                        )}
                                    </button>
                                </div>
                                <div className="place-self-center">
                                    <button
                                        onClick={() => requestSort('createdDate')}
                                    >
                                        Uploaded
                                        {sortConfig && sortConfig.key === 'createdDate' && (
                                            <span>
                                        {sortConfig.direction === 'ascending' ?
                                            <FontAwesomeIcon icon={faArrowUp} className="pl-1 text-gray-200 text-sm"/> :
                                            <FontAwesomeIcon icon={faArrowDown}
                                                             className="pl-1 text-gray-200 text-sm"/>}
                                        </span>
                                        )}
                                    </button>
                                </div>
                                <div className="place-self-center">
                                    {/*<button*/}
                                    {/*    onClick={() => console.log("clicked sort")}*/}
                                    {/*>*/}
                                        File Size

                                    {/*</button>*/}
                                </div>
                                <div className="place-self-center">
                                    <button
                                        // className="bg-gray-100 text-gray-600 px-2 py-1 rounded-lg"
                                        onClick={() => requestSort('author')}
                                    >
                                        Owned by
                                        {sortConfig && sortConfig.key === 'author' && (
                                            <span>
                {sortConfig.direction === 'ascending' ?
                    <FontAwesomeIcon icon={faArrowUp} className="pl-1 text-gray-200 text-sm"/> :
                    <FontAwesomeIcon icon={faArrowDown} className="pl-1 text-gray-200 text-sm"/>}
              </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <DragDropContext onDragEnd={handleDragEnd}>

                            <div>
                                {filteredItems.filter(file => file.type === "folder").map((file, index) => (
                                    <div key={file.id}>
                                        {file.type === "folder" &&
                                            <Droppable droppableId={`folder-${file.id}`} type="FILES">
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        style={{minHeight: '70px', minWidth: '100px'}}
                                                        {...provided.droppableProps}
                                                        className={`border-bottom border-grays-lineGray border-b-2 ${snapshot.isDraggingOver ? `rounded-xl bg-grays-hoverGray` : ``}`}
                                                    >
                                                        <FolderListItem key={file.id}
                                                                        docId={file.id}
                                                                        fileName={file.fileName}
                                                                        author={file.author}
                                                                        date={file.createdDate}
                                                                        appFirebase={appFirebase}
                                                                        onDetailChange={(newDocumentDetail) => handleDetailChange(index, newDocumentDetail)}
                                                                        draggingOver={snapshot.isDraggingOver}
                                                                        isCurrentlyMoving={file.id === folderIdMoving}

                                                        />


                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        }
                                    </div>
                                ))}

                                <Droppable droppableId="file-list" type="FILES">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={{minHeight: '100px', minWidth: '100px'}}
                                            {...provided.droppableProps}
                                        >
                                            {/*<h2 className="py-10 bg-accent text-white" >I am a third droppable that contains files: (Delete me!)</h2>*/}
                                            {filteredItems.filter(item => item.type === "doc").map((file, index) => (
                                                <Draggable key={file.id} draggableId={file.id} index={index}
                                                           onStart={() => handleDragStart(index)}
                                                           isDragDisabled={isMoving}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <>
                                                                {
                                                                    file.type === "doc" &&
                                                                    <DocumentListItem key={file.id}
                                                                                      docId={file.id}
                                                                                      fileName={file.fileName}
                                                                                      author={file.author}
                                                                                      authorUid={file.authorUid}
                                                                                      date={file.createdDate}
                                                                                      size={file.size}
                                                                                      appFirebase={appFirebase}
                                                                                      isBeingDragged={snapshot.isDragging}
                                                                                      isRoot={isRoot}
                                                                                      currentFolderId={rootFolderId}
                                                                    />

                                                                }
                                                            </>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </DragDropContext>

                    </div>

                }
                {isDragActive &&
                    <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 text-lg bg-primary text-white text-center rounded-full px-6 py-4 font-grotesk-semi-bold">
                        <FontAwesomeIcon icon={faCloudArrowUp} className="pr-5 text-gray-200 text-xl"/>
                        Drop files to upload
                    </div>
                }

            </div>
            <Snackbar
                open={showErrorDelete}
                onClose={() => setShowErrorDelete(false)}
                autoHideDuration={6000}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                sx={{zIndex: 9000000}}
            >
                <Alert
                    severity="error"
                    onClose={() => setShowErrorDelete(false)}

                >
                    <div className="font-grotesk-normal">
                        You can't move documents if you are not the owner.
                    </div>

                </Alert>
            </Snackbar>
        </div>
    );
}

export default DocumentList;