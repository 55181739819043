import React from 'react';
import {Grid, TextField} from "@mui/material";
import {SubmitButton} from "../../navbar/style";
import LockIcon from '@mui/icons-material/Lock';
import {Link} from "react-router-dom";

function EmailShare({
                        shareEmailRef,
                        shareMessageRef,
                        submitShareRequest,
                        docName,
                        isPremium
                    }) {
    return (
        <div>
            <div className="mt-2 font-grotesk-bold text-xl text-grays-headingTextGray">Share via email</div>
            <div className="font-grotesk-normal text-sm text-grays-headingTextGray pb-3">
                Invite collaborators via email to review {docName}
            </div>

            {!isPremium && (
                <div className="mb-4 p-4 rounded bg-grays-hoverGray text-grays-headingTextGray font-grotesk-normal">
                    <LockIcon className="mr-2"/>
                    Private email sharing is available for premium users only.
                    <Link to="/checkout" className="pl-1 underline text-blue-600">Upgrade now</Link> or make the
                    document public to share with a link.
                </div>
            )}

            <TextField
                style={{marginBottom: "20px"}}
                fullWidth="standard-basic"
                label="Email"
                type="email"
                inputRef={shareEmailRef}
                variant="standard"
                disabled={!isPremium}
            />
            <TextField
                style={{marginBottom: "30px"}}
                fullWidth="standard-basic"
                id="outlined-basic"
                label="Message (Optional)"
                inputRef={shareMessageRef}
                variant="filled"
                disabled={!isPremium}
            />

            <Grid
                container
                justifyContent="flex-end"
            >
                {/*<SubmitButton*/}
                {/*    onClick={() => submitShareRequest()}*/}
                {/*    disabled={!isPremium}*/}
                {/*>*/}
                {/*    Share*/}
                {/*</SubmitButton>*/}

                <button
                    className={`font-medium text-xs text-white tracking-wide h-10 block ml-2
                        uppercase whitespace-nowrap ${isPremium ? "bg-primary cursor-pointer" : "bg-grays-lineGray"} 
                        rounded-full px-5 border-0 focus:outline-none`}
                    onClick={() => submitShareRequest()}
                    disabled={!isPremium}
                >
                    Share
                </button>
            </Grid>

        </div>
    );
}

export default EmailShare;
