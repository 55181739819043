import React from 'react';
import {Box, Modal} from "@mui/material";
import 'firebase/compat/auth';
import styled from "styled-components";
import theme from "../../style/theme";
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 4,
    borderRadius: 3,
    outline: 0,
};


function PopUpModal ({open, handleClose, text, buttonOnClick, buttonText}) {
    const handleClick = () => {
        buttonOnClick()
    }
    const onClickX = () => {
        handleClose();
    }


    // const handleClose() {}
    return (
        <div>
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                <div className="cursor-pointer">
                    <CloseIcon onClick={() => onClickX()}
                               sx={{color: "#898989", float: "right", marginRight: 1, marginTop: 1}}/></div>
                    <div className="py-10 px-10 align-content-center">
                        <PageSubHeading>{text.heading} </PageSubHeading>
                        {text.body.map((paragraph, index) =>
                            <Words
                                key={index}
                                className="px-2"
                            >
                                {paragraph}
                            </Words>
                        )}
                        <ButtonFilled onClick={() => {
                            handleClick();
                            handleClose();
                        }} className="mb-8 mt-5">{buttonText}</ButtonFilled>

                    </div>
                </Box>
            </Modal>

        </div>
    );
}

export default PopUpModal;

const PageSubHeading = styled.div`
  font-family: ${theme.font.medium};
  font-size: 24px;
  letter-spacing: -1px;
  line-height: 30px;
  padding-top: 10px;
  text-align: center;
  padding-left: 20px;
  color: #4d4d4d;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

const Words = styled.div`
  font-family: ${theme.font.normal};
  font-size: 15px;
  text-align: left;
  color: #4d4d4d;
  margin-bottom: 10px;
  a {
    color: ${theme.color.accent};
    cursor: pointer;
    text-decoration: underline ${theme.color.accent};
    font-family: ${theme.font.semibold};
  }
`;

const ButtonFilled = styled.div`
  @media (max-width: ${theme.screen.sm}) {
    //width: 50px;
    font-size: 11px;
  }
  background: ${theme.color.primary};
  border: 2px solid ${theme.color.primary};
  padding: 8px 16px;
  font-family: ${theme.font.bold};
  //float: center;
  text-align: center;
  font-family: ${theme.font.semibold};
  ${theme.font_size.small};
  color: white;
  border-radius: 18px;
  text-transform: uppercase;
  font-size: 12px;
  margin-left: 10px;
  margin-top: 40px;
  width: auto;
  height: 35px;
  cursor: pointer;

  :hover {
    background-color: #f4f4f4;
    border: 2px solid ${theme.color.primary};
    color: ${theme.color.primary};
  }
`