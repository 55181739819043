import React, {useContext, useEffect, useState} from 'react';
import "../../../style/userFileDisplay.scss";
import {onValue, ref, off} from "firebase/database";
import UploadButton from "./UploadButton";
import DocumentDisplay from "./DocumentDisplay";
import {databaseTypesConstants as dbConst} from "../../../firebase/databaseConstants";
import {FirebaseContext} from "../../../contexts/FirebaseContext";
import UploadProvider from "./hooks/UploadProvider";
import OnboardingTour from "../../common/onboardingTour/onboardingTour";
import UploadErrorHandler from "./UploadErrorHandler";

function UserFileDisplayPage({signedIn}) {
    const [documentIds, setDocumentIds] = useState([]);
    const [folderIds, setFolderIds] = useState([]);
    const [rootFolderId, setRootFolderId] = useState(null);
    const [docMoveCompleted, setDocMoveCompleted] = useState(false);
    const appFirebase = useContext(FirebaseContext);

    //Adding Segment Analytics page function on mount
    useEffect(() => {
        return () => {
            //Segment pageview call
            window.analytics.page();
        };
    }, []);

    useEffect(() => {
        if (signedIn) {
            setDocMoveCompleted(false);
            const uid = appFirebase.auth.currentUser.uid;
            const rootRef = ref(appFirebase.database, `${dbConst.ROOT_DIRECTORY.name}/${uid}`);

            const handleRootSnapshot = async (snapshot) => {
                const rootValue = snapshot.val();

                let docRef;
                let folderRef;

                if (rootValue === null) {
                    docRef = `${dbConst.USER_DOCUMENTS.name}/${uid}`;
                    folderRef = null;
                } else {
                    setRootFolderId(rootValue);
                    docRef = `${dbConst.FOLDERS.name}/${rootValue}/${dbConst.FOLDERS.options.DOCUMENTS}`;
                    folderRef = `${dbConst.FOLDERS.name}/${rootValue}/${dbConst.FOLDERS.options.FOLDERS}`;
                }

                const docDbRef = ref(appFirebase.database, docRef);

                const handleDocSnapshot = (docSnapshot) => {
                    const docValue = docSnapshot.val();
                    if (docValue !== null) {
                        const ids = Object.keys(docValue);
                        setDocumentIds(ids);
                    } else {
                        setDocumentIds([])
                    }
                };

                onValue(docDbRef, handleDocSnapshot);

                if (folderRef) {
                    const folderDbRef = ref(appFirebase.database, folderRef);

                    const handleFolderSnapshot = (folderSnapshot) => {
                        const folderValue = folderSnapshot.val();
                        if (folderValue !== null) {
                            const ids = Object.keys(folderValue);
                            setFolderIds(ids);
                        } else {
                            setFolderIds([])
                        }
                    };

                    onValue(folderDbRef, handleFolderSnapshot);

                    return () => {
                        off(docDbRef, 'value', handleDocSnapshot);
                        off(folderDbRef, 'value', handleFolderSnapshot);
                    };
                } else {
                    return () => {
                        off(docDbRef, 'value', handleDocSnapshot);
                    };
                }
            };

            onValue(rootRef, handleRootSnapshot);
            return () => off(rootRef, 'value', handleRootSnapshot);
        } else {
            setDocumentIds([]);
            setFolderIds([]);
        }
    }, [signedIn, docMoveCompleted]);
    return (
        <div>
            <div className="userFileDisplayOuterContainer">
                <div className="userHomeContainer">
                    <div className="onboarding-tour-home-screen font-grotesk-bold text-grays-headingTextGray text-3xl pl-4">
                        Upload a new document
                    </div>
                    <div className="text-textGray font-grotesk-normal pl-4 text-sm mb-8">
                        Supported file formats: stl, obj, gltf, 3dxml, step, ifc, pdf
                    </div>
                    <UploadProvider>
                        <UploadErrorHandler signedIn={signedIn} />
                        <UploadButton signedIn={signedIn}/>

                        <DocumentDisplay
                            documentIds={documentIds}
                            folderIds={folderIds}
                            title={"All documents"}
                            rootFolderId={rootFolderId}
                            isRoot={true}
                            setDocMoveCompleted={setDocMoveCompleted}
                        />

                    </UploadProvider>
                </div>
            </div>
            {/*<HubspotChat portalId="22162875" />*/}
        </div>
    );
}

export default UserFileDisplayPage;