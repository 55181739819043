import {
    PointLight,
} from "@xeokit/xeokit-sdk";
//Function to create custom lighting for stl files (default is too dark)
export const setCustomLighting = (viewerInstance, setViewer, fileEnding) => {
    if (fileEnding === "stl" || fileEnding === "stb") {
        viewerInstance.scene.clearLights();

        new PointLight(viewerInstance.scene, {
            id: "keyLight",
            pos: [-80, 60, 80],
            color: [0.7, 0.7, 0.8],
            intensity: 1.3,
            space: "world"
        });

        new PointLight(viewerInstance.scene, {
            id: "fillLight",
            pos: [80, 40, 40],
            color: [0.7, 0.7, 0.8],
            intensity: 1.3,
            space: "world"
        });
        new PointLight(viewerInstance.scene, {
            id: "rimLight",
            pos: [-20, 80, -80],
            color: [0.7, 0.7, 0.8],
            intensity: 1.3,
            space: "world"
        });
    }
    setViewer(viewerInstance);
};