import React, {useState} from 'react';
import CheckoutButton from "./checkoutButton";

// Define plan constants
const PLANS = {
    STARTER: 'starter',
    PROFESSIONAL: 'professional',
};

const BILLING_INTERVALS = {
    MONTHLY: 'monthly',
    YEARLY: 'yearly',
};

function Checkout({appFirebase}) {
    const [selectedBillingInterval, setSelectedBillingInterval] = useState('monthly');
    const [selectedPlan, setSelectedPlan] = useState('professional');

    const prices = {
        monthly: {
            [PLANS.STARTER]: 10,
            [PLANS.PROFESSIONAL]: 32
        },
        yearly: {
            [PLANS.STARTER]: 96,
            [PLANS.PROFESSIONAL]: 348
        },
    };

    const handlePlanChange = (e) => {
        setSelectedBillingInterval(e.target.value);
    };

    const handleTypeChange = (e) => {
        setSelectedPlan(e.target.value);
    };

    const finalPrice = prices[selectedBillingInterval][selectedPlan];

    return (
        <div className=" md:py-40 py-20 px-5 items-center">
            <div className="flex items-center justify-center cursor-pointer">
                <div className="font-grotesk-bold text-navy text-3xl pt-7 pb-5 px-3">Upgrade your Plan</div>
            </div>
            <div className="border bottom-2 border-grays-lineGray md:mx-20 lg:mx-30"/>
            <div className="max-w-md mx-auto my-10 pt-10">

                <div className="mb-10">
                    <label className="block text-gray-700 font-grotesk-bold text-base font-bold mb-2">
                        Plan
                    </label>
                    <div className="flex flex-col">

                        <div className="mb-3">
                            <div className="flex items-center">

                                <input
                                    type="radio"
                                    id={PLANS.STARTER}
                                    value={PLANS.STARTER}
                                    checked={selectedPlan === PLANS.STARTER}
                                    onChange={handleTypeChange}
                                    className="mr-2"
                                />
                                <label htmlFor="monthly">Starter</label>

                            </div>
                            <div className="text-bubbleGray font-grotesk-normal text-sm px-5">
                                US${prices[BILLING_INTERVALS.MONTHLY][PLANS.STARTER]}/month
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="flex items-center">

                                <input
                                    type="radio"
                                    id={PLANS.PROFESSIONAL}
                                    value={PLANS.PROFESSIONAL}
                                    checked={selectedPlan === PLANS.PROFESSIONAL}
                                    onChange={handleTypeChange}
                                    className="mr-2"
                                />
                                <label htmlFor="monthly">Professional</label>

                            </div>
                            <div className="text-bubbleGray font-grotesk-normal text-sm px-5">
                                US${prices[BILLING_INTERVALS.MONTHLY][PLANS.PROFESSIONAL]}/month
                            </div>
                        </div>


                    </div>
                </div>
                <label className="block text-gray-700 font-grotesk-bold text-base font-bold mb-2">
                    Billing Period
                </label>
                <div className="mb-10">

                    <div className="flex flex-col">

                        <div className="mb-3">
                            <div className="flex items-center">
                                <input
                                    type="radio"
                                    id="yearly"
                                    value="yearly"
                                    checked={selectedBillingInterval === 'yearly'}
                                    onChange={handlePlanChange}
                                    className="mr-2"
                                />
                                <label htmlFor="yearly">Yearly</label>
                                <div className="uppercase text-center bg-accent px-3 mx-3
            rounded-full text-white font-grotesk-bold text-xs">Best Value
                                </div>
                            </div>
                            <div className="text-bubbleGray font-grotesk-normal text-sm px-5">
                                US${prices['yearly'][selectedPlan]}
                            </div>
                        </div>

                        <div className="mb-3">
                            <div className="flex items-center">

                                <input
                                    type="radio"
                                    id="monthly"
                                    value="monthly"
                                    checked={selectedBillingInterval === 'monthly'}
                                    onChange={handlePlanChange}
                                    className="mr-2"
                                />
                                <label htmlFor="monthly">Monthly</label>

                            </div>
                            <div className="text-bubbleGray font-grotesk-normal text-sm px-5">
                                US${prices['monthly'][selectedPlan]}
                            </div>
                        </div>

                    </div>

                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 font-grotesk-bold text-base font-bold mb-2">
                        Order Summary
                    </label>
                    <div className="text-bubbleGray font-grotesk-normal text-sm px-4 py-2">
                    <span>
                        ${finalPrice}.00 x 1 member x 1 {selectedBillingInterval === BILLING_INTERVALS.YEARLY ? "year" : "month"}
                    </span>
                        <span className="float-right">
                        ${finalPrice}.00
                    </span>
                    </div>
                    <div className="border bottom-2 border-grays-lineGray"/>
                    <div className="px-4">
                    <span className="text-gray-700 font-bold">
                        Total
                    </span>
                        <span className="text-gray-700 font-bold float-right">
                            ${finalPrice}.00
                        </span>
                    </div>

                </div>
            </div>
            <div className="flex flex-col items-center  justify-center ">
                <CheckoutButton
                    appFirebase={appFirebase}
                    canUpgrade={true}
                    plan={selectedPlan}
                    billingInterval={selectedBillingInterval}
                />
                <div className="text-bubbleGray font-grotesk-normal text-sm max-w-md mx-auto px-5">
                    You'll be charged ${finalPrice} {selectedBillingInterval} unitl you cancel the subscription. By
                    continuing, you
                    agree to Jinolo's <a style={{textDecoration: "underline"}}
                                         href={"https://jinolo.com/terms"} target="_blank">Terms
                    of Use</a>. Read our <a style={{textDecoration: "underline"}}
                                            href={"https://jinolo.com/privacy"} target="_blank">Privacy
                    Policy</a>.
                </div>
            </div>

        </div>

    );
}

export default Checkout;