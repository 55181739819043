import React, {useState} from 'react';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import './viewerStyle.scss';
import {createTheme, IconButton, ThemeProvider} from "@mui/material";
import {ArrowBack, ArrowForward} from "@mui/icons-material";
import {grey} from "@mui/material/colors";
import styled from "styled-components";
import theme from "../../../../style/theme";
import {ZoomIn, ZoomOut} from "react-feather";

const viewerMaterialTheme = createTheme({
    palette: {
        primary: {
            main: grey[700],
        },
        secondary: {
            main: grey[100],
        },
    },
});
export const NavigationText = styled.div`
  font-family: ${theme.font.medium};
  ${theme.font_size.small};
  color: ${theme.color.black.light};
  text-decoration: none;
`
// const PdfRender = React.memo(({ pdfData }: any) => {
//     return (
//         pdfData?.pdfUrl && (
//             <Document file={{url: pdfData?.pdfUrl}} onLoadSuccess={pdfData?.onDocumentLoadSuccess}>
//                 <Page pageNumber={pdfData?.pageNumber} scale={pdfData?.scale}/>
//             </Document>
//         )
//     );
// });
function PdfView(props) {
    const {downloadUrl} = props;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    // const [pdfData, setPdfData] = useState({
    //     pdfUrl: "",
    // });
    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }
    // useEffect(() => {
    //     return () => {
    //         setPdfData({
    //             pdfUrl: downloadUrl,
    //             onDocumentLoadSuccess,
    //             pageNumber,
    //             scale,
    //
    //         })
    //     };
    // }, []);




    return (
        <div>
            <ThemeProvider theme={viewerMaterialTheme}>
                <div className="navigationContainer">
                    <IconButton
                        color="primary"
                        // disableRipple={true}
                        onClick={() => {
                            if (pageNumber > 1) {
                                setPageNumber(pageNumber - 1)
                            }
                        }}
                    >
                        <ArrowBack/>
                    </IconButton>
                    <NavigationText>
                        {pageNumber} / {numPages}
                    </NavigationText>
                    <IconButton
                        color="primary"
                        // disableRipple={true}
                        onClick={() => {
                            if (pageNumber < numPages) {
                                setPageNumber(pageNumber + 1)
                            }
                        }}
                    >
                        <ArrowForward/>
                    </IconButton>
                    <IconButton
                        color="primary"
                        // disableRipple={true}
                        onClick={() => {
                            if (scale <= 1.5) {
                                setScale(scale + 0.1)
                            }
                        }}
                    >
                        <ZoomIn/>
                    </IconButton>
                    <IconButton
                        color="primary"
                        // disableRipple={true}
                        onClick={() => {
                            if (scale >= 0.5) {
                                setScale(scale - 0.1)
                            }
                        }}
                    >
                        <ZoomOut/>
                    </IconButton>
                </div>
            </ThemeProvider>
            <div className="pdfContainer">

                <Document file={{url: downloadUrl}} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} scale={scale}/>
                </Document>
                {/*<PdfRender pdfData={pdfData}/>*/}
            </div>


            {/*</canvas>*/}
        </div>
    );
}

export default PdfView;