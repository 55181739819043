import React, {useContext, useEffect, useState} from 'react';
import {ButtonFilled} from "./style";
import LogInModal from "./LogInModal";
import {Navigate} from "react-router-dom";
import SignInUpModal from "./SignInUpModal";
import {SignUpModalContext} from "../../contexts/signUpModalContext";
import {FirebaseContext} from "../../contexts/FirebaseContext";

function LogInButton({signedIn, setSignInStatus, isDocPage, uiConfig}) {
    const appFirebase = useContext(FirebaseContext);
    const [displayString, setDisplayString] = useState("Log In");
    const [logOutClicked, setLogOutClicked] = useState(false);
    const {state, dispatch} = useContext(SignUpModalContext);

    useEffect(() => {
        if (signedIn) {
            setDisplayString("Log Out");
        } else {
            setDisplayString("Log In");
        }
    }, [signedIn]);

    // Using useEffect to perform an action based on shouldBeOpen
    useEffect(() => {
        if (!isDocPage) {
            if (!signedIn) {
                dispatch({type: 'OPEN_MODAL'});
            } else {
                dispatch({type: 'CLOSE_MODAL'});
            }
            dispatch({type: 'OPEN_MODAL'});
        } else {
            dispatch({type: 'CLOSE_MODAL'});
        }
    }, [signedIn]); // Rerun when shouldBeOpen changes


    const onClickLogIn = () => {
        if (!signedIn) {
            dispatch({type: 'OPEN_MODAL'});
        }

    }
    return (
        <div>
            {logOutClicked && <Navigate to='/' replace={true}/>}
            <ButtonFilled onClick={() => onClickLogIn()}>{displayString}</ButtonFilled>
            <SignInUpModal
                setSignInStatus={() => setSignInStatus()}
                uiConfig={uiConfig}
            />

        </div>
    );
}

export default LogInButton;