import React, {useEffect, useState} from 'react';
import {httpsCallable} from 'firebase/functions';
import {onValue, ref} from "firebase/database";
import {databaseTypesConstants} from "../../../firebase/databaseConstants";
import CircularProgress from '@mui/material/CircularProgress';

function CheckoutButton(props) {
    const {appFirebase, canUpgrade, plan, billingInterval} = props;
    const upgradeCheckoutFunction = httpsCallable(appFirebase.function, 'payment-createStripeCheckout');

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const functionData = {
        plan: plan,
        billingPeriod: billingInterval
    }

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);

    const handleClickUpgrade = async () => {
        setLoading(true);
        window.analytics.track('Upgrade Click', {
            "Author": appFirebase.auth.currentUser.uid,
        });
        onValue(ref(appFirebase.database, `${databaseTypesConstants.SUBSCRIPTION.name}/${appFirebase.auth.currentUser.uid}`), async (snapshot) => {
            const user = snapshot.val();
            if (user[databaseTypesConstants.SUBSCRIPTION.options.PLAN] <= 0) {
                // const stripe = await getStripe();
                upgradeCheckoutFunction(functionData)
                    .then(async response => {
                        console.log(response)
                        window.location.replace(response.data.url)
                        setLoading(false);
                        // const sessionId = response.data.id;
                        // console.log("called upgradeCheckoutFunction");
                        // const {error} = await stripe.redirectToCheckout({sessionId: sessionId}).then(r => {
                        // });
                        // window.location.href = response.data.url;
                    })
                    .catch(error => {
                        const code = error.code;
                        const message = error.message;
                        const details = error.details;
                        console.log(code, message, details);
                        setLoading(false);
                    });
            }
        }, {
            onlyOnce: true,
        });

    }
    return (
        // <div>
        //     {canUpgrade && <ButtonOutline onClick={handleClickUpgrade}>Upgrade</ButtonOutline>}
        // </div>
        <div>
            {canUpgrade && <div
                className={`uppercase ${ !loading && "cursor-pointer"} items-center justify-center w-52 py-2 px-10 text-center bg-primary
                rounded-full text-white font-grotesk-medium mx-3 mb-5`}
                onClick={!loading ? handleClickUpgrade : undefined}
            >
                <div className="text-white text-center">
                {loading ? <CircularProgress color="inherit" size="20px" /> : 'Checkout'}
                </div>
            </div>
            }
            {message && <div>{message}</div>}
        </div>
    );
}

export default CheckoutButton;