import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    ThemeProvider,
    Grid,
    Modal,
    TextField,
    Typography,
    createTheme,
    Alert,
    Switch,
    LinearProgress, FormControl, InputLabel, Select, Tooltip
} from "@mui/material";
import 'firebase/compat/auth';
import '../../navbar/firebaseui-styling.global.css';
import {grey} from "@mui/material/colors";
import {httpsCallable} from "firebase/functions";
import TogglePill from "./TogglePill";
import CloseIcon from "@mui/icons-material/Close";
import {Lock, People} from "@mui/icons-material";
import EmailShare from "../ShareModal/EmailShare"
import LinkShare from "../ShareModal/LinkShare"
import GeneralAccess from "./GeneralAccess";
import PermissionSettings from "./PermissionSettings";
import PeopleAccess from "./PeopleAccess";
import {getUserPlan} from "../../../firebase/commonFunctions";

const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    pb: 2,
    pl: 3,
    pr: 3,
    borderRadius: 3,
    outline: 0,
    zIndex: 900000,
};

const modalMaterialTheme = createTheme({
    palette: {
        primary: {
            main: "#121640",
        },
        secondary: {
            main: grey[100],
        },
    },
});

function ShareModal(props) {
    const {
        handleClose,
        open,
        appFirebase,
        docId,
        docName
    } = props;
    const [emailText, setEmailText] = useState("");
    const [email, setEmail] = useState("");
    const [shareSuccess, setShareSuccess] = useState(false);
    const [shareFailure, setShareFailure] = useState(false);
    const [allowDownload, setAllowDownload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPeopleAccessScreen, setShowPeopleAccessScreen] = useState(false);
    const [userPlan, setUserPlan] = useState(null);

    const [activeTogglePillOption, setActiveTogglePillOption] = useState(1);
    const shareDocument = httpsCallable(appFirebase.function, "userManagement-shareWithUser");
    const shareEmailRef = useRef(null);
    const shareMessageRef = useRef(null);


    const submitShareRequest = async () => {
        setLoading(true);
        const permissionsObject = {
            download: allowDownload
        }
        // console.log(shareMessageRef.current?.value);
        // console.log(shareEmailRef.current?.value);
        const data = {
            docId: docId,
            permission: permissionsObject,
            message: shareMessageRef.current?.value,
            email: shareEmailRef.current?.value,
        }
        // console.log(data);
        shareDocument(data).then(() => {
            setLoading(false);
            setShareSuccess(true);
            setEmail(emailText);
            setEmailText("");
            window.analytics.track('Share Document', {
                "Author": appFirebase.auth.currentUser.uid,
                "Recipient": emailText,
            });
        }).catch(() => {
            console.log("Sorry there was an error");
            setLoading(false);
            setShareFailure(true);
            setEmail(emailText);
            setEmailText("");
        })

    }
    useEffect(async () => {
        const plan = await getUserPlan(appFirebase);
        setUserPlan(plan);
    }, []);


    const closingModal = () => {
        handleClose();
        setEmail("");
        setEmailText("");
        setShareSuccess(false);
        setShareFailure(false);
    }
    const onClickX = () => {
        handleClose();
    }
    const onClickPeople = () => {
        setShowPeopleAccessScreen(true);
    }
    return (
        <div>
            <Modal
                open={open}
                onClose={closingModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={modalMaterialTheme}>
                    {loading && <LinearProgress/>}
                    <Box sx={style}>


                        {showPeopleAccessScreen ?
                            <div className="relative">
                                <div className="absolute right-0 top-0">
                                    <CloseIcon className="cursor-pointer" onClick={() => onClickX()}
                                               sx={{color: "#898989", marginRight: 1, marginTop: 1}}/>
                                </div>
                                <PeopleAccess
                                    docName={docName}
                                    docId={docId}
                                    setShowPeopleAccessScreen={setShowPeopleAccessScreen}
                                    appFirebase={appFirebase}
                                />
                            </div>
                            :
                            <div>
                                <div className="relative">
                                    <div className="absolute right-0 top-0">
                                        <Tooltip title="Manage access">
                                        <People className="cursor-pointer" onClick={() => onClickPeople()}
                                                sx={{color: "#898989", marginRight: 1, marginTop: 1}}/>
                                        </Tooltip>
                                        <CloseIcon className="cursor-pointer" onClick={() => onClickX()}
                                                   sx={{color: "#898989", marginRight: 1, marginTop: 1}}/>
                                    </div>
                                    {shareSuccess &&
                                        <Alert className="mb-3" severity="success">Successfully shared
                                            with {shareEmailRef.current?.value}</Alert>}
                                    {shareFailure &&
                                        <Alert severity="error">An error has occurred. Please try again. </Alert>}
                                    <div className="flex justify-center pb-2">
                                        <TogglePill
                                            activeOption={activeTogglePillOption}
                                            setActiveOption={setActiveTogglePillOption}
                                            option1={"Link"}
                                            option2={"Email"}
                                        />
                                    </div>
                                </div>
                                <GeneralAccess appFirebase={appFirebase} docId={docId}/>
                                {activeTogglePillOption === 1 ?
                                    <div>
                                        <LinkShare docName={docName} docId={docId}/>
                                    </div> :
                                    <div>
                                        {/*<div className="font-grotesk-bold text-md text-grays-headingTextGray">Access Controls</div>*/}

                                        <PermissionSettings
                                            allowDownload={allowDownload}
                                            setAllowDownload={setAllowDownload}
                                        />
                                        <EmailShare
                                            shareEmailRef={shareEmailRef}
                                            shareMessageRef={shareMessageRef}
                                            submitShareRequest={submitShareRequest}
                                            docName={docName}
                                            isPremium={userPlan > 0}
                                        />

                                    </div>
                                }
                            </div>
                        }
                    </Box>
                </ThemeProvider>
            </Modal>
        </div>
    );
}

export default ShareModal;