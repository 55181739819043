import React, {useEffect, useState} from 'react';
import {FormControl, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {Lock, People, Public} from "@mui/icons-material";
import {onValue, ref} from "firebase/database";
import {databaseTypesConstants as dbConst} from "../../../firebase/databaseConstants";
import {CircularProgress, createTheme, ThemeProvider} from "@mui/material";
import {modalMaterialTheme} from "../muiThemes";
import {httpsCallable} from "firebase/functions";

function GeneralAccess({appFirebase, docId}) {
    const [access, setAccess] = useState(null);
    const [loading, setLoading] = useState(true);
    const makeDocumentPublic = httpsCallable(appFirebase.function, "document-makeDocumentPublic");
    const makeDocumentPrivate = httpsCallable(appFirebase.function, "document-makeDocumentPrivate");
    const changeDocPermissions = httpsCallable(appFirebase.function, "document-changePublicPermissions");

    useEffect(() => {
        setLoading(true)
        setAccess(0);
        if (appFirebase.auth.currentUser) {
            return onValue(ref(appFirebase.database, `/${dbConst.DOCUMENT_ACCESS.name}/${docId}/public`), (snapshot) => {
                if (snapshot.val()) {
                    setAccess(2)
                } else {
                    setAccess(1)
                }
                setLoading(false)
            });
        } else {
            setAccess(0);
            setLoading(false);
        }
    }, []);

    const handleAccessClick = async (operation) => {
        const permissionsObject = {
            download: false,
            comment: true,
        };
        const data = {
            docId: docId,
            permission: permissionsObject,
        }
        /* Setting to Private */
        if (operation === 1 && access === 2) {
            setLoading(true);
            setAccess(0);
            makeDocumentPrivate(data).then((response) => {
                if (response.data.success) {
                    setLoading(false);
                    setAccess(1)
                    window.analytics.track('Change Document Access', {
                        "Access": "private",
                        docId: docId,
                    });
                }

            }).catch(() => {
                console.log("Sorry there was an error");
                setLoading(false);
            })
        }
        /* Setting to Public */
        else if (operation === 2 && access === 1) {
            setLoading(true);
            setAccess(0);
            makeDocumentPublic(data).then((response) => {
                if (response.data.success) {
                    setLoading(false);
                    setAccess(2);
                    window.analytics.track('Change Document Access', {
                        "Access": "public",
                        docId: docId,
                    });
                }
            }).catch((error) => {
                console.log(`Sorry there was an error ${error}`);
                setLoading(false);
            })
        }
    }
    return (
        <div>
            <div className="font-grotesk-bold text-md text-grays-headingTextGray">General access</div>
            <div className="font-grotesk-normal text-sm text-grays-headingTextGray pb-3">Select who can view
                your file
            </div>
            {/*<div>*/}
            {/*    <Settings className="cursor-pointer" onClick={() => console.log("clicked")}*/}
            {/*            sx={{color: "#898989", marginRight: 1, marginTop: 1}}/>*/}
            {/*</div>*/}
            <FormControl fullWidth style={{zIndex: '9000000000000 !important'}}>
                <Select style={{zIndex: '90000000000011 !important'}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={access}
                        onChange={handleAccessClick}
                >
                    {access === 0 || loading === true ?

                        <MenuItem value={0}>
                            <div className="flex justify-center items-center">
                                <ThemeProvider theme={modalMaterialTheme}>
                                    <CircularProgress className="align-self-center"/>
                                </ThemeProvider>
                            </div>
                        </MenuItem>
                        :
                        <MenuItem value={0} style={{display: access !== 0 ? 'none' : undefined}}>
                            <div className="flex justify-center items-center">
                                <ThemeProvider theme={modalMaterialTheme}>
                                    <CircularProgress className="align-self-center"/>
                                </ThemeProvider>
                            </div>
                        </MenuItem>

                    }
                    {access !== 0 &&
                        [
                            <MenuItem value={1} onClick={() => handleAccessClick(1)}>
                                <div className="flex items-center">
                                    <div className="mr-4">
                                        <Lock/>
                                    </div>
                                    <div>
                                        <div
                                            className="font-grotesk-bold text-md text-grays-headingTextGray">Private
                                        </div>
                                        <div className="font-grotesk-normal text-sm text-grays-headingTextGray">Only
                                            people with access can view
                                        </div>
                                    </div>
                                </div>
                            </MenuItem>,
                            <MenuItem value={2} onClick={() => handleAccessClick(2)}>
                                <div className="flex items-center">
                                    <div className="mr-4">
                                        <Public/>
                                    </div>
                                    <div>
                                        <div
                                            className="font-grotesk-bold text-md text-grays-headingTextGray">Public
                                        </div>
                                        <div
                                            className="font-grotesk-normal text-sm text-grays-headingTextGray">Anyone
                                            with the link can view
                                        </div>
                                    </div>
                                </div>
                            </MenuItem>
                        ]
                    }

                </Select>
            </FormControl>
        </div>
    )
        ;
}

export default GeneralAccess;