import React, {useState} from 'react';
import {ContentCopy} from "@mui/icons-material";

function LinkShare({docName, docId}) {
    const [copied, setCopied] = useState(false);

    const handleCopy = async () => {
        try {
            const link = `https://share.jinolo.com/files/${docId}`;
            await navigator.clipboard.writeText(link);
            setCopied(true);
            setTimeout(() => setCopied(false), 3000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };
    return (
        <div className="flex items-center justify-between  mt-7">
            <div className="mr-4">
                <div className="mt-2 font-grotesk-bold text-xl text-grays-headingTextGray">Share a link</div>
                {/*<div className="font-grotesk-normal text-sm text-grays-headingTextGray pb-3">Send a link to*/}
                {/*    collaborators to review {docName}</div>*/}
                <div className="font-grotesk-normal text-sm text-grays-headingTextGray pb-3">
                    Only granted users can view the link.
                </div>
            </div>
            <button
                className="flex bg-primary text-white font-grotesk-bold px-3 py-1 rounded-full border border-2 border-primary hover:bg-white hover:text-primary"
                onClick={handleCopy}
            >
                <ContentCopy/>
                <div className="pl-2">
                    {copied ? "Copied!" : "Copy link"}
                </div>
            </button>
        </div>
    );
}

export default LinkShare;
