import {ref, onValue, onChildRemoved, off} from 'firebase/database';
import {databaseTypesConstants as dbConst} from "../../../../../firebase/databaseConstants";
import {AnnotationsPlugin} from "@xeokit/xeokit-sdk";
import {SidePanelModes} from "../../../../../contexts/SidePanelRightContext";
import {COMMENT_TOOL} from "../../tools/toolConstants";

function nameToInitials(name) {
    let initials = ''
    const nameArray = name.split(' ')
    if (nameArray.length === 1) {
        initials = `${name.split(' ')[0][0]}`
    } else if (nameArray.length === 2 || nameArray.length > 2) {
        initials = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    } else {
        initials = `OO`
    }
    return initials;
}


export function setupAnnotationListeners(appFirebase, docId, handleNewComment, handleRemovedComment, annotations, setAnnotations) {
    const documentCommentsUrl = `/${dbConst.COMMENTS.name}/${docId}`;
    const commentsRef = ref(appFirebase.database, documentCommentsUrl);

    // Handle new or changed comments
    const valueHandler = onValue(commentsRef, (snapshot) => {
        handleNewComment(snapshot, annotations, setAnnotations, appFirebase);
    });

    // Handle removed comments
    const childRemovedHandler = onChildRemoved(commentsRef, (snapshot) => {
        handleRemovedComment(snapshot.key, annotations, setAnnotations);  // passing the deleted comment's key
    });

    return () => {   // Return the cleanup function
        off(commentsRef, 'value', valueHandler);
        off(commentsRef, 'child_removed', childRemovedHandler);
    };
}

export function createAnnotationObjectOnLoading(annotations, setAnnotations, viewerInstance, setViewer) {
    return new Promise((resolve, reject) => {
        const newAnnotations = new AnnotationsPlugin(viewerInstance, {
            markerHTML: "<div class='annotation-marker' style='background-color: {{markerBGColor}};'>{{glyph}}</div>",
            labelHTML: "<div class='annotation-label' style='background-color: {{labelBGColor}};'>\
            <div class='font-grotesk-bold font-xl mx-2 mt-2 mb-0 w-fit'>{{title}}</div>\
            <div class='font-grotesk-light mx-2 mt-0 text-gray-100 text-xs'>{{date}}</div>\
            <div class='font-grotesk-normal m-2 w-fit'>{{description}}</div>\
            </div>",
            values: {
                markerBGColor: "#e43397",
                labelBGColor: "white",
                glyph: "X",
                title: "Untitled",
                description: "No description"
            }
        });
        setAnnotations(newAnnotations);
        setViewer(viewerInstance);
        resolve(newAnnotations);
    });
}


export function createAnnotationOnClick(
    coords,
    viewerInstance,
    author,
    setCurrentCanvasPos,
    setCurrentWorldPos,
    onClickAnnotationsRef,
    annotationCounterRef,
    startCommentRef,
    setStartCommentState,
    startCommentState
) {

    var pickRecord = viewerInstance.scene.pick({
        canvasPos: coords,
        pickSurface: true  // This causes picking to find the intersection point on the entity
    });
    if (!onClickAnnotationsRef.current) {
        onClickAnnotationsRef.current = new AnnotationsPlugin(viewerInstance, {
            markerHTML: "<div class='annotation-marker' style='background-color: {{markerBGColor}};'>{{glyph}}</div>",
            labelHTML: "",
            values: {
                markerBGColor: "#e43397",
                // markerBGColor: "#50ff00",
                labelBGColor: "white",
                glyph: "X",
                title: "Untitled",
                description: "No description"
            }
        });
    }
    if (pickRecord) {
        const newAnnotationId = "annotationOnClick" + annotationCounterRef.current;
        const newAnnotation = onClickAnnotationsRef.current.createAnnotation({
            id: newAnnotationId,
            worldPos: pickRecord['worldPos'],
            occludable: true,
            markerShown: true,
            labelShown: false,
            values: {
                glyph: nameToInitials(author),
            },
        });
        setCurrentCanvasPos([newAnnotation.canvasPos[0], newAnnotation.canvasPos[1]]);
        setCurrentWorldPos(pickRecord['worldPos']);
    }
}

export function destroyUnusedAnnotation(
    onClickAnnotationsRef,
    annotationCounterRef,
) {
    onClickAnnotationsRef.current.destroyAnnotation(`annotationOnClick${annotationCounterRef.current}`);
    annotationCounterRef.current = annotationCounterRef.current + 1;
}

export const fetchAndCreateAnnotations = (appFirebase, docId, annotations) => {

    if (appFirebase.auth.currentUser) {
        const documentCommentsUrl = `/${dbConst.COMMENTS.name}/${docId}`;
        const commentsRef = ref(appFirebase.database, documentCommentsUrl);
        onValue(commentsRef, (snapshot) => {
            const commentData = snapshot.val();
            for (let commentId in commentData) {
                const comment = commentData[commentId];
                onValue(ref(appFirebase.database, `/${dbConst.USERS.name}/${comment['createdBy']}`), (authorSnapshot) => {
                    const author = authorSnapshot.val().firstname +
                        (authorSnapshot.val().lastname ? (` ${authorSnapshot.val().lastname}`) : "");
                    const formattedDate = new Date(comment['dateCreated']).toDateString();
                    if (comment['point'] === true && !annotations.annotations.hasOwnProperty(commentId)) {
                        annotations.createAnnotation({
                            id: commentId,
                            worldPos: comment['worldPos'],
                            occludable: true,
                            markerShown: true,
                            labelShown: false,
                            values: {
                                glyph: nameToInitials(author),
                                title: author,
                                description: comment['text'],
                                date: formattedDate,
                            }
                        });
                    }
                });
            }
        });
    }
}

export function handleNewComment(comment, annotations, setAnnotations, appFirebase) {
    // Your logic for handling new/updated comments
    const newCommentId = comment.key;
    const worldPos = comment[`${dbConst.COMMENTS.options.comment3d.WORLD_POS}`]
    const authorId = comment[`${dbConst.COMMENTS.options.comment3d.CREATED_BY}`]
    if (annotations) {
        if (!annotations.annotations.hasOwnProperty(newCommentId)) {
            onValue(ref(appFirebase.database, `/${dbConst.USERS.name}/${authorId}`), (authorSnapshot) => {
                if (authorSnapshot.val()) {
                    const author = authorSnapshot.val().firstname +
                        (authorSnapshot.val().lastname ? (` ${authorSnapshot.val().lastname}`) : "");
                    const newAnnotations = annotations;
                    const newAnnotation = newAnnotations.current.createAnnotation({
                        id: comment.key,
                        worldPos: worldPos,
                        occludable: true,
                        markerShown: true,
                        labelShown: false,
                        values: {
                            glyph: nameToInitials(author),
                        },
                    });
                    setAnnotations(newAnnotations);
                }
            });
        }
    }
}

export function handleRemovedComment(deletedCommentId, annotations, setAnnotations) {
    // If annotation exists in annotations, delete the annotation with id
    if (annotations) {
        if (annotations.annotations.hasOwnProperty(deletedCommentId)) {
            const newAnnotations = annotations;
            newAnnotations.destroyAnnotation(deletedCommentId);
            setAnnotations(newAnnotations);
        }
    }
}