import React, {useState} from 'react';
import {ButtonOutline} from "./style";
import {Link} from "react-router-dom";

let stripePromise;

function UpgradeButton(props) {
    const {canUpgrade} = props;


    return (
        <div>
            <Link to={`/checkout`} style={{textDecoration: 'none'}}>
                {canUpgrade && <ButtonOutline>Upgrade</ButtonOutline>}
            </Link>
        </div>
    );
}

export default UpgradeButton;