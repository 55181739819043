export const databaseTypesConstants = {
    PERMISSIONS: {
        name: "permissions",
        options: {
            OWNER: "owner",
        }
    }, UID: {
        name: "uids",
    }, USERS: {
        name: "users", options: {
            FIRSTNAME: "firstname",
            LASTNAME: "lastname",
            EMAIL: "email",
            INDUSTRY: "industry",
            PHONE: "phone",
            ROLE: "role",
        },
    }, DOCUMENTS: {
        name: "documents",
        options: {
            CREATED_BY: "createdBy",
            NAME: "name",
            URL: "url",
            REV_NUM: "revNum",
            DATE: "dateCreated",
            LAST_EDIT: "lastEdited",
            EXTENSION: "fileExtension",
            FOLDER_LOCATION: "folderLocation",
            PROCESSING_COMPLETED: "processingCompleted",
            DEMO_FILE: "demoFile",
        },
    }, TEMP_USERS: {
        name: "tempUsers", options: {
            ALL_DOCS: "allDocs",
        },
    }, SUBSCRIPTION: {
        name: "subscription", options: {
            // Data options available in the subscription database
            PLAN: "subscriptionPlan",
            TRIAL: "trial",
            TRIAL_DAYS: "daysTrialRemaining",
            STORAGE_TOTAL: "storageAvailable",
            TRIAL_START: "trialStart",
            STORAGE_USED: "storageUsed",
            STRIPE_SUBSCRIPTION_ID: "stripeSubscriptionId",
            STRIPE_CUSTOMER_ID: "stripeCustomerId",
        },
    },
    // List of documents a user has access to
    USER_DOCUMENTS: {
        name: "userDocuments",
    }, // List of users that has access to a document
    DOCUMENT_ACCESS: {
        name: "documentAccess", options: {
            TEMP_USERS: "tempUsers",
        },
    },
    // Flags for demo and onboarding
    USER_MARKETING: {
        name: "userMarketingFlags",
        options: {
            ONBOARD_TOUR_COMPLETED: "onboardTourCompleted",
            ONBOARD_TOUR_SKIPPED: "onboardTourSkipped",
            ONBOARD_TOUR_STEP: "onboardTourStep",
            ONBOARD_UPGRADE_CLICK: "onboardUpgradeClick",
            DEMO_FILE_DOC_ID: "demoFileDocId",
            COMMENT_FIRST_OPEN: "commentFirstOpen",
        }
    },
    COMMENTS: {
        name: "comments",
        options: {
            comment3d: {
                TEXT: "text",
                CREATED_BY: "createdBy",
                DATE_CREATED: "dateCreated",
                WORLD_POS: "worldPos",
                CANVAS_POS: "canvasPos",
                POINT:"point",
            },
            commentText: {
                TEXT: "text",
                CREATED_BY: "createdBy",
                DATE_CREATED: "dateCreated",
            }
        }
    },
    FOLDERS: {
        name: "folders",
        options: {
            CREATED_BY: "createdBy",
            NAME: "name",
            PARENT: "parent",
            DATE: "dateCreated",
            DOCUMENTS: "documents",
            FOLDERS: "folders",
        }
    },
    ROOT_DIRECTORY: {
        name: "rootDirectory",
        options: {
            DOCUMENTS: "documents",
            FOLDERS: "folders",
        }
    }

};
export const subscriptionPlanStrings = {
    [-1]: "Free",
    0: "Free",
    1: "Starter",
    2: "Professional"
}