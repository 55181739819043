import React, {useEffect, useState, createContext, useCallback, useContext} from 'react';
import {onValue, ref} from "firebase/database";
import {CircularProgress, createTheme, ThemeProvider} from "@mui/material";
import {grey} from "@mui/material/colors";
import {databaseTypesConstants as dbConstants} from "../../../firebase/databaseConstants";
import DocumentList from "./DocumentList";
import {FirebaseContext} from "../../../contexts/FirebaseContext";

function DocumentDisplay(props) {
    const {
        documentIds,
        folderIds,
        title,
        rootFolderId,
        isRoot,
        setDocMoveCompleted
    } = props;
    const appFirebase = useContext(FirebaseContext);
    const [documentDetailsList, setDocumentDetailsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    function handleDetailChange(index, newDocEntry) {
        setDocumentDetailsList((prevDocList) => {
            const newDocDetailList = [...prevDocList];
            newDocDetailList[index] = newDocEntry;
            return newDocDetailList;
        });
    }

    // memoize the function using useCallback
    // const handleDetailChange = useCallback((index, newDocEntry) => {
    //     setDocumentDetailsList((prevDocList) => {
    //         const newDocDetailList = [...prevDocList];
    //         newDocDetailList[index] = newDocEntry;
    //         return newDocDetailList;
    //     });
    // }, [setDocumentDetailsList]);

    async function fetchAuthorName(userRef) {
        return new Promise((resolve, reject) => {
            onValue(userRef, (authorSnapshot) => {
                const currentUser = authorSnapshot.val();
                const authorName = `${currentUser.firstname} ${currentUser.lastname}`;
                resolve(authorName);
            }, {
                onlyOnce: true,
            });
        });
    }

    // Creating list of documents on render
    useEffect(async () => {
        setIsLoading(true);
        let docDetailList = [];
        let folderPromises = folderIds.map((folderId) => {
            // console.log(folderId)
            const folderRef = ref(appFirebase.database, `/${dbConstants.FOLDERS.name}/${folderId}`);
            return new Promise((resolve) => {
                onValue(
                    folderRef,
                    async (snapshot) => {
                        const currentDocumentData = snapshot.val();
                        // console.log(currentDocumentData)
                        const userRef = ref(
                            appFirebase.database,
                            `/${dbConstants.USERS.name}/${currentDocumentData[dbConstants.FOLDERS.options.CREATED_BY]}`
                        );
                        let authorName = "";
                        if (
                            currentDocumentData[
                                dbConstants.FOLDERS.options.CREATED_BY
                                ] === appFirebase.auth.currentUser.uid
                        ) {
                            authorName = "you";
                        } else {
                            authorName = await fetchAuthorName(userRef);
                        }
                        resolve({
                            id: folderId,
                            fileName: currentDocumentData[
                                dbConstants.FOLDERS.options.NAME
                                ],
                            author: authorName,
                            type: 'folder',
                            createdDate: new Date(
                                currentDocumentData[
                                    dbConstants.FOLDERS.options.DATE
                                    ]
                            ).toLocaleDateString(undefined, {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric'
                            }),
                        });
                    },
                    {
                        onlyOnce: true,
                    }
                );
            });
        });

        let promises = documentIds.map((docId) => {
            const documentsRef = ref(appFirebase.database, `/documents/${docId}`);
            return new Promise((resolve) => {
                onValue(
                    documentsRef,
                    async (snapshot) => {
                        const currentDocumentData = snapshot.val();
                        const userRef = ref(
                            appFirebase.database,
                            `/${dbConstants.USERS.name}/${currentDocumentData[dbConstants.DOCUMENTS.options.CREATED_BY]}`
                        );
                        let authorName = "";
                        if (
                            currentDocumentData[
                                dbConstants.DOCUMENTS.options.CREATED_BY
                                ] === appFirebase.auth.currentUser.uid
                        ) {
                            authorName = "you";
                        } else {
                            authorName = await fetchAuthorName(userRef);
                        }
                        resolve({
                            id: docId,
                            fileName: currentDocumentData[
                                dbConstants.DOCUMENTS.options.NAME
                                ],
                            author: authorName,
                            authorUid: currentDocumentData[dbConstants.DOCUMENTS.options.CREATED_BY],
                            type: 'doc',
                            createdDate: new Date(
                                currentDocumentData[
                                    dbConstants.DOCUMENTS.options.DATE
                                    ]
                            ).toLocaleDateString(undefined, {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric'
                            }),
                        });
                    },
                    {
                        onlyOnce: true,
                    }
                );
            });
        });

        async function fetchData(folderPromises, promises) {
            setIsLoading(true);

            try {
                const [folderResults, results] = await Promise.all([Promise.all(folderPromises), Promise.all(promises)]);
                setIsLoading(false);
                // console.log(folderResults, results);
                setDocumentDetailsList([...folderResults, ...results]);
            } catch (error) {
                console.error(error);
            }
        }

        await fetchData(folderPromises, promises);

        return () => {
            // documentsRef.off('value');
        };
    }, [documentIds, folderIds]);

    const modalMaterialTheme = createTheme({
        palette: {
            primary: {
                main: "#00c4cc",
            },
            secondary: {
                main: grey[100],
            },
        },
    });
    return (
        <div>
            {
                isLoading ?
                    <div className="flex justify-center pt-3">
                        <ThemeProvider theme={modalMaterialTheme}>
                            <CircularProgress className="align-self-center"/>
                        </ThemeProvider>
                    </div>
                    :
                    <DocumentList
                        files={documentDetailsList}
                        appFirebase={appFirebase}
                        handleDetailChange={handleDetailChange}
                        title={title}
                        rootFolderId={rootFolderId}
                        isRoot={isRoot}
                        setDocMoveCompleted={setDocMoveCompleted}
                    />
            }

        </div>
    );
}

export default DocumentDisplay;




