import React, {useContext} from 'react';
import {UploadContext} from "../../../contexts/UploadContext";
import PopUpModal from "../../common/PopUpModal";
import {useNavigate} from "react-router-dom";

function UploadErrorHandler({signedIn}) {
    const { unableToUploadFreeFileLimitReached, setUnableToUploadFreeFileLimitReached } = useContext(UploadContext);
    const { unableToUploadNoStorage, setUnableToUploadNoStorage } = useContext(UploadContext);
    const { unableToUploadLargeFile, setUnableToUploadLargeFile} = useContext(UploadContext);
    const navigate = useNavigate();
    return (
        <div>
            {signedIn && <PopUpModal
                open={unableToUploadFreeFileLimitReached}
                handleClose={() => {
                    setUnableToUploadFreeFileLimitReached(false)
                }}
                text={{
                    heading: "Upgrade Required",
                    body: ["You've reached the 3 file limit of your current plan. To upload more, please delete existing files or upgrade your plan.",
                        "Upgrading unlocks additional storag and offers additional premium benefits, including: Upgrade to unlock additional storage capacity and embrace the full potential of our premium plans, including:",
                        "• Increased storage capacity",
                        "• Secure, private file sharing",
                        "• Wider range of functionalities including unlimited folders",
                        "Upgrade today and take your productivity to the next level!"
                    ]
                }}
                buttonOnClick={() => {
                    navigate('/checkout');
                }}
                buttonText={"Upgrade"}>

            </PopUpModal>}
            {signedIn && <PopUpModal
                open={unableToUploadNoStorage}
                handleClose={() => {
                    setUnableToUploadNoStorage(false)
                }}
                text={{
                    heading: "Upgrade Required",
                    body: ["You've reached the storage limit and need to upgrade your plan to continue uploading files.",
                        "Upgrade now to unlock additional storage capacity and access all the benefits of our premium plan, including:",
                        "• Increased storage capacity",
                        "• Unlimited folders and reviewers",
                        "• Private file sharing",
                        "Upgrade today and take your productivity to the next level!"
                    ]
                }}
                buttonOnClick={() => {
                    navigate('/checkout');
                }}
                buttonText={"Upgrade"}>

            </PopUpModal>}
            {signedIn && <PopUpModal
                open={unableToUploadLargeFile}
                handleClose={() => {
                    setUnableToUploadLargeFile(false)
                }}
                text={{
                    heading: "Upgrade Required",
                    body: ["The file you are trying to upload is too large for your current plan.",
                        "Upgrade now to upload larger file sizes and access all the benefits of our premium plan, including:",
                        "• Increased storage capacity",
                        "• Unlimited folders and reviewers",
                        "• Private file sharing",
                        "Upgrade today and take your productivity to the next level!"
                    ]
                }}
                buttonOnClick={() => {
                    navigate('/checkout');
                }}
                buttonText={"Upgrade"}>

            </PopUpModal>}
        </div>
    );
}

export default UploadErrorHandler;