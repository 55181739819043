import {onValue, ref} from "firebase/database";
import {fetchAndCreateAnnotations} from "./AnnotationHandling";

export const calculateNearFar = (aabb, viewerInstance) => {
    let xmin = aabb[0];
    let ymin = aabb[1];
    let zmin = aabb[2];
    let xmax = aabb[3];
    let ymax = aabb[4];
    let zmax = aabb[5];

    let centerX = (xmin + xmax) / 2;
    let centerY = (ymin + ymax) / 2;
    let centerZ = (zmin + zmax) / 2;

    let dx = viewerInstance.camera.eye[0] - centerX;
    let dy = viewerInstance.camera.eye[1] - centerY;
    let dz = viewerInstance.camera.eye[2] - centerZ;

    let distanceToCenter = Math.sqrt(dx * dx + dy * dy + dz * dz);
    let boundingBoxDiagonal = Math.sqrt((xmax - xmin) * (xmax - xmin) + (ymax - ymin) * (ymax - ymin) + (zmax - zmin) * (zmax - zmin));
    let radius = boundingBoxDiagonal / 2;

// Multiplying by arbitrary numbers to increase the near and far values
    let newFar = 3.8 * (distanceToCenter + 1.2*radius);
    let newNear = 3.8 * (distanceToCenter - 1.2*radius);

    return {
        newNear, newFar
    }

}

// Function to fit the model on the screen, or 'fly' to the model
export const zoomToFit = (model, viewerInstance, setViewer) => {
    viewerInstance.cameraFlight.flyTo(model);
    setViewer(viewerInstance);
}

export const handleModelLoaded = (model, viewerInstance, setViewer, setCenter, setHasParts) => {
    model.on("loaded", () => {
        const aabb = model.aabb; // [xmin, ymin, zmin, xmax, ymax, zmax]
        const center = [
            (aabb[0] + aabb[3]) / 2,
            (aabb[1] + aabb[4]) / 2,
            (aabb[2] + aabb[5]) / 2
        ];
        setCenter(center);
        // Leaving this code in case we want to change the position of the model later
        // model.position = [-this.state.center[0],-this.state.center[1],-this.state.center[2]];


        viewerInstance.camera.projection = "ortho";
        viewerInstance.camera.zoom =2;
        viewerInstance.cameraFlight.flyTo(model);

        const {newNear, newFar} = calculateNearFar(aabb, viewerInstance);

        // Setting the Near and Far values to avoid clipping
        if (newFar > viewerInstance.camera.ortho.far) {
            // console.log(`old far was ${viewerInstance.camera.ortho.far}, new is ${newFar}`)
            viewerInstance.camera.ortho.far = newFar;
        }

        if (newNear < viewerInstance.camera.ortho.near) {
            // console.log(`old near was ${viewerInstance.camera.ortho.near}, new is ${newNear}`)
            viewerInstance.camera.ortho.near = newNear;
        }

        // Setting whether the model has parts
        const metaModel = viewerInstance.metaScene.metaModels;
        const isMetaModelEmpty = !metaModel || Object.keys(metaModel).length === 0;
        setHasParts(!isMetaModelEmpty)

        setViewer(viewerInstance);

        // const metaModel = this.viewer.metaScene.metaModels;
        // const isMetaModelEmpty = !metaModel || Object.keys(metaModel).length === 0;
        // this.props.setHasParts(!isMetaModelEmpty)

    })
}

// export const handleModelLoaded = (model, viewerInstance, setViewer) => {
//     model.on("loaded", () => {
//         console.log(`loaded model ${model}`)
//
//     })
// }