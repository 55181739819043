import React, {Component, useContext, useEffect, useState} from "react";
import {ActionsContainer, Brand, Nav, StyledContainer} from "./style";
import LogInButton from "./LogInButton";
import ShareButton from "./ShareButton";
import UserAvatar from "./UserAvatar";
import {onValue, ref} from "firebase/database";
import {Link} from "react-router-dom";
import UpgradeButton from "./UpgradeButton";
import {databaseTypesConstants, subscriptionPlanStrings} from "../../firebase/databaseConstants";
import {FirebaseContext} from "../../contexts/FirebaseContext";


function TopNavbar({
                       signedIn,
                       setSignInStatus,
                       uiConfig,
                       displayAvatar,
                       isDocPage = false
                   }) {
    const [userEmail, setUserEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [subscriptionNumber, setSubscriptionNumber] = useState(null);
    const appFirebase = useContext(FirebaseContext);


    function handleSignOut() {
        if (signedIn) {
            appFirebase.auth.signOut()
                .then(() => {
                    setSignInStatus(false);
                })
                .catch(e => {
                    console.error('Sign Out Error', e);
                });
            setSignInStatus(false);

            // setDisplayString("log In");
            // setLogOutClicked(true);
        }
    }

    useEffect(() => {

        if (signedIn) {
            const uid = appFirebase.auth.currentUser.uid;
            const usersRef = ref(appFirebase.database, `/users/${uid}`);
            return onValue(usersRef, (snapshot) => {
                // if (!snapshot.val().firstname && !snapshot.val().lastname) {
                if (!Object.hasOwnProperty(snapshot.val()['firstname']) && !Object.hasOwnProperty(snapshot.val()['lastname'])) {
                    setFullName(appFirebase.auth.currentUser.displayName);
                } else if (snapshot.val()['firstname'] !== "" && snapshot.val()['lastname'] !== "") {
                    setFullName(snapshot.val()['firstname'] + " " + snapshot.val()['lastname']);
                } else {
                    setFullName(snapshot.val()['firstname'] + snapshot.val()['lastname']);
                }
                setUserEmail(snapshot.val().email);
            });
        } else {
            setFullName("");
            setUserEmail("");
        }
    }, [signedIn]);

    useEffect(() => {

        if (signedIn) {
            const uid = appFirebase.auth.currentUser.uid;
            const subscriptionRef = ref(appFirebase.database, `/${databaseTypesConstants.SUBSCRIPTION.name}/${uid}`);
            return onValue(subscriptionRef, (subscriptionSnapshot) => {
                setSubscriptionNumber(subscriptionSnapshot.val()[`${databaseTypesConstants.SUBSCRIPTION.options.PLAN}`]);
            });

        } else {
            setFullName("");
            setUserEmail("");
        }

    }, [signedIn]);
    return (
        <div>
            <Nav>
                <StyledContainer>
                    <Link to={`/`} style={{textDecoration: 'none'}}>
                        <div className="flex">
                            <Brand>
                                Jinolo
                            </Brand>
                            {(signedIn && subscriptionNumber!==null) &&
                                <span
                                    className={`px-3 mx-3 mt-1 rounded-full text-center py-1 ${subscriptionNumber > 0 ? 'bg-purple' : 'bg-bubbleGray'} text-sm font-semibold tracking-wide font-grotesk-normal text-white`}>
                            {subscriptionPlanStrings[subscriptionNumber]}
                        </span>
                            }
                        </div>
                    </Link>
                    <ActionsContainer>
                        {/*{displayShare && <ShareButton appFirebase={props.appFirebase} docId={docId}/>}*/}
                        {signedIn &&
                            <UpgradeButton appFirebase={appFirebase} canUpgrade={subscriptionNumber < 1}/>}
                        {(signedIn && displayAvatar) ?
                            <div style={{cursor: "pointer"}}>
                                <UserAvatar
                                    // fullName={(firstName && lastName) ? `${firstName} ${lastName}` : ""}
                                    fullName={fullName}
                                    email={userEmail}
                                    handleSignOut={handleSignOut}

                                />
                            </div>
                            :
                            <LogInButton
                                signedIn={signedIn}
                                setSignInStatus={() => setSignInStatus()}
                                isDocPage={isDocPage}
                                uiConfig={uiConfig}
                            />}
                    </ActionsContainer>
                </StyledContainer>
            </Nav>
        </div>
    );

}

export default TopNavbar;
