import React from 'react';
import {Box, createTheme, Modal, TextField, ThemeProvider} from "@mui/material";
import 'firebase/compat/auth';
import styled from "styled-components";
import theme from "../../../style/theme";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 4,
    borderRadius: 3,
    outline: 0,
};

const loginTheme = createTheme({
    palette: {
        primary: {
            main: '#121640',
        },
    },
});

const PopUpModal = React.forwardRef((props, ref) => {
    const {open, setOpen, onSubmit} = props;
    const handleClose = () => setOpen(false);
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="py-10 px-10 align-content-center">
                        <PageSubHeading>Confirm your current password </PageSubHeading>
                        <Words>Please confirm your password before updating your account settings.</Words>
                        <ThemeProvider theme={loginTheme}>
                            <TextField sx={{width: "100%", marginTop: 2, marginBottom: 5}}
                                       id="password"
                                       type="password"
                                       label="Password"
                                       variant="standard"
                                       inputRef={ref}
                            />
                        </ThemeProvider>
                        <ButtonFilled onClick={() => {
                            onSubmit();
                            handleClose();
                        }} className="mb-8">Next</ButtonFilled>
                    </div>
                </Box>
            </Modal>

        </div>
    );
});

export default PopUpModal;

const PageSubHeading = styled.div`
  font-family: ${theme.font.medium};
  font-size: 24px;
  letter-spacing: -1px;
  line-height: 30px;
  padding-top: 10px;
  text-align: center;
  padding-left: 20px;
  color: #4d4d4d;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

const Words = styled.div`
  font-family: ${theme.font.normal};
  font-size: 15px;
  text-align: center;
  color: #4d4d4d;
  margin-bottom: 10px;

`;

const ButtonFilled = styled.div`
  @media (max-width: ${theme.screen.sm}) {
    //width: 50px;
    font-size: 11px;
  }
  background: ${theme.color.primary};
  border: 2px solid ${theme.color.primary};
  padding: 8px 16px;
  font-family: ${theme.font.bold};
  float: right;
  text-align: center;
  font-family: ${theme.font.semibold};
  ${theme.font_size.small};
  color: white;
  border-radius: 18px;
  text-transform: uppercase;
  font-size: 12px;
  margin-left: 10px;
  width: 100px;
  height: 35px;
  cursor: pointer;

  :hover {
    background-color: #f4f4f4;
    border: 2px solid ${theme.color.primary};
    color: ${theme.color.primary};
  }
`