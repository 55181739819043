// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getStorage} from "firebase/storage";
import {getAuth} from "firebase/auth";
import {getDatabase, onValue, push, ref, set, update, remove, get} from "firebase/database";
import {databaseTypesConstants as dbConst} from "./databaseConstants";
import {getFunctions, httpsCallable} from 'firebase/functions';

// import {initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check";

class FireBaseUsage {
    // Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
    constructor() {
        this.firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASURE_ID
        };
        // Initialize Firebase

        this.app = initializeApp(this.firebaseConfig);
        this.analytics = getAnalytics(this.app);

        // Get a reference to the storage service, which is used to create references in your storage bucket
        this.storage = getStorage(this.app);
        this.auth = getAuth(this.app);
        this.database = getDatabase(this.app);
        this.function = getFunctions(this.app);
        // connectFunctionsEmulator(this.function, "localhost", 5001);
        // connectAuthEmulator(this.auth, "http://localhost:9099");
        // connectDatabaseEmulator(this.database, "localhost", 9000);
        // connectStorageEmulator(this.storage, "localhost", 9199);

        // this.app.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
        // this.app.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.LOCALHOST_DEBUG_KEY;
        // const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");
        // this.appCheck = initializeAppCheck(this.app, {
        //     provider: new ReCaptchaV3Provider('6Le4tP8fAAAAACTDWcOzwq6uhNVc5aczXf9Evxfu'),
        //
        //     // Optional argument. If true, the SDK automatically refreshes App Check
        //     // tokens as needed.
        //     isTokenAutoRefreshEnabled: true
        // });
    }

    async onUserCreation() {
        const addFullName = httpsCallable(this.function, 'addUserNameOnSignUp');
        await addFullName();
    }

    addComment(CommentText, docId) {
        const currentDate = new Date().toUTCString();
        // console.log(currentDate)
        const newDid = push(ref(this.database, '/comments/' + docId));
        if (CommentText !== "") {
            console.log(CommentText);
            set(newDid, {
                text: CommentText,
                createdBy: this.auth.currentUser.uid,
                dateCreated: currentDate,
            }).then();
        }

    }

    addPointComment(CommentText, canvasPos, worldPos, docId) {
        const currentDate = new Date().toUTCString();
        // console.log(currentDate)
        const newDid = push(ref(this.database, '/comments/' + docId));
        if (CommentText !== "") {
            // console.log(CommentText);
            set(newDid, {
                text: CommentText,
                createdBy: this.auth.currentUser.uid,
                dateCreated: currentDate,
                worldPos: worldPos,
                canvasPos: canvasPos,
                point: true,
            }).then();
        }

    }

    async getDocumentUrlRef(docId, original = true) {
        let urlRef = "";
        let returnUrl = "";
        // Original: true
        // If original flag is set to true, return the original file for download
        // with storage name ${authorId}/${docId}
        // Original: false and Ending: STP or STEP
        // If original is set to false, return the display url for 3d viewer
        // with storage name ${}/${}-xkt
        await onValue(ref(this.database, `${dbConst.DOCUMENTS.name}/${docId}`), async (snapshot) => {
            const authorId = snapshot.val()[dbConst.DOCUMENTS.options.CREATED_BY];
            if ((snapshot.val()[dbConst.DOCUMENTS.options.EXTENSION] === "stp" ||
                    snapshot.val()[dbConst.DOCUMENTS.options.EXTENSION] === "step") &&
                original === false) {
                urlRef = `${authorId}/${docId}-xkt`;
            } else {
                urlRef = `${authorId}/${docId}`;
                //Adding tracking to Segment Analytics
                window.analytics.track('Download Document', {
                    "FileName": snapshot.val().name,
                    "FileType": snapshot.val().name.split(".").pop(),
                    "Author": snapshot.val().createdBy,
                    "Downloader": this.auth.currentUser.uid
                })
            }
        });

        // console.log(`ret urn url is ${returnUrl}`)
        return urlRef;

    }

    /* Deletes empty folder from FOLDERS, Parent's folder list and deletes relevant PID */
    async checkAndRemoveEmptyFolder(folderId) {
        const folderPath = `/${dbConst.FOLDERS.name}/${folderId}`;
        return new Promise(async (resolve, reject) => {
            try {
                const folderSnapshot = await get(ref(this.database, folderPath));
                const folderData = folderSnapshot.val();
                if (
                    !folderData[dbConst.FOLDERS.options.DOCUMENTS] &&
                    !folderData[dbConst.FOLDERS.options.FOLDERS]
                ) {
                    const parentFolderPath = `/${dbConst.FOLDERS.name}/${folderData[dbConst.FOLDERS.options.PARENT]}/${dbConst.FOLDERS.options.FOLDERS}/${folderId}`
                    const folderPIDSnapshot = await get(ref(this.database, parentFolderPath));
                    const folderPIDValue = folderPIDSnapshot.val();
                    await remove(
                        ref(
                            this.database,
                            `/${dbConst.FOLDERS.name}/${folderData[dbConst.FOLDERS.options.PARENT]}/${dbConst.FOLDERS.options.FOLDERS}/${folderId}`
                        )
                    );
                    await remove(ref(this.database, `/${dbConst.FOLDERS.name}/${folderId}`));
                    await remove(ref(this.database, `/${dbConst.PERMISSIONS.name}/${folderPIDValue}`));
                    // console.log(`Folder ${folderId} is empty and has been removed.`);
                    resolve();
                } else {
                    // console.log(`Folder ${folderId} is not empty.`);
                    reject();
                }
            } catch (error) {
                console.error("Failed to check and remove folder:", error);
                reject(error);
            }
        });
    }
}


export default FireBaseUsage;