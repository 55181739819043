import React, {useContext, useEffect, useState} from 'react';
import {onValue, ref} from "firebase/database";
import theme from "../../../../style/theme";
import styled from "styled-components";
import "../../../../style/userFileDisplay.scss";
import PopUpModal from "../../../common/PopUpModal";
import {httpsCallable} from "firebase/functions";
import UpgradeButton from "../../../navbar/UpgradeButton";
import {databaseTypesConstants, subscriptionPlanStrings} from "../../../../firebase/databaseConstants"
import {FirebaseContext} from "../../../../contexts/FirebaseContext";
import AsyncActionButton from "./AsyncActionButton";

function SubscriptionPage(props) {
    const [user, setUser] = useState({});
    const [subscription, setSubscription] = useState({});
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const {
        signedIn,
    } = props;
    const appFirebase = useContext(FirebaseContext);
    const cancelSubscriptionFunction = httpsCallable(appFirebase.function, 'payment-cancelSubscription');
    const customerPortalFunction = httpsCallable(appFirebase.function, 'payment-createStripeCustomerPortal');

    function handleCancelSubscriptionClick(plan, setOpenCancelModal) {
        if (plan > 0) {
            cancelSubscriptionFunction().then(setOpenCancelModal(false));
            //Analytics to track upload document
            window.analytics.track('Cancel Subscription', {});
        } else {
            console.log("Not pro");
        }
    }

    function handlePortalClick() {
        console.log(subscription)
        const functionData = {
            stripeId: subscription[databaseTypesConstants.SUBSCRIPTION.options.STRIPE_CUSTOMER_ID]
        }
        customerPortalFunction(functionData).then(r => window.location.replace(r.data.url))
    }


//Adding Segment Analytics page function on mount
    useEffect(() => {
        return () => {
            window.analytics.page();
        };
    }, []);

    useEffect(() => {
        if (signedIn) {
            const uid = appFirebase.auth.currentUser.uid;
            const usersRef = ref(appFirebase.database, `/users/${uid}`);
            return onValue(usersRef, (snapshot) => {
                setUser(snapshot.val());
            });
        } else {
            setUser({});
        }
    }, [signedIn]);
    useEffect(() => {
        if (signedIn) {
            const uid = appFirebase.auth.currentUser.uid;
            const subscriptionRef = ref(appFirebase.database, `/subscription/${uid}`);
            return onValue(subscriptionRef, (snapshot) => {
                setSubscription(snapshot.val());
            });
        } else {
            setUser({});
        }
    }, [signedIn]);
    return (
        <div>
            <div className="userFileDisplayOuterContainer">
                <div className="userHomeContainer">
                    <PageHeading>
                        Subscription Settings
                    </PageHeading>

                    <Line/>
                    {signedIn ?
                        <>
                            <SubscriptionPlanLineItem
                                labelText={"Plan"}
                                value={subscription.subscriptionPlan > 0 ? `Subscribed to Jinolo ${subscriptionPlanStrings[subscription.subscriptionPlan]} Plan` : "You are on the Jinolo Free Plan"}
                                displayButton={true}
                                appFirebase={appFirebase}
                                editButtonText={subscription.subscriptionPlan <= 0 ? "Upgrade" : "Cancel subscription"}
                                showUpgrade={subscription.subscriptionPlan <= 0}
                                onClickFunction={() => setOpenCancelModal(true)}
                            />
                            {
                                subscription.subscriptionPlan > 0 &&


                                <div>
                                    {/* Label and Value Display */}
                                    <ItemLabel>
                                        {"Billing Details"}
                                    </ItemLabel>
                                    <div className="flex justify-between">
                                        <div className="max-w-3xl">
                                            <ItemValue>
                                                {"Edit your billing details."}
                                            </ItemValue>
                                        </div>
                                        {/* AsyncActionButton */}
                                        <AsyncActionButton
                                            initialText="Update Details"
                                            loadingText="Updating..."
                                            onClick={handlePortalClick}
                                        />
                                    </div>
                                </div>
                            }

                        </>
                        :
                        <div class="m-10">
                            <div className="noDocumentsNotification">You need to be signed in to view Account
                                Settings.
                            </div>
                        </div>
                    }
                </div>
            </div>
            {signedIn && <PopUpModal
                open={openCancelModal}
                handleClose={() => {
                    setOpenCancelModal(false)
                }}
                text={subscription.subscriptionPlan > 0 ? {
                    heading: "Cancel Subscription",
                    body: ["Are you sure you want to cancel your subscription?",
                        "You will not be able to upload more files and your data may be deleted."]
                } : {
                    heading: "Successfully Cancelled Subscription",
                    body: ["You have successfully cancelled your Jinolo Pro subscription.",
                        "You are now back on the Jinolo Free Plan."]
                }

                }
                buttonOnClick={subscription.subscriptionPlan > 0 ?
                    () => {
                        handleCancelSubscriptionClick(subscription.subscriptionPlan, setOpenCancelModal).then(() => setOpenCancelModal(false));
                    } : () => {
                        setOpenCancelModal(false)
                    }
                }
                buttonText={subscription.subscriptionPlan > 0 ? "Cancel subscription" : "Close"}>

            </PopUpModal>}
        </div>
    );
}

export default SubscriptionPage;

const PageHeading = styled.div`
  font-family: ${theme.font.bold};
  font-size: 28px;
  line-height: 30px;
  text-align: left;
  padding-left: 20px;
  color: #4d4d4d;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

const Line = styled.div`
  border-bottom: 2px solid #b3b3b3;
`;

const PageSubHeading = styled.div`
  font-family: ${theme.font.medium};
  font-size: 24px;
  line-height: 30px;
  padding-top: 10px;
  text-align: left;
  padding-left: 20px;
  color: #4d4d4d;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;


function SubscriptionPlanLineItem(props) {
    const {labelText, value, editButtonText, showUpgrade, appFirebase, onClickFunction} = props;
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setLoading(true);
        onClickFunction();
        // You might want to set setLoading(false) at some point after the operation
    };

    return (
        <div>
            <ItemLabel>
                {labelText}
            </ItemLabel>
            <div className="flex justify-between">
                <div className="max-w-3xl">
                    <ItemValue>
                        {value}
                    </ItemValue>
                </div>
                {
                    showUpgrade ? <UpgradeButton appFirebase={appFirebase} canUpgrade={showUpgrade}/> :
                        <SubscriptionButtonFilled editButtonText={editButtonText}
                                                  onClick={() => {
                                                      onClickFunction();
                                                  }}>
                            {editButtonText}
                        </SubscriptionButtonFilled>
                }
            </div>
        </div>
    )
}

const ItemLabel = styled.div`
  font-family: ${theme.font.bold};
  font-size: 17px;
  line-height: 30px;
  padding-top: 10px;
  text-align: left;
  padding-left: 20px;
  color: #4d4d4d;
`;

const ItemValue = styled.div`
  font-family: ${theme.font.medium};
  font-size: 17px;
  line-height: 30px;
  text-align: left;
  padding-left: 20px;
  color: #4d4d4d;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

const SubscriptionButtonFilled = styled.div`
  @media (max-width: ${theme.screen.sm}) {
    //width: 50px;
    font-size: 11px;
  }
  float: right;
  text-align: center;
  font-family: ${theme.font.semibold};
  ${theme.font_size.small};
  color: white;
  background: #949494;
  border-radius: 18px;
  padding: 8px 20px;
  text-transform: uppercase;
  font-size: 12px;
  margin-left: 10px;
  //width: 100px;
  width: fit-content;
  height: 35px;
  cursor: pointer;
  border: 2px solid #949494;

  :hover {
    background-color: #b3b3b3;
    border: 2px solid #b3b3b3;
  }

  @media (max-width: ${theme.screen.sm}) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  ${({editButtonText}) => editButtonText === "Upgrade" && `
    background: ${theme.color.primary};
    border: 2px solid ${theme.color.primary};
    padding: 8px 16px;
    font-family: ${theme.font.bold};
    :hover {
    background-color: #f4f4f4;
    border: 2px solid ${theme.color.primary};
    color: ${theme.color.primary};
    }
  `}

`


const Input = styled.input`
  font-family: ${theme.font.medium};
  font-size: 17px;
  color: #4d4d4d;
  width: 200%;
  text-align: left;
  height: 35px;
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.color.primary};
  border-image: initial;
  border-radius: 23px;
  padding: 7px 16px;
  font-family: ${theme.font.bold};
  margin-top: 5px;
  margin-left: 8px;
  line-height: 30px;


  :focus {
    box-shadow: inset ${theme.color.secondary} 0px 0px 0px 2px;
    border: none;
    outline: none;
  }

  @media (max-width: ${theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`