import React, {useContext, useState} from 'react';
import { UploadContext } from '../../../../contexts/UploadContext';
import axios from "axios";
import {httpsCallable} from "firebase/functions";
import {FirebaseContext} from "../../../../contexts/FirebaseContext";

function UploadProvider({ children }) {
    const [startedUpload, setStartedUpload] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [unableToUploadFreeFileLimitReached, setUnableToUploadFreeFileLimitReached] = useState(false);
    const [unableToUploadNoStorage, setUnableToUploadNoStorage] = useState(false);
    const [unableToUploadLargeFile, setUnableToUploadLargeFile] = useState(false);
    const appFirebase = useContext(FirebaseContext);
    const getSignedUrl = httpsCallable(appFirebase.function, "upload-generateSignedUrl");

    const startUploadFile = async (file) => {

        const config = {
            // onUploadProgress: progressEvent => console.log(progressEvent.loaded)
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadPercentage(percentCompleted);
                }
        }
        console.log(file)
        const data = {
            filename: file.name,
            size: file.size
        }
        //Analytics to track upload document
        window.analytics.track('Upload Document', {
            "FileName": file.name,
            "FileType": file.name.split(".").pop(),
            "Size": file.size,
            "Author": appFirebase.auth.currentUser.uid,
        });

        setUnableToUploadFreeFileLimitReached(false);
        setUnableToUploadNoStorage(false);

        try {
            const response = await getSignedUrl(data);
            const url = response.data.url;
            await axios.put(url, file, config);

            setUploadSuccess(true);
        } catch (error) {
            console.log(error.code)
            console.log(error.message)
            console.log(error.message)
            if (error && error.message === "Free file upload limit reached") {
                setUnableToUploadFreeFileLimitReached(true);

            } if (error && error.message === "Not enough storage to upload") {
                setUnableToUploadNoStorage(true);

            } if (error && error.message === "File size exceeds the limit") {
                setUnableToUploadLargeFile(true);

            }
            // ... handle other specific errors
        } finally {
            setStartedUpload(false);
        }
    }

    return (
        <UploadContext.Provider
            value={{
                startedUpload,
                setStartedUpload,
                uploadSuccess,
                setUploadSuccess,
                startUploadFile,
                uploadPercentage,
                unableToUploadFreeFileLimitReached,
                setUnableToUploadFreeFileLimitReached,
                unableToUploadNoStorage,
                setUnableToUploadNoStorage,
                unableToUploadLargeFile,
                setUnableToUploadLargeFile
            }}
        >
            {children}
        </UploadContext.Provider>
    );
}

export default UploadProvider;