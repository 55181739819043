import {useMemo, useState} from "react";

export const useFilterData = (items, query) => {
    const [filteredItems, setFilteredItems] = useState(items);

    useMemo(() => {
        const result = items.filter((item) => {
            return (

                item && (item.fileName ? item.fileName.toLowerCase().includes(query.toLowerCase()) : " ") ||
                item && (item.author ? item.author.toLowerCase().includes(query.toLowerCase()) : " ") ||
                item && (item.createdDate ? item.createdDate.toLowerCase().includes(query.toLowerCase()) : " ")
            );
        });
        setFilteredItems(result);
    }, [items, query]);

    return filteredItems;
};
