import React, {useContext} from "react";
import {FirebaseContext} from "../../../contexts/FirebaseContext";
import PdfView from "./viewers/pdfView";
import CADViewer from "./viewers/CADViewer";
import DocumentViewPage from "./DocumentViewPage";
import {useSidePanel} from "../../../contexts/SidePanelRightContext";
import CADViewerHead from "./viewers/CADViewerHead";

function Viewer(props) {
    const {
        fileEnding,
        did,
        documentUrl,
        permissionObject,
        viewerRef,
        setHasParts
    } = props;
    const supportedFormats = ["pdf", "stl", "stb", "3dxml", "gltf", "glb", "obj", "xkt", "ifc"];
    const appFirebase = useContext(FirebaseContext);
    const { currentSidePanelMode, openSidePanel } = useSidePanel();
    // console.log(documentUrl)
    if (documentUrl) {

        if (supportedFormats.includes(fileEnding.toLowerCase())) {
            if (fileEnding.toLowerCase() === "pdf") {
                return (<PdfView storage={appFirebase.storage} docId={did} downloadUrl={documentUrl}/>);
            } else {
                return (
                    // <CADViewer docId={did}
                    //            url={documentUrl}
                    //            appFirebase={appFirebase}
                    //            fileEnding={fileEnding.toLowerCase()}
                    //            sidePanelMode={currentSidePanelMode}
                    //            setStartPointComment={setStartPointComment}
                    //            startPointComment={startPointComment}
                    //            viewerRef={viewerRef}
                    //            setHasParts={setHasParts}
                    // />
                <CADViewerHead docId={did}
                           url={documentUrl}
                           fileEnding={fileEnding.toLowerCase()}
                           sidePanelMode={currentSidePanelMode}
                           viewerRef={viewerRef}
                           setHasParts={setHasParts}
                />
                );
            }
        } else {
            return (
                <div
                    className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-grotesk-normal text-center">
                    Unfortunately, the file format {fileEnding} is not currently supported. We are working
                    to add more formats every day. If you have the necessary permissions, you can download
                    the file directly. Please send us an email at hello@jinolo.com to request support for
                    specific file formats.
                </div>
            );
        }
    }
}

export default Viewer;