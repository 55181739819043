import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    DistanceMeasurementsPlugin,
    SectionPlanesPlugin,
    TreeViewPlugin,
    Viewer,
    DistanceMeasurementsMouseControl,
    PointerLens,
    NavCubePlugin,
    AxisGizmoPlugin
} from "@xeokit/xeokit-sdk";
import "./Annotations/annotation.scss";
import {setCustomLighting} from "./CADViewerUtils/CustomLighting";
import {loadModelByType} from "./CADViewerUtils/ModelLoaderByType";
import {handleModelLoaded, zoomToFit} from "./CADViewerUtils/ModelHandling";
import ToolMenu from "../tools/ToolMenu";
import {FirebaseContext} from "../../../../contexts/FirebaseContext";
import {fabric} from 'fabric';
import {
    COMMENT_TOOL,
    EXPLODE_TOOL,
    FIT_TOOL,
    IS_OPEN,
    MEASURE_TOOL,
    SECTION_TOOL,
    SELECT_TOOL,
    PEN_TOOL, COMPONENT_TOOL
} from "../tools/toolConstants";
import {SidePanelModes, useSidePanel} from "../../../../contexts/SidePanelRightContext";
import {
    createAnnotationObjectOnLoading, createAnnotationOnClick, destroyUnusedAnnotation,
    fetchAndCreateAnnotations, handleNewComment, handleRemovedComment,
    setupAnnotationListeners
} from "./CADViewerUtils/AnnotationHandling";
import AnnotationTextBox from "./Annotations/AnnotationTextBox";

function CadViewerHead(props) {
    const {
        docId,
        url,
        fileEnding,
        sidePanelMode,
        viewerRef,
        setHasParts
    } = props;
    const appFirebase = useContext(FirebaseContext);
    const [canvasId, setCanvasId] = useState(docId);
    // Declaring state variables for the model and loaders
    const [model, setModel] = useState(null);
    const [center, setCenter] = useState(null);
    const [viewer, setViewer] = useState(null);
    const [sectionPlanes, setSectionPlanes] = useState(null);
    const [axisView, setAxisView] = useState(null);
    const [navCube, setNavCube] = useState(null);
    const [distanceMeasurement, setDistanceMeasurement] = useState(null);
    const [distanceMeasurementValues, setDistanceMeasurementValues] = useState(null);
    const [treeView, setTreeView] = useState(null);
    const [annotations, setAnnotations] = useState(null);
    const [mouseClickedListener, setMouseClickedListener] = useState(null);
    const onClickAnnotationsRef = useRef(null);
    const [startCommentState, setStartCommentState] = useState(false);
    const startCommentRef = useRef(startCommentState);
    const [currentCanvasPos, setCurrentCanvasPos] = useState(null);
    const [currentWorldPos, setCurrentWorldPos] = useState(null);
    const annotationCounterRef = useRef(0);
    //Drawing tool
    const [fabricCanvas, setFabricCanvas] = useState(null);

    const [tools, setTools] = useState({
        [IS_OPEN]: true,
        [SECTION_TOOL]: false,
        [MEASURE_TOOL]: false,
        [EXPLODE_TOOL]: false,
        [FIT_TOOL]: false,
        [SELECT_TOOL]: true,
        [COMMENT_TOOL]: false,
        [PEN_TOOL]: false,
        // ... add more tools here
    });

    const createNewFabricCanvas = (setFabricCanvas) => {
        const newFabricCanvas = new fabric.Canvas('fabricCanvas', {
            isDrawingMode: true,
        });
        newFabricCanvas.freeDrawingBrush.color = 'red';
        newFabricCanvas.freeDrawingBrush.width = 2;
        // Function to update canvas size
        const updateCanvasSize = () => {
            newFabricCanvas.setHeight(window.innerHeight);
            newFabricCanvas.setWidth(window.innerWidth);
            newFabricCanvas.renderAll();
        };

        // Set initial size
        updateCanvasSize();

        window.addEventListener('resize', updateCanvasSize);

        setFabricCanvas(newFabricCanvas);
    }

    //Initialising fabric canvas
    useEffect(() => {
        // Initialize the Fabric.js canvas
        if (tools[PEN_TOOL]) {
            if (!fabricCanvas) {
                createNewFabricCanvas(setFabricCanvas);
            }
        } else {
            if (fabricCanvas) {
                fabricCanvas.dispose();
                setFabricCanvas(null);
            }
        }
    }, [tools[PEN_TOOL]]);

    useEffect(() => {
        // console.log("called ref useEffect")
        // console.log(`UseEffect Ref is ${startCommentRef.current}`)
        startCommentRef.current = startCommentState;
        // setStartCommentState(startCommentRef.current)
    }, [startCommentState]);

    useEffect(() => {
        // console.log(`State after update: ${startCommentState}`);
    }, [startCommentState]);


    useEffect(() => {
        if (annotations) {
            return setupAnnotationListeners(appFirebase, docId, handleNewComment, handleRemovedComment, annotations, setAnnotations);
        }

        // This will run the cleanup when the component unmounts or dependencies change
    }, [appFirebase, docId, annotations]);

    const handleToolClick = (toolName) => {

        // Invoke a specific logic based on tool name
        let newToolState = {...tools};
        switch (toolName) {
            case SECTION_TOOL:
                if (!tools[SECTION_TOOL]) {
                    let position = [0, 0, 0];
                    if (center.length > 0) {
                        position = center;
                    }
                    sectionPlanes.createSectionPlane({
                        id: "mySectionPlane1",
                        pos: position,
                        dir: [1.0, 0.0, 0.0]
                    });
                    sectionPlanes.showControl("mySectionPlane1");
                    sectionPlanes.setOverviewVisible(true);
                    // Update the tool state
                    newToolState[SELECT_TOOL] = false;
                    newToolState[SECTION_TOOL] = true;
                } else {
                    sectionPlanes.clear();
                    // Update the tool state
                    newToolState[SECTION_TOOL] = false;
                }
                break;
            case MEASURE_TOOL:
                if (!tools[MEASURE_TOOL]) {
                    // console.log("Measure tool activated!");
                    distanceMeasurement.activate()
                    newToolState[MEASURE_TOOL] = true;
                    newToolState[SELECT_TOOL] = false;
                } else {
                    distanceMeasurement.deactivate()
                    distanceMeasurementValues.destroy()
                    newToolState[MEASURE_TOOL] = false;
                }
                break;
            case FIT_TOOL:
                zoomToFit(model, viewer, setViewer);
                break;
            case COMPONENT_TOOL:
                openPartList();
                break;
            case COMMENT_TOOL:
                newToolState[COMMENT_TOOL] = !tools[COMMENT_TOOL];
                if (!newToolState[COMMENT_TOOL]) {
                    if (startCommentState) {
                        destroyUnusedAnnotation(onClickAnnotationsRef, annotationCounterRef);
                        setStartCommentState(false)
                    }
                }
                newToolState[SELECT_TOOL] = false;
                break;
            case SELECT_TOOL:
                // Turning off all other tools when select tool is picked
                if (distanceMeasurement && distanceMeasurementValues) {
                    distanceMeasurement.deactivate()
                    distanceMeasurementValues.destroy()
                }
                if (sectionPlanes) {
                    sectionPlanes.clear();
                }
                Object.keys(newToolState).forEach(toolKey => {
                    if (toolKey !== SELECT_TOOL && toolKey !== IS_OPEN) {
                        newToolState[toolKey] = false;
                    }
                });
                newToolState[SELECT_TOOL] = true;
                break;
            case PEN_TOOL:
                if (!tools[PEN_TOOL]) {
                    newToolState[PEN_TOOL] = true;
                    newToolState[SELECT_TOOL] = false;
                } else {
                    // Deactivating logic
                    newToolState[PEN_TOOL] = false;
                }
                break;
            default:
                break;
        }

        // Check if any tool other than selectTool is active. If not, activate the selectTool.
        const isAnyToolActive = Object.keys(newToolState).some(toolKey =>
            toolKey !== SELECT_TOOL && toolKey !== IS_OPEN && newToolState[toolKey]
        );
        if (!isAnyToolActive) {
            newToolState[SELECT_TOOL] = true;
        }

        // Update the tools state
        setTools(newToolState);
    }

    // Hook for TreeView to see parts of the model
    useEffect(() => {
        if (treeView && sidePanelMode !== SidePanelModes.PART_LIST) {
            treeView.destroy();
        }

        if (viewerRef.current && sidePanelMode === SidePanelModes.PART_LIST) {
            if (treeView) {
                treeView.destroy();
            }
            const newTreeView = new TreeViewPlugin(viewer, {
                containerElement: props.viewerRef.current,
                autoAddModels: true,
                autoExpandDepth: 1,
            });
            setTreeView(newTreeView);
        }
        return () => {
            if (treeView) {
                treeView.destroy();
            }
        };
    }, [props.viewerRef, sidePanelMode, viewer, viewerRef]);

    // Hook that runs on component loading
    useEffect(() => {
        // This will run once when the component mounts.
        // Add any initialization or side-effects code here.
        const newViewer = new Viewer({
            // canvasId: "myCanvas",
            canvasId: canvasId,
            transparent: true
        });
        const metrics = newViewer.scene.metrics;
        if (fileEnding === "xkt") {
            metrics.units = "millimeters";
        } else {
            metrics.units = "metres";
        }


        metrics.scale = 1;

        // loadModelByType(newViewer, setViewer, setModel, fileEnding, url).then(handleModelLoaded);
        loadModelByType(newViewer, setViewer, setModel, fileEnding, url)
            .then(model => {
                handleModelLoaded(model, newViewer, setViewer, setCenter, setHasParts)
            });
        // Adding custom lights to stl files because default is too dark
        setCustomLighting(newViewer, setViewer, fileEnding);

        // Creating measure tool
        const newDistanceMeasurements = new DistanceMeasurementsPlugin(newViewer);

        const distanceMeasurementsControl = new DistanceMeasurementsMouseControl(newDistanceMeasurements, {
            pointerLens: new PointerLens(newViewer)
        })

        distanceMeasurementsControl.snapToVertex = true;
        distanceMeasurementsControl.snapToEdge = true;

        setDistanceMeasurement(distanceMeasurementsControl)
        setDistanceMeasurementValues(newDistanceMeasurements)

        // Initialize and set NavCubePlugin
        const newNavCube = new NavCubePlugin(newViewer, {
            canvasId: "navCubeCanvas",
            visible: true,
            size: 200,
            // ... other configuration properties
        });
        setNavCube(newNavCube);

        // Initialize and set AxisViewPlugin
        const newAxisView = new AxisGizmoPlugin(newViewer, {
            canvasId: "axisViewCanvas",
            visible: true,
            size: 200,
            // ... other configuration properties
        });
        setAxisView(newAxisView);

        // distanceMeasurementsControl.activate();
        setViewer(newViewer)

        // Creating annotations from database
        createAnnotationObjectOnLoading(annotations, setAnnotations, newViewer, setViewer)
            .then(annotationResponse => {
                fetchAndCreateAnnotations(appFirebase, docId, annotationResponse)
                setAnnotations(annotationResponse)
            });


        const newSectionPlanes = new SectionPlanesPlugin(newViewer, {
            // overviewCanvasID: "myOverviewCanvas",
            overviewVisible: true
        });
        setSectionPlanes(newSectionPlanes);

        return () => {
            // console.log(`viewer is desoryed: ${viewer.isDestroyed}`)
            try {
                if (distanceMeasurementValues) {
                    distanceMeasurementValues.destroy();
                }
                if (distanceMeasurement) {
                    distanceMeasurement.deactivate();
                    distanceMeasurement.destroy();
                }
                if (viewer) {
                    viewer.destroy();
                }
                // Other cleanup actions
                if (treeView) {
                    treeView.destroy();
                }

                if (newNavCube) newNavCube.destroy();
                if (newAxisView) newAxisView.destroy();

                if (sectionPlanes) {
                    sectionPlanes.destroy();
                }

                if (annotations) {
                    annotations.destroy();
                }
            } catch (error) {
                // console.error("Error during cleanup:", error);
            }

        };
    }, []);

    useEffect(() => {
        if (!viewer) {
            if (distanceMeasurementValues) {
                distanceMeasurementValues.destroy();
            }
            if (distanceMeasurement) {
                distanceMeasurement.deactivate();
                distanceMeasurement.destroy();
            }
            // Other cleanup actions
            if (treeView) {
                treeView.destroy();
            }

            if (sectionPlanes) {
                sectionPlanes.destroy();
            }

            if (annotations) {
                annotations.destroy();
            }
        }
        return () => {
            if (viewer) {
                viewer.destroy()
            }
        };
    }, [viewer]);


    //Hook for annotations
    useEffect(() => {
        // Check if the annotations object exists before setting listeners
        if (annotations) {
            const handleMarkerMouseEnter = (annotation) => {
                annotation.setLabelShown(true);
            };

            // const handleMarkerClicked = (annotation) => {
            //     console.log(`clicked Annotation ${annotation.canvasPos}`)
            // };

            const handleMarkerMouseLeave = (annotation) => {
                annotation.setLabelShown(false);
            };

            // Add listeners
            annotations.on("markerMouseEnter", handleMarkerMouseEnter);
            // annotations.on("markerClicked", handleMarkerClicked);
            annotations.on("markerMouseLeave", handleMarkerMouseLeave);

            // Cleanup: This will run when the component is unmounted or when the annotations object changes
            return () => {
                annotations.off("markerMouseEnter", handleMarkerMouseEnter);
                // annotations.off("markerClicked", handleMarkerClicked);
                annotations.off("markerMouseLeave", handleMarkerMouseLeave);
            };
        }
    }, [annotations]); // Dependency array: Re-run this effect only when the annotations object changes

    useEffect(() => {
        if (distanceMeasurementValues) {
            // Listener for when a measurement is hovered over
            const handleMeasurementMouseOver = (e) => {
                e.distanceMeasurement.color="#121640";
            };

            // Listener for when the mouse leaves a measurement
            const handleMeasurementMouseLeave = (e) => {
                e.distanceMeasurement.color="#00BBFF";
            };

            // Listener for right-click on a measurement to show context menu
            const handleMeasurementContextMenu = (e) => {
                // console.log("context menu")
                // console.log(e)
                // e.event.preventDefault();
                // Show context menu logic here
                const measureId = e.distanceMeasurement.id;
                e.plugin.destroyMeasurement(measureId)
            };

            // Registering event listeners
            distanceMeasurementValues.on("mouseOver", handleMeasurementMouseOver);
            distanceMeasurementValues.on("mouseLeave", handleMeasurementMouseLeave);
            distanceMeasurementValues.on("contextMenu", handleMeasurementContextMenu);

            // Cleanup function to remove listeners when the component unmounts or dependencies change
            return () => {
                distanceMeasurementValues.off("mouseOver", handleMeasurementMouseOver);
                distanceMeasurementValues.off("mouseLeave", handleMeasurementMouseLeave);
                distanceMeasurementValues.off("contextMenu", handleMeasurementContextMenu);
            };
        }
    }, [distanceMeasurementValues]); // Dependency array: Re-run this effect only when the distanceMeasurementValues object changes



    // Hook for annotations when clicked
    useEffect(() => {
        const handleMouseClicked = (coords) => {
            if (tools[COMMENT_TOOL]) {
                if (startCommentRef.current) {
                    destroyUnusedAnnotation(onClickAnnotationsRef, annotationCounterRef);
                    setStartCommentState(false);
                } else {
                    createAnnotationOnClick(
                        coords,
                        viewer,
                        appFirebase.auth.currentUser.displayName,
                        setCurrentCanvasPos,
                        setCurrentWorldPos,
                        onClickAnnotationsRef,
                        annotationCounterRef,
                        startCommentRef,
                        setStartCommentState,
                        startCommentState
                    )
                    setStartCommentState(true);
                }
            }
        }
        if (viewer) {
            if (tools[COMMENT_TOOL] && appFirebase.auth.currentUser) {
                const mouseClicked = viewer.scene.input.on("mouseclicked", handleMouseClicked);
                setMouseClickedListener(mouseClicked)

            } else if (!tools[COMMENT_TOOL]) {
                viewer.scene.input.off(mouseClickedListener);
            }
        }
        // Cleanup function
        return () => {
            if (viewer) {
                viewer.scene.input.off(mouseClickedListener);
            }
        };
    }, [tools[COMMENT_TOOL]]);
    const { openPartList } = useSidePanel();
    return (
        <div className="overflow-y-hidden overflow-x-hidden">
            {tools[PEN_TOOL] && <div className="w-full h-full absolute bg-transparent z-50">
            {/*{<div className="w-full h-full absolute bg-transparent z-50">*/}
                <canvas
                    // ref={fabricRef}
                    id="fabricCanvas"
                    className="bg-transparent z-50"
                    // className="bg-[#c6beee] z-50"
                    width='500%'
                    height='500%'
                    // style={{
                    //     zIndex: '100000',
                    // }} // Adjust styling as needed
                ></canvas>
            </div>}
            {/*<div className="w-full h-full absolute bg-[#D3D3D3FF] z-30">*/}
            <div className="w-full h-full absolute bg-[#D3D3D3FF]">
                <canvas
                    id={canvasId}
                    // className="bg-transparent z-40"
                    style={{width: '100%', height: '100%' }}
                >
                </canvas>
                {/* NavCube Canvas */}
                <canvas id="navCubeCanvas"
                        width="190" height="190"
                        className="hidden md:block absolute right-1 bottom-1">
                </canvas>

                {/* AxisView Canvas */}
                <canvas id="axisViewCanvas"
                        width="190" height="190"
                        className="hidden md:block absolute left-1 bottom-1">
                </canvas>
            </div>
            {(startCommentState && tools[COMMENT_TOOL]) &&
                <AnnotationTextBox
                    // key={annotation}
                    appFirebase={appFirebase}
                    docId={docId}
                    canvasPos={currentCanvasPos}
                    worldPos={currentWorldPos}
                    setStartCommentState={setStartCommentState}
                    onClickAnnotationsRef={onClickAnnotationsRef}
                    annotationCounterRef={annotationCounterRef}
                />
            }
            <ToolMenu tools={tools} setTools={setTools} onToolClick={handleToolClick}/>
        </div>
    );
}

export default CadViewerHead;