import React, {useContext, useEffect} from 'react';
import FlipIcon from '@mui/icons-material/Flip';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import NearMeIcon from '@mui/icons-material/NearMe';
import AddCommentIcon from '@mui/icons-material/AddComment';
import StraightenIcon from '@mui/icons-material/Straighten';
import CreateIcon from '@mui/icons-material/Create';
import SettingsOverscanIcon from '@mui/icons-material/SettingsOverscan';
import { Tooltip } from "@mui/material";
import {
    COMMENT_TOOL,
    FIT_TOOL,
    MEASURE_TOOL,
    PEN_TOOL,
    SECTION_TOOL,
    SELECT_TOOL,
    COMPONENT_TOOL
} from "./toolConstants";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {SidePanelModes, useSidePanel} from "../../../../contexts/SidePanelRightContext";



function ToolMenu({ openMenu = true, tools, setTools, onToolClick }) {
    const {currentSidePanelMode} = useSidePanel();

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                // Trigger the same action as clicking the SELECT_TOOL
                onToolClick(SELECT_TOOL);
            }
        };

        // Add event listener for keydown events
        document.addEventListener("keydown", handleKeyDown);

        // Remove event listener on cleanup
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [onToolClick]);

    const toggleTool = (toolName, action) => {
        setTools(prevTools => ({
            ...prevTools,
            [toolName]: !prevTools[toolName]
        }));
        if(action) action();
    };

    const clickTool = (toolName, action) => {
        setTools(prevTools => ({
            ...prevTools,
            [toolName]: true
        }));

        action();

        setTimeout(() => {
            setTools(prevTools => ({
                ...prevTools,
                [toolName]: false
            }));
        }, 200);  // 300ms or adjust this duration as desired


    };

    return (
        <div>
            {openMenu &&
                <div className={`absolute top-1/3 left-0 flex flex-col bg-gray-200 p-4 items-center z-50`}>
                    <div className="bg-white px-3 py-3 rounded-full cursor-pointer text-navy">
                        <div onClick={() => toggleTool('isOpen')}
                             className="w-full h-12 rounded-lg flex items-center justify-center bg-white ">
                            {
                                tools.isOpen ?
                                    <Tooltip title="Hide toolbar">
                                        <KeyboardDoubleArrowUpIcon />
                                    </Tooltip>

                                    :

                                <Tooltip title="Show toolbar">
                                    <KeyboardDoubleArrowDownIcon />
                                </Tooltip>
                            }
                        </div>
                        {tools.isOpen && (
                            <>
                                <div className={`w-full h-12 rounded-lg flex items-center justify-center ${
                                    tools[SELECT_TOOL] ? 'text-primary' : ''
                                }`}
                                     onClick={() => onToolClick(SELECT_TOOL)}
                                >
                                    <Tooltip title="Select">
                                        <NearMeIcon/>
                                    </Tooltip>
                                </div>
                                <div className={`w-full h-12 rounded-lg flex items-center justify-center ${
                                    tools[FIT_TOOL] ? 'text-primary' : ''
                                }`}
                                     onClick={() => clickTool(FIT_TOOL, () => onToolClick(FIT_TOOL))}
                                >
                                    <Tooltip title="Zoom to Fit">
                                        <SettingsOverscanIcon/>
                                    </Tooltip>
                                </div>
                                <div className={`w-full h-12 rounded-lg flex items-center justify-center ${
                                    tools[COMMENT_TOOL] ? 'text-primary' : ''
                                }`}
                                     onClick={() => onToolClick(COMMENT_TOOL)}
                                >
                                    <Tooltip title="Add comment">
                                        <AddCommentIcon/>
                                    </Tooltip>
                                </div>
                                <div className={`w-full h-12 rounded-lg flex items-center justify-center ${
                                    tools[SECTION_TOOL] ? 'text-primary' : ''
                                }`}
                                     onClick={() => onToolClick(SECTION_TOOL)}
                                >
                                    <Tooltip title="Section">
                                        <FlipIcon/>
                                    </Tooltip>
                                </div>
                                <div className={`w-full h-12 rounded-lg flex items-center justify-center ${
                                    tools[MEASURE_TOOL] ? 'text-primary' : ''
                                }`}
                                     onClick={() => toggleTool([MEASURE_TOOL], () => onToolClick(MEASURE_TOOL))}
                                >
                                    <Tooltip title="Measure">
                                        <StraightenIcon/>
                                    </Tooltip>
                                </div>
                                <div className={`w-full h-12 rounded-lg flex items-center justify-center ${
                                    currentSidePanelMode === SidePanelModes.PART_LIST ? 'text-primary' : ''
                                }`}
                                     onClick={() => clickTool(COMPONENT_TOOL, () => onToolClick(COMPONENT_TOOL))}
                                >
                                    <Tooltip title="View component tree">
                                        <AccountTreeIcon/>
                                    </Tooltip>
                                </div>
                                <div className={`w-full h-12 rounded-lg flex items-center justify-center ${
                                    tools[PEN_TOOL] ? 'text-primary' : ''
                                }`}
                                     onClick={() => toggleTool([PEN_TOOL], () => onToolClick(PEN_TOOL))}
                                >
                                    <Tooltip title="Draw">
                                        <CreateIcon/>
                                    </Tooltip>
                                </div>

                            </>
                        )}
                    </div>
                </div>
            }
        </div>
    );
}

export default ToolMenu;
