import {createTheme} from "@mui/material";
import {grey} from "@mui/material/colors";

export const modalMaterialTheme = createTheme({
    palette: {
        primary: {
            main: "#00c4cc",
        },
        secondary: {
            main: grey[100],
        },
    },
});