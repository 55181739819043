import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";

function HubSpotChat({isChatOpen}) {
    const portalId = 22162875;
    const location = useLocation();
    useEffect(() => {
        // Remove the script tag
        const script = document.getElementById('hs-script-loader');
        if (script) {
            script.remove();
        }
            // Add the script tag with loadImmediately set to false
            const hsConversationsSettings = {
                loadImmediately: false,
                inlineEmbedSelector: '#hubspot-conversations-inline-embed-selector',
            };
        let scriptTag = document.createElement('script');
        scriptTag.src = `//js.hs-scripts.com/${portalId}.js`;
        scriptTag.async = true;

        document.body.appendChild(scriptTag);
            if (window.HubSpotConversations) {
                if(!isChatOpen) {
                    window.HubSpotConversations.widget.remove()
                }
                else {
                    window.HubSpotConversations.widget.load()
                }
            }
        // Remove the script tag when the component unmounts
        return () => {
            const script = document.getElementById('hs-script-loader');
            if (script) {
                script.remove();
            }
        };
    }, [location.pathname, portalId, isChatOpen]);

    return <div id="hubspot-conversations-inline-embed-selector"></div>;
}

export default HubSpotChat;