import React from 'react';
import {Avatar, ClickAwayListener, Divider, Grow, MenuList, Paper, Popper} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components"
import theme from "../../style/theme"
import {Link} from "react-router-dom";

function LogInButton(props) {
    const {fullName, email, handleSignOut} = props;

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        function nameToInitials(name) {
            let initials = ''
            const nameArray = name.trim().split(' ');
            if ( nameArray.length === 1 ) {
                initials = `${name.split(' ')[0][0]}`
            }
            else if (nameArray.length === 2 || nameArray.length > 2) {
                initials = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
            }
            else {
                initials = `OO`
            }
            return initials;
        }
        if (name) {
            return {
                sx: {
                    bgcolor: stringToColor(name),
                },
                children: nameToInitials(name),
            };
        } else {
            return {
                sx: {
                    bgcolor: "#e43397",
                },
                children: ``,
            };
        }

    }

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div>
            <Avatar
                {...stringAvatar(fullName)}
                ref={anchorRef}
                onClick={handleToggle}
                sx={{
                    marginLeft: 2
                }}
            />

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper
                            sx={{
                                minWidth: 250
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>

                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <UserFullName>{fullName}</UserFullName>
                                    <div className="font-grotesk-normal text-sm pt-1 text-left px-5 text-gray-500 mb-2.5">
                                        {email}
                                    </div>
                                    <Divider/>
                                    <MenuItem onClick={handleClose}>
                                        <Link to={`/settings`} style={{textDecoration: 'none'}}>
                                            <MenuText>
                                                Account settings
                                            </MenuText>
                                        </Link>
                                    </MenuItem>
                                    <Link to={`/`} style={{textDecoration: 'none'}}>
                                        <MenuItem onClick={handleSignOut}><MenuText>Sign out</MenuText></MenuItem>
                                    </Link>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

        </div>
    );
}

export default LogInButton;

const UserFullName = styled.div`
  font-family: ${theme.font.bold};
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  padding-left: 20px;
  color: #4d4d4d;
  margin-top: 10px;
`;

const MenuText = styled.div`
  font-family: ${theme.font.normal};
  margin-left: 3px;
  color: #4d4d4d;

`