import { useState } from 'react';

function TogglePill({activeOption, setActiveOption, option1, option2}) {

    return (
        <div className="flex flex-col items-center">
            <div className="inline-flex rounded-full bg-grays-hoverGray ">
                <div className="px-2 py-1">
                <button
                    className={`px-4 py-2 rounded-full w-20  ${
                        activeOption === 1 ? 'bg-primary text-white font-grotesk-bold' : 'font-grotesk-medium'
                    }`}
                    onClick={() => setActiveOption(1)}
                >
                    {option1}
                </button>
                <button
                    className={`px-4 py-2 rounded-full w-20  ${
                        activeOption === 2 ? 'bg-primary text-white font-grotesk-bold' : 'font-grotesk-medium'
                    }`}
                    onClick={() => setActiveOption(2)}
                >
                    {option2}
                </button>
                </div>
            </div>
        </div>
    );
}

export default TogglePill