import React, {forwardRef, useEffect, useState} from 'react';
import {List, TextField} from "@mui/material";
import './treeView.scss'
import CloseIcon from '@mui/icons-material/Close';
import {useSidePanel} from "../../../../../../contexts/SidePanelRightContext";
import {ReactComponent as PartSVG} from "../../../../../../assets/no_parts_file_page.svg";

const textFieldStyle = {
    position: "fixed",
    bottom: 10,
    right: 24,
    backgroundColor: '#f4f4f4',
    width: 400,
};
const textFieldStyleForPoint = {
    position: "fixed",
    bottom: 10,
    right: 24,
    backgroundColor: '#ae14d7',
    width: 400,
};

// const PartListChannel = ({viewerRef}) => {
// const PartListChannel = forwardRef((props, ref) => {
const PartListChannel = forwardRef(function PartListChannel(props, ref) {
    const {closeSidePanel} = useSidePanel();
    const {setViewerRefFinishedLoading, hasParts} = props;

    useEffect(() => {
        setViewerRefFinishedLoading(true);
        return () => {
            setViewerRefFinishedLoading(false);
        }
    }, []);

    return (
        <>
            <div className="commentContainer">
                {/*<div className="HeadingTextDrawer">*/}
                {/*    Comments*/}
                {/*</div>*/}
                <div className="flex justify-between mt-3">
                    <div/>
                    <div className="font-grotesk-bold text-lg text-grays-headingTextGray text-center">
                        Component Tree
                    </div>
                    <div onClick={closeSidePanel}>
                        <div>
                            <CloseIcon className="cursor-pointer text-sm text-textGray"/>
                        </div>
                    </div>
                </div>
                <div >
                    {hasParts === true ?
                        <div className="font-grotesk-normal pt-10 pl-10 text-xl cursor-pointer accent-primary" ref={ref} id="treeViewContainer"></div>
                        :
                        <div className="absolute flex flex-col justify-center items-center px-4" style={{ top: '42%' }}>
                            <PartSVG className="w-40 h-40 mx-2 my-2" />
                            <span className="text-center font-grotesk-normal text-xl pt-5 px-5 text-grays-headingTextGray">
                            It looks like this model doesn't have separate parts.
                                For distinct components, consider uploading a file format that supports this feature.
                            </span>
                        </div>
                    }

                    {/*{this.state.isSingleMesh ?*/}
                    {/*    <div className="absolute top-1/2 left-1/2 flex flex-col bg-gray-200*/}
                    {/*p-4 items-center font-grotesk-normal text-lg">The model has no parts</div> :*/}
                    {/*    <div*/}
                    {/*        className="absolute top-1/2 left-1/2 flex flex-col bg-gray-200*/}
                    {/*        p-4 items-center font-grotesk-normal text-lg"*/}
                    {/*        ref={this.props.viewerRef} id="treeViewContainer"></div>}*/}


                </div>
            </div>

        </>
    );
});

export default PartListChannel;