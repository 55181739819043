import React, {forwardRef, useContext} from 'react';
import {Divider, Drawer, List, ListItem} from "@mui/material";
import "../../../../style/header.scss";
import CommentChannel from "./channels/CommentChannel/CommentChannel";
import NotLoggedInCommentChannel from "./channels/CommentChannel/NotLoggedInCommentChannel";
import {useSidePanel, SidePanelModes} from '../../../../contexts/SidePanelRightContext';
import PartListChannel from "./channels/PartListChannel/PartListChannel";
import {FirebaseContext} from "../../../../contexts/FirebaseContext";

// function SidePanelRight(props) {
const SidePanelRight = forwardRef(function SidePanelRight({
                                                              signedIn,
                                                              docId,
                                                              setViewerRefFinishedLoading,
                                                              hasParts
                                                          }, ref) {

    const drawerWidth = 450;

    const {currentSidePanelMode, openSidePanelState} = useSidePanel();
    const appFirebase = useContext(FirebaseContext);

    const renderPanelContent = () => {
            switch (currentSidePanelMode) {
                case SidePanelModes.COMMENTS:
                    return (
                        <div>
                            <CommentChannel
                                appFirebase={appFirebase}
                                docId={docId}
                                signedIn={signedIn}
                            />
                        </div>
                    );
                // case SidePanelModes.UPLOAD:
                //     return <UploadChannel />;
                case SidePanelModes.PART_LIST:
                    return (
                        <PartListChannel
                            ref={ref}
                            setViewerRefFinishedLoading={setViewerRefFinishedLoading}
                            hasParts={hasParts}
                        />
                    );
                default:
                    return null;
            }
    };


    return (
        <div>
            <Drawer
                PaperProps={{
                    sx: {
                        backgroundColor: "#f4f4f4",
                        zIndex: 60,
                    }
                }}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                open={openSidePanelState}
                anchor="right"
            >
                <Divider/>
                {renderPanelContent()}
            </Drawer>
        </div>
    );
});

export default SidePanelRight;