import React, {useContext, useEffect, useRef, useState} from 'react';
import {Alert, Box, createTheme, Modal, Step, StepLabel, Stepper, TextField, ThemeProvider} from "@mui/material";
import 'firebase/compat/auth';
import './firebaseui-styling.global.css';
import styled from "styled-components";
import theme from "../../style/theme";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    updateProfile
} from "firebase/auth";
import {httpsCallable} from "firebase/functions";
import {onValue, ref} from "firebase/database";
import {SignUpModalContext} from "../../contexts/signUpModalContext";
import CloseIcon from "@mui/icons-material/Close";
import {ArrowBackIos} from "@mui/icons-material";
import {FirebaseContext} from "../../contexts/FirebaseContext";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
    outline: 0,
};
const muiTheme = createTheme({
    palette: {
        primary: {
            main: '#00c4cc',
        },
        secondary: {
            main: '#E33E7F',
        }
    }
});

const SignInUpModal = ({setSignInStatus, uiConfig}) => {
    const [createNewAccount, setCreateNewAccountState] = useState(false);
    const [resetPasswordScreen, setResetPasswordScreen] = useState(false);
    const [question, setQuestion] = useState("No account? ");
    const [answer, setAnswer] = useState(" Create one");
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const {state, dispatch} = useContext(SignUpModalContext);
    const firebaseObject = useContext(FirebaseContext);
    const clearAllAlerts = () => {
        setError(null);
        setSuccessMessage(null);
    }

    const onButtonClickHandler = () => {
        setCreateNewAccountState(!createNewAccount);
        setResetPasswordScreen(false);
        if (!createNewAccount) {
            setQuestion("Have an account? ");
            setAnswer(" Sign in");
        } else {
            setQuestion("No account? ");
            setAnswer(" Create one");
        }
        clearAllAlerts();
    };

    // Handle closing the modal
    const handleClose = () => {
        dispatch({type: 'CLOSE_MODAL'});
        clearAllAlerts();
        setResetPasswordScreen(false);
    };

    const submitSignUpForm = () => {
        handleClose();
    }

    const onSignIn = () => {
        handleClose();
    }


    return (
        <div>
            <Modal
                open={state.modalIsOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    <div className="flex justify-between pb-2">
                        {resetPasswordScreen ? <ArrowBackIos className="cursor-pointer" onClick={() => setResetPasswordScreen(false)}
                                      sx={{color: "#898989", marginRight: 1, marginTop: 1}}/> :
                        <div/>}
                        <CloseIcon className="align-self-end cursor-pointer" onClick={handleClose}
                                   sx={{color: "#898989", marginRight: 1, marginTop: 1}}/>
                    </div>
                    <ThemeProvider theme={muiTheme}>
                        {error && <Alert severity="error">{error} </Alert>}
                        {successMessage && <Alert severity="success">{successMessage} </Alert>}
                        {/*<Alert severity="success">{successMessage} </Alert>*/}

                        {resetPasswordScreen ? <ResetPassword onSubmit={onSignIn} firebaseObject={firebaseObject}
                                                       setError={setError} setSuccessMessage={setSuccessMessage}/>
                        :
                            <>
                                {createNewAccount ?
                                    <SignUp onSubmit={submitSignUpForm} handleClose={handleClose}
                                            firebaseObject={firebaseObject} error={error}
                                            setError={setError}/> :
                                    <LogIn onSubmit={onSignIn} firebaseObject={firebaseObject}
                                           setError={setError} setResetPasswordScreen={setResetPasswordScreen}/>}
                            </>
                        }
                        <div className="flex justify-center pt-2 gap-x-1 py-2 ">{question}
                            <div className="inline-block cursor-pointer" onClick={onButtonClickHandler}><u>{answer}</u>
                            </div>
                        </div>


                        <div className="termsDisclaimer">
                            By continuing, you agree to Jinolo's <a style={{textDecoration: "underline"}}
                                                                    href={"https://jinolo.com/terms"} target="_blank">Terms
                            of Use</a>. Read our <a style={{textDecoration: "underline"}}
                                                    href={"https://jinolo.com/privacy"} target="_blank">Privacy
                            Policy</a>.
                        </div>
                        {/*<EmailInput type="email" name="email" placeholder="Email"  />*/}
                    </ThemeProvider>
                </Box>
            </Modal>
        </div>
    );
}

export default SignInUpModal;

const SignUp = ({onSubmit, handleClose, firebaseObject, error, setError}) => {

    const addUserDataOnSignUp = httpsCallable(firebaseObject.function, 'userManagement-addUserDataOnSignUp');
    const [step, setStep] = useState(0);
    const [data, setData] = useState({});
    const [email, setEmail] = useState(null);
    const createAccountEmailRef = useRef(null);
    const createAccountNameRef = useRef(null);
    const createAccountPasswordRef = useRef(null);
    const createAccountPhoneRef = useRef(null);
    const createAccountIndustryRef = useRef(null);
    const createAccountRoleRef = useRef(null);

    function createUserAndSubmitData(submitData) {
        createUserWithEmailAndPassword(firebaseObject.auth, data["email"], data["password"])
            .then((userCredential) => {
                const user = userCredential.user;
                updateProfile(firebaseObject.auth.currentUser, {
                    displayName: submitData["fullName"]
                }).then(() => {
                }).catch((error) => {
                });
                delete submitData["password"];
                addUserDataOnSignUp(submitData).then();
            })
            .catch((error) => {
                const errorCode = error.code;
                let errorMessage = error.message;
                // Handle specific error messages
                switch (errorCode) {
                    case 'auth/email-already-in-use':
                        errorMessage = 'The email address is already in use by another account.';
                        break;
                    case 'auth/invalid-email':
                        errorMessage = 'The email address is not valid.';
                        break;
                    case 'auth/operation-not-allowed':
                        errorMessage = 'Password sign-in is disabled for this project.';
                        break;
                    case 'auth/weak-password':
                        errorMessage = 'The password is too weak.';
                        break;
                    default:
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                }

                setError(errorMessage);
            });
    }

    const onClickSubmit = async () => {
        if (step < 2) {
            if (step === 0) {
                const emailVal = createAccountEmailRef.current?.value;
                const nameVal = createAccountNameRef.current?.value;
                const pwdVal = createAccountPasswordRef.current?.value;
                let newData = {
                    email: emailVal,
                    fullName: nameVal,
                    password: pwdVal
                }
                await setData(newData)
            } else if (step === 1) {
                const phoneVal = createAccountPhoneRef.current?.value;
                const industryVal = createAccountIndustryRef.current?.value;
                const roleVal = createAccountRoleRef.current?.value;
                const newData = {
                    ...data,
                    phone: phoneVal,
                    industry: industryVal,
                    role: roleVal
                }
                await setData(data => ({
                    ...data,
                    ...newData
                }));
                createUserAndSubmitData(newData);

            }
            setStep(step + 1)
        } else {
            setStep(0);
            handleClose();
        }

    };
    const signUpTextProps = {
        step: step,
        onSubmit: onClickSubmit,
    }

    return (
        <div className="pt-1">
            <Stepper nonLinear activeStep={step}>
                <Step>
                    <StepLabel onClick={() => setStep(0)}/>
                </Step>
                <Step>
                    <StepLabel onClick={() => setStep(1)}/>
                </Step>
                <Step>
                    <StepLabel/>
                </Step>
            </Stepper>
            <div className="px-5 py-5 mx-5"/>
            <PageSubHeading>Create Account</PageSubHeading>
            <>
                <div className="py-4 flex flex-col px-5 gap-y-3 pb-9">
                    {(step === 0) &&
                        <>
                            <SignUpTextLine props={{label: "Email", id: "createAccountEmail", type: "email"}}
                                            ref={createAccountEmailRef}/>
                            <SignUpTextLine props={{label: "Full Name", id: "createAccountName"}}
                                            ref={createAccountNameRef}/>
                            <SignUpTextLine props={{label: "Password", id: "createAccountPassword", type: "password"}}
                                            ref={createAccountPasswordRef}/>
                        </>
                    }
                    {(step === 1) &&
                        <>
                            <SignUpTextLine props={{label: "Phone", id: "createAccountPhone"}}
                                            ref={createAccountPhoneRef}/>
                            <SignUpTextLine props={{label: "Industry", id: "createAccountIndustry"}}
                                            ref={createAccountIndustryRef}/>
                            <SignUpTextLine props={{label: "Role", id: "createAccountRole"}}
                                            ref={createAccountRoleRef}/>
                        </>
                    }
                    {(step === 2) &&
                        <>
                            <div className="font-grotesk-normal text-center text-slate-800">
                                Thank you for signing up to Jinolo!
                            </div>
                            <div className="font-grotesk-normal text-center text-slate-800">
                                Please click the verification link that we sent to your email to finish signing up.
                                Please
                                make sure to check your inbox and spam folder.
                            </div>
                        </>
                    }

                </div>
            </>


            <ButtonFilled onClick={onClickSubmit} className="mb-4 mt-3">
                {(step === 2) ? "Close" : "Next"}
            </ButtonFilled>

        </div>
    );
}

const SignUpTextLine = React.forwardRef(({props}, ref) => {
    const {label, id, type, value, defaultValue, keyPress} = props;

    return (
        <TextField
            id={id}
            inputRef={ref}
            label={label}
            variant="standard"
            type={type}
            onKeyDown={keyPress}
            // defaultValue={(ref.current?.value) ? ref.current?.value : ""}
        />
    )
});


const LogIn = ({onSubmit, firebaseObject, setError, setResetPasswordScreen}) => {
    const signInEmailRef = useRef(null);
    const signInPasswordRef = useRef(null);

    function onClick() {
        signInWithEmailAndPassword(firebaseObject.auth, signInEmailRef.current?.value, signInPasswordRef.current?.value)
            .then((userCredential) => {
                const user = userCredential.user;
                onSubmit();
            })
            .catch((error) => {
                const errorCode = error.code;
                let errorMessage = error.message;
                console.log(`${errorCode} and ${errorMessage}`)
                // Handle specific error messages
                switch (errorCode) {
                    case 'auth/wrong-password':
                        errorMessage = 'The password you entered is incorrect.';
                        break;
                    case 'auth/user-not-found':
                        errorMessage = 'No user found with this email.';
                        break;
                    case 'auth/user-disabled':
                        errorMessage = 'The user account has been disabled by an administrator.';
                        break;
                    case 'auth/email-already-in-use':
                        errorMessage = 'The email address is already in use by another account.';
                        break;
                    case 'auth/invalid-email':
                        errorMessage = 'The email address is not valid.';
                        break;
                    default:
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                }
                setError(errorMessage);
            });

    }

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            // console.log('value', e.target.value);
            onClick();
            // console.log()
            e.target.value = "";
        }
    }

    return (
        <div>
            <div className="py-5 pb-5">
                <PageSubHeading>Sign In</PageSubHeading>
                <div className="py-4 flex flex-col px-5 gap-y-3">
                    <TextField
                        id={"signInEmail"}
                        inputRef={signInEmailRef}
                        label={"Email"}
                        variant="standard"
                        type={"email"}
                        // defaultValue={(ref.current?.value) ? ref.current?.value : ""}
                    />
                    <TextField
                        id={"signInPassword"}
                        inputRef={signInPasswordRef}
                        label={"Password"}
                        variant="standard"
                        type={"password"}
                        onKeyDown={keyPress}
                        // defaultValue={(ref.current?.value) ? ref.current?.value : ""}
                    />
                    {/*<SignUpTextLine props={{id:"signInEmail", type:"email", label:"Email"}} ref={signInEmailRef}/>*/}
                    {/*<SignUpTextLine props={{id:"signInPassword", type:"password", label:"Password"}} ref={signInPasswordRef} keyPress={keyPress}/>*/}
                    <ButtonFilled onClick={onClick} className="mt-10">Submit</ButtonFilled>
                </div>
            </div>
            <div className="flex justify-center pt-1 gap-x-1 inline-block cursor-pointer text-center"
                 onClick={() => setResetPasswordScreen(true)}><u>Reset password</u></div>
        </div>
    );
}

const ResetPassword = ({onSubmit, firebaseObject, setError, setSuccessMessage}) => {
    const signInEmailRef = useRef(null);

    function onClick() {
        sendPasswordResetEmail(firebaseObject.auth, signInEmailRef.current?.value)
            .then(() => {
                setSuccessMessage('Password reset email sent!');
                setError(null);
                // onSubmit();
            })
            .catch((error) => {
                const errorCode = error.code;
                let errorMessage = error.message;

                switch (errorCode) {
                    case 'auth/invalid-email':
                        errorMessage = 'The email address is not valid.';
                        break;
                    case 'auth/user-not-found':
                        errorMessage = 'No user found with this email.';
                        break;
                    default:
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                }

                setError(errorMessage);
                setSuccessMessage(null);
            });
    }


    const keyPress = (e) => {
        if (e.keyCode === 13) {
            // console.log('value', e.target.value);
            onClick();
            // console.log()
            e.target.value = "";
        }
    }

    return (
        <div className="py-5 pb-5">
            <PageSubHeading>Enter your email to reset password</PageSubHeading>
            <div className="py-4 flex flex-col px-5 gap-y-3">
                <TextField
                    id={"signInEmail"}
                    inputRef={signInEmailRef}
                    label={"Email"}
                    variant="standard"
                    type={"email"}
                    // defaultValue={(ref.current?.value) ? ref.current?.value : ""}
                />
                {/*<SignUpTextLine props={{id:"signInEmail", type:"email", label:"Email"}} ref={signInEmailRef}/>*/}
                {/*<SignUpTextLine props={{id:"signInPassword", type:"password", label:"Password"}} ref={signInPasswordRef} keyPress={keyPress}/>*/}
                <ButtonFilled onClick={onClick} className="mt-10">Reset password</ButtonFilled>
            </div>
        </div>
    );
}

const PageSubHeading = styled.div`
  font-family: ${theme.font.medium};
  font-size: 24px;
  letter-spacing: -1px;
  line-height: 30px;
  padding-top: 10px;
  text-align: center;
  padding-left: 20px;
  color: #4d4d4d;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

const ButtonFilled = styled.div`
  text-align: center;
  ${theme.font_size.small};
  color: white;
  border-radius: 18px;
  padding: 8px 16px;
  text-transform: uppercase;
  font-size: 12px;
  margin-left: 10px;
  width: 95%;
  height: 35px;
  cursor: pointer;
  @media (max-width: ${theme.screen.sm}) {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 11px;
  }
  background: ${theme.color.primary};
  border: 2px solid ${theme.color.primary};
  font-family: ${theme.font.bold};

  :hover {
    background-color: #f4f4f4;
    border: 2px solid ${theme.color.primary};
    color: ${theme.color.primary};
  }
`
