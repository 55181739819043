import React, {useContext, useEffect, useState} from 'react';
import "../../../style/header.scss";
import {StyledContainer, ActionsContainer, ButtonOutline} from "../../navbar/style";
import {createTheme, IconButton, Menu, ThemeProvider, Tooltip} from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {grey} from "@mui/material/colors";
import CommentIcon from '@mui/icons-material/Comment';
import DownloadIcon from '@mui/icons-material/Download';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {MoreVert, Share} from "@mui/icons-material";
import styled from "styled-components";
import theme from "../../../style/theme";
import {Link} from "react-router-dom";
import {onValue, ref} from "firebase/database";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import ShareModal from "../../common/ShareModal/ShareModal";
import {FirebaseContext} from "../../../contexts/FirebaseContext";
import {SidePanelModes, useSidePanel} from "../../../contexts/SidePanelRightContext";

const viewerMaterialTheme = createTheme({
    palette: {
        primary: {
            main: grey[500],
        },
        secondary: {
            main: grey[100],
        },
    },
});

export const DocumentName = styled.div`
  font-family: ${theme.font.semibold};
  ${theme.font_size.small};
  color: ${theme.color.black.light};
  text-decoration: none;
  margin: 0;
  @media (max-width: ${theme.screen.sm}) {
    ${theme.font_size.xxxsmall};
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      color: ${theme.color.primary};
      text-decoration: none;
    }
  }
`


function DocHeader(props) {
    const {
        docId,
        signedIn,
        url,
        permissionObject,
        docName,
    } = props;
    const [openShareModal, setOpenShareModal] = useState(false);

    const appFirebase = useContext(FirebaseContext);
    const { currentSidePanelMode, openComments, openPartList } = useSidePanel();

    function allowShare(permissionObject) {
        if (signedIn) {
            if (permissionObject !== null) {
                return permissionObject["owner"] === true;
            }
        }

        return false;
    }

    function allowDownload(permissionObject) {
        if (permissionObject !== null) {
            if (permissionObject["owner"] === true || permissionObject["download"] === true) {
                return true;
            }
        }
        return false;
    }

    function handleDocumentShare() {
        setOpenShareModal(true)
    }

    function onClickDownload() {
        window.open(url, '_blank');
        //Adding tracking to Segment Analytics
        onValue(ref(appFirebase.database, '/documents/' + docId), (snapshot) => {
            window.analytics.track('Download Document', {
                "FileName": snapshot.val().name,
                "FileType": snapshot.val().name.split(".").pop(),
                "Author": snapshot.val().createdBy,
                "Downloader": appFirebase.auth.currentUser.uid
            })
        });
    }

    return (
        <div>
            <div className="header">
                <StyledContainer>
                    <ThemeProvider theme={viewerMaterialTheme}>
                        <Link to="/">
                            <IconButton
                                color="primary"
                                disableRipple={true}
                            >
                                <ArrowBackIosNewIcon/>
                            </IconButton>
                        </Link>
                        <DocumentName className="align-self-center">
                            {docName}

                        </DocumentName>
                        <ActionsContainer>

                            {allowShare(permissionObject) === true &&
                                <Tooltip className="onboarding-tour-share-icon" title="Share file">
                                    <IconButton
                                        color="primary"
                                        disableRipple={true}
                                        onClick={() => handleDocumentShare()}
                                        sx={{ color: openShareModal && "#00c4cc"}}
                                        // sx={{ color: openShareModal && "#00c4cc"}}
                                    >
                                        <Share/>
                                    </IconButton>
                                </Tooltip>
                            }
                            <Tooltip className="onboarding-tour-comment-icon" title="View comments">
                                <IconButton
                                    color="primary"
                                    disableRipple={true}
                                    onClick={openComments}
                                    style={{ position: 'relative' }}
                                    sx={{ color: currentSidePanelMode===SidePanelModes.COMMENTS && "#00c4cc"}}
                                >
                                    <CommentIcon/>
                                </IconButton>
                                {/*{true && <div className="blinking-dot"></div>}*/}
                            </Tooltip>

                                <PopupState variant="popover" popupId="docHeader-popup-menu">
                                    {(popupState) => (
                                        <React.Fragment>
                                            <IconButton
                                                color="primary"
                                                disableRipple={true}
                                                sx={{color: "#6d6d6d"}}
                                                {...bindTrigger(popupState)}
                                                // onClick={() => handleMenuClose(popupState)}
                                            >
                                                <MoreVert/>
                                            </IconButton>

                                            <Menu
                                                {...bindMenu(popupState)}
                                            >
                                                {allowDownload(permissionObject) === true &&
                                                    <div
                                                        className="cursor-pointer flex font-grotesk-small hover:bg-grays-hoverGray pt-1 pb-2 pr-2 pl-1"
                                                        onClick={() => {
                                                            onClickDownload();
                                                            popupState.close();
                                                        }}>
                                                        <div className="pl-3 pt-1 text-textGray">
                                                            <DownloadIcon fontSize={"small"}/>
                                                        </div>
                                                        <div className="pt-1 px-3 text-grays-headingTextGray">
                                                            Download file
                                                        </div>
                                                    </div>
                                                }
                                                    {/*<div*/}
                                                    {/*    className="cursor-pointer flex font-grotesk-small hover:bg-grays-hoverGray pt-1 pb-2 pr-2 pl-1"*/}
                                                    {/*    onClick={() => {*/}
                                                    {/*        openPartList();*/}
                                                    {/*        popupState.close();*/}
                                                    {/*    }}>*/}
                                                    {/*    <div className="pl-3 pt-1 text-textGray">*/}
                                                    {/*        <AccountTreeIcon fontSize={"small"}/>*/}
                                                    {/*    </div>*/}
                                                    {/*    <div className="pt-1 px-3 text-grays-headingTextGray">*/}
                                                    {/*        Component Tree*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                            </Menu>
                                        </React.Fragment>
                                    )}
                                </PopupState>


                        </ActionsContainer>
                    </ThemeProvider>
                </StyledContainer>
            </div>
            <ShareModal handleClose={() => setOpenShareModal(false)} open={openShareModal} appFirebase={appFirebase}
                        docId={docId} docName={docName}/>
        </div>
    );
}

export default DocHeader;

