import React, { useRef, useState} from 'react';
import styled from "styled-components";
import theme from "../../../style/theme";
import DeleteButton from "./DeleteButton";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {grey} from "@mui/material/colors";
import {onValue, ref, set} from "firebase/database";
import {updatePassword, signInWithEmailAndPassword, updateEmail, updateProfile} from "firebase/auth";
import "firebase/auth";
import PopUpModal from "./confirmPasswordModal";

function SettingsLineItem(props) {
    const {labelText, value, appFirebase, type, editButtonText, databaseConstant, displayButton} = props;
    const newValue = useRef(null);
    let currentPasswordRef = useRef(null);
    const [editingState, setEditingState] = useState(false);
    const [openReauthenticateModal, setOpenReauthenticateModal] = useState(false);


    function handleEditClick() {
        if (!editingState) {
            setOpenReauthenticateModal(true);
        } else {
            setEditingState(false);
            if (type === "details") {
                set(ref(appFirebase.database, `users/${appFirebase.auth.currentUser.uid}/${databaseConstant}`), newValue.current?.value).then(() => {
                }).then(() => {
                    // After successful database update, update Firebase Auth profile
                    if (databaseConstant === "firstname" || databaseConstant === "lastname") {
                        // Fetch the latest user data
                        const userRef = ref(appFirebase.database, `users/${appFirebase.auth.currentUser.uid}`);
                        onValue(userRef, (snapshot) => {
                            const userData = snapshot.val();
                            const fullName = `${userData.firstname || ''} ${userData.lastname || ''}`.trim();

                            // Update Firebase Auth display name
                            updateProfile(appFirebase.auth.currentUser, {
                                displayName: fullName
                            }).then(() => {
                                console.log("Firebase Auth profile updated successfully");
                            }).catch((error) => {
                                console.error("Error updating Firebase Auth profile: ", error);
                            });
                        });
                    }
                });
                if (labelText==="Email") {
                    set(ref(appFirebase.database, `uids/${(appFirebase.auth.currentUser.email).replaceAll('.', ',')}`), null).then(() =>{} );
                    set(ref(appFirebase.database, `uids/${(newValue.current?.value).replaceAll('.', ',')}`), appFirebase.auth.currentUser.uid).then(() =>{} );
                    updateEmail(appFirebase.auth.currentUser, newValue.current?.value).then(() => {
                        // Email updated!
                    }).catch(() => {
                        // An error occurred
                    });
                }
            }
            if (type === "password") {
                updatePassword(appFirebase.auth.currentUser, newValue.current?.value).then(() => {
                    // console.log(`Successfully changed password`);
                }).catch(() => {
                });
            }
        }
    }

    function handlePasswordSubmit() {
        signInWithEmailAndPassword(appFirebase.auth, appFirebase.auth.currentUser.email, currentPasswordRef.current?.value).then(() => {
            setEditingState(true);
        }).catch(() => {
            // console.log("Sorry incorrect password");
        })
    }


    function handleCancelClick() {
        setEditingState(false);
    }


    return (<div>
        <ItemLabel>
            {labelText}
        </ItemLabel>
        <div className="flex justify-between">
            <div className="max-w-3xl">
                {editingState ? <div>
                    {type === "password" ? <PasswordInput ref={newValue}/> :
                        <Input defaultValue={value} type="text" ref={newValue}/>}

                </div> : <ItemValue>
                    {value}
                </ItemValue>}
            </div>
            {displayButton!== false && <div className={editingState && "mt-2"}>

                {editButtonText === "Delete" ? <DeleteButton>{editButtonText}</DeleteButton> :
                    <ButtonFilled editButtonText={editButtonText} editingState={editingState}
                                  onClick={handleEditClick}>
                        {editingState ? "Save" : (editButtonText) ? editButtonText : "Edit"}
                    </ButtonFilled>}
                {editingState && <ButtonFilled
                    editButtonText={editButtonText}
                    onClick={handleCancelClick}>
                    Cancel
                </ButtonFilled>}
                {openReauthenticateModal &&
                    <PopUpModal onSubmit={handlePasswordSubmit} open={openReauthenticateModal}
                                setOpen={setOpenReauthenticateModal} ref={currentPasswordRef}>

                    </PopUpModal>
                }
            </div>}
        </div>


    </div>);
}

export default SettingsLineItem;

const ItemLabel = styled.div`
  font-family: ${theme.font.bold};
  font-size: 17px;
  line-height: 30px;
  padding-top: 10px;
  text-align: left;
  padding-left: 20px;
  color: #4d4d4d;
`;

const ItemValue = styled.div`
  font-family: ${theme.font.medium};
  font-size: 17px;
  line-height: 30px;
  text-align: left;
  padding-left: 20px;
  color: #4d4d4d;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

const ButtonFilled = styled.div`
  @media (max-width: ${theme.screen.sm}) {
    //width: 50px;
    font-size: 11px;
  }
  float: right;
  text-align: center;
  font-family: ${theme.font.semibold};
  ${theme.font_size.small};
  color: white;
  background: #aeaeae;
  border-radius: 18px;
  padding: 8px 20px;
  text-transform: uppercase;
  font-size: 12px;
  margin-left: 10px;
  //width: 100px;
  width: fit-content;
  height: 35px;
  cursor: pointer;
  border: 2px solid #aeaeae;

  :hover {
    background-color: #bebebe;
    border: 2px solid #bebebe;
  }

  @media (max-width: ${theme.screen.sm}) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  ${({editingState}) => editingState && `
    background: ${theme.color.primary};
    border: 2px solid ${theme.color.primary};
    padding: 8px 16px;
    font-family: ${theme.font.bold};
    :hover {
    background-color: #f4f4f4;
    border: 2px solid ${theme.color.primary};
    color: ${theme.color.primary};
    }
  `}

  ${({editButtonText}) => editButtonText === "Upgrade" && `
    background: ${theme.color.primary};
    border: 2px solid ${theme.color.primary};
    padding: 8px 16px;
    font-family: ${theme.font.bold};
    :hover {
    background-color: #f4f4f4;
    border: 2px solid ${theme.color.primary};
    color: ${theme.color.primary};
    }
  `}
`


const Input = styled.input`
  font-family: ${theme.font.medium};
  font-size: 17px;
  color: #4d4d4d;
  width: 200%;
  text-align: left;
  height: 35px;
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.color.primary};
  border-image: initial;
  border-radius: 23px;
  padding: 7px 16px;
  font-family: ${theme.font.bold};
  margin-top: 5px;
  margin-left: 8px;
  line-height: 30px;


  :focus {
    box-shadow: inset ${theme.color.secondary} 0px 0px 0px 2px;
    border: none;
    outline: none;
  }

  @media (max-width: ${theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`


const PasswordInput = React.forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    return (<div className="flex">
        <Input type={visible ? "text" : "password"} ref={ref}/>
        <div className="pt-2 pl-4 cursor-pointer" onClick={() => setVisible(!visible)}>
            {visible ? <VisibilityIcon sx={{color: grey[500]}}/> : <VisibilityOffIcon sx={{color: grey[500]}}/>}
        </div>
    </div>)
});