import React from 'react';
import styled from "styled-components";
import theme from "../../../style/theme";

function DeleteButton(props) {
    function handleOnClick() {


    }
    return (
        <div>
            <RedButton onclick={handleOnClick}>Delete</RedButton>
        </div>
    );
}

export default DeleteButton;

const RedButton = styled.div`
  @media (max-width: ${theme.screen.sm}) {
    //width: 50px;
    font-size: 11px;
  }
  float: right;
  text-align: center;
  font-family: ${theme.font.semibold};
  ${theme.font_size.small};
  color: #B71C1C;
  border-radius: 18px;
  padding: 8px 16px;
  text-transform: uppercase;
  font-size: 12px;
  margin-left: 10px;
  width: 100px;
  height: 35px;
  cursor: pointer;
  border: 2px solid #B71C1C;

  :hover {
    background-color: #e6e6e6;
    color: #D32F2F;
    border: 2px solid #D32F2F;
  }
`;