import { useContext, useState } from 'react';
import { UploadContext } from '../../../../contexts/UploadContext';

function useUpload() {
    const context = useContext(UploadContext);

    if (!context) {
        throw new Error('useUpload must be used within an UploadProvider');
    }

    return context;
}

export default useUpload;
