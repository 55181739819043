import React, {useEffect, useState, useContext} from 'react';
import {Link} from "react-router-dom";
import {Alert, CircularProgress, IconButton, ListItem, Snackbar} from "@mui/material";
import {MoreVert, DeleteForever, Undo} from "@mui/icons-material";
import Menu from '@mui/material/Menu';
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import FolderIcon from '@mui/icons-material/Folder';
import {Droppable} from "react-beautiful-dnd";
import {httpsCallable} from "firebase/functions";

function canDelete(permissionObject) {
    if (permissionObject) {
        if (permissionObject.owner === true) {
            return true;
        }
    }
    return false;
}

function FolderListItem(props) {
    const {
        docId,
        appFirebase,
        fileName,
        author,
        date,
        handleFolderDrop,

    } = props;
    const [permissionObject, setPermissionObject] = useState(null);
    const [showErrorDelete, setShowErrorDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    // function canDelete(permissionObject) {
    //     if (permissionObject) {
    //         if (permissionObject.owner === true) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    function deleteFolder() {
        appFirebase
            .checkAndRemoveEmptyFolder(docId)
            .then()
            .catch(() => setShowErrorDelete(true));

    }



    const linkTo = `/folders/${docId}`
    return (

        // <div className="rounded-xl hover:bg-grays-hoverGray hover:text-navy px-1 ">
        <div className="rounded-xl hover:bg-grays-hoverGray hover:text-navy px-1 pb-2">
            <div
                // border-bottom border-grays-lineGray border-b-2
                className="flex justify-between  hover:text-grays-headingTextGray  pt-4 py-1">
                <div className="w-full ">
                    <div className="grid grid-cols-4">
                        <div className="flex text-textGray pl-1">
                            <div className="pt-1" >
                              <FolderIcon/>
                            </div>
                            <Link to={linkTo}>
                                <div
                                    className="font-grotesk-semi-bold cursor-pointer text-lg pl-3 whitespace-normal break-words">
                                    {fileName}
                                </div>
                            </Link>
                        </div>
                        <div
                            className="font-grotesk-normal cursor-pointer text-textGray text-lg pl-10 place-self-center whitespace-normal break-words">
                            {date}
                        </div>
                        <div
                            className="font-grotesk-normal cursor-pointer text-textGray text-lg pl-10 place-self-center whitespace-normal break-words">
                            -
                        </div>
                        <div
                            className="font-grotesk-normal cursor-pointer text-textGray text-lg pl-10 place-self-center whitespace-normal break-words">
                            {author}
                        </div>
                    </div>
                </div>

                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <React.Fragment>
                            <IconButton
                                color="primary"
                                disableRipple={true}
                                sx={{color: "#6d6d6d"}}
                                {...bindTrigger(popupState)}

                            >
                                <MoreVert/>
                            </IconButton>


                            <Menu {...bindMenu(popupState)} disableScrollLock={true}>

                                {/*<div></div>*/}
                                <Link to={linkTo}>
                                    <div
                                        className="text-grays-headingTextGray flex font-grotesk-normal hover:bg-grays-hoverGray pt-1 pb-2 pr-2 pl-1 cursor-pointer">
                                        <div className="pl-3 pt-1">
                                            <RemoveRedEyeIcon fontSize={"small"}/>
                                        </div>
                                        <div className="pt-1 px-3">View</div>
                                    </div>
                                </Link>
                                <div
                                    className="text-grays-headingTextGray flex font-grotesk-normal hover:bg-grays-hoverGray pt-1 pb-2 pr-2 pl-1 cursor-pointer">
                                    <div className="pl-3 pt-1">
                                        <DeleteForever fontSize={"small"}/>
                                    </div>
                                    <div className="pt-1 px-3"
                                         onClick={
                                             () => deleteFolder()}
                                    >Delete folder</div>
                                </div>
                                {/*<div*/}
                                {/*    className="text-grays-headingTextGray flex font-grotesk-normal hover:bg-grays-hoverGray pt-1 pb-2 pr-2 pl-1">*/}
                                {/*    <div className="pl-3 pt-1">*/}
                                {/*        <DownloadIcon fontSize={"small"}/>*/}
                                {/*    </div>*/}
                                {/*    <div className="pt-1 px-3"*/}
                                {/*        //  onClick={*/}
                                {/*        // () => onClickDownload(docId)}*/}
                                {/*    >Download*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*/!*{canDelete(permissionObject) &&*!/*/}
                                {/*    <div*/}
                                {/*        className="text-grays-headingTextGray flex font-grotesk-normal hover:bg-grays-hoverGray pt-1 pb-2 pr-2 pl-1">*/}
                                {/*        <div className="pl-3 pt-1">*/}
                                {/*            <DeleteForeverIcon fontSize={"small"}/>*/}
                                {/*        </div>*/}
                                {/*        <div className="pt-1 px-3"*/}
                                {/*            //  onClick={*/}
                                {/*            // () => deleteDocument({docId: docId})}*/}
                                {/*        >Delete file*/}
                                {/*        </div>*/}
                                {/*    </div>*/}


                                {/*}*/}

                                {/*<MenuItem onClick={popupState.close}>My account</MenuItem>*/}
                                {/*<MenuItem onClick={popupState.close}>Logout</MenuItem>*/}
                            </Menu>
                        </React.Fragment>
                    )}
                </PopupState>
            </div>
            <Snackbar
                open={showErrorDelete}
                onClose={() => setShowErrorDelete(false)}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{zIndex:9000000}}
            >
                <Alert
                    severity="error"
                    onClose={() => setShowErrorDelete(false)}

                >
                    <div className="font-grotesk-normal">
                        Can't delete a folder that is not empty!
                    </div>

                </Alert>
            </Snackbar>



        </div>
    );
}

export default FolderListItem;