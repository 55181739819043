import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function AsyncActionButton({ initialText, loadingText, onClick }) {
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setLoading(true);
        onClick().then(() => {
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    return (
        <button
            onClick={!loading ? handleClick : undefined}
            className={`float-right text-center font-semibold text-sm text-white bg-grays-homePageLine border-2 border-gray-400
                        rounded-full py-2 px-4 uppercase text-xs ml-2 w-auto h-9 cursor-pointer hover:bg-grays-lineGray hover:border-gray-500
                        sm:text-xs sm:mt-1 sm:mb-1 ${loading && 'opacity-50 cursor-not-allowed'}  min-w-[15%]`}
            disabled={loading}
        >
            {/*{loading ? (loadingText || <CircularProgress color="inherit" size="20px" />) : initialText}*/}
            {loading ? <CircularProgress color="inherit" size="20px" /> : initialText}
        </button>
    );
}

export default AsyncActionButton;
