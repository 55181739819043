export const initialState = {
    modalIsOpen: false
};

export function signUpReducer(state, action) {
    switch (action.type) {
        case 'OPEN_MODAL':
            return { ...state, modalIsOpen: true };
        case 'CLOSE_MODAL':
            return { ...state, modalIsOpen: false };
        default:
            throw new Error();
    }
}

export function init(initialModalState) {
    return {
        modalIsOpen: initialModalState
    }
}
