import React, {useRef, useState} from 'react';
import {
    Box,
    ThemeProvider,
    Modal,
    TextField,
    createTheme,
    LinearProgress
} from "@mui/material";
import 'firebase/compat/auth';
import {grey} from "@mui/material/colors";
import styled from "styled-components";
import {httpsCallable} from "firebase/functions";

const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pb: 7,
    pr:4,
    pl:4,
    pt:4,
    borderRadius: 3,
    outline: 0,
    zIndex: 900000,
};

const modalMaterialTheme = createTheme({
    palette: {
        primary: {
            main: "#121640",
        },
        secondary: {
            main: grey[100],
        },
    },
});

function CreateNewFolderModal(props) {
    const {
        handleClose,
        open,
        appFirebase,
        docId
    } = props;
    const [shareSuccess, setShareSuccess] = useState(false);
    const [shareFailure, setShareFailure] = useState(false);
    const [loading, setLoading] = useState(false);
    const createNewFolderFunction = httpsCallable(appFirebase.function, 'folders-createNewFolder')

    const folderNameRef = useRef(null);

    const onClickCreate = async () => {
        if (loading) return;
        try {
            setLoading(true);
            const folderNameVal = folderNameRef.current?.value;
            await createNewFolderFunction({folderName: folderNameVal});
            handleClose();
        }
        catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
            handleClose();
        }
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                zIndex: '9000000000000 !important',
            }}
        >
            <ThemeProvider theme={modalMaterialTheme}>
                {loading && <LinearProgress/>}
                <Box sx={style} className="pb-5">

                    <PageSubHeading className="mt-2">New Folder</PageSubHeading>


                    <TextField
                        style={{marginBottom: "20px"}}
                        fullWidth="standard-basic"
                        label="Folder name"
                        type="email"
                        inputRef={folderNameRef}
                        // defaultValue={emailText}
                        variant="standard"
                    />

                    <div
                        className="float-right bg-primary text-white font-grotesk-bold cursor-pointer rounded-full px-5 py-1 mb-5 py-5"
                        onClick={() => onClickCreate()}
                    >
                        Create
                    </div>

                </Box>
            </ThemeProvider>
        </Modal>
    );
}

export default CreateNewFolderModal;

const PageSubHeading = styled.div`
  font-size: 24px;
  letter-spacing: -1px;
  line-height: 30px;
  padding-top: 10px;
  text-align: center;
  padding-left: 20px;
  color: #4d4d4d;
`;