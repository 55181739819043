import React, {useContext, useEffect, useRef, useState} from 'react';
import DocHeader from "./DocHeader";
import {useParams} from "react-router-dom";
import TopNavbar from "../../navbar/TopNavbar";
import {onValue, ref as Dref, on, off} from "firebase/database";
import './viewers/viewerStyle.scss';
import theme from '../../../style/theme';
import styled from "styled-components";
import {databaseTypesConstants as dbConst} from "../../../firebase/databaseConstants";
import {onAuthStateChanged} from "firebase/auth";
import {CircularProgress, ThemeProvider} from "@mui/material";
import {isDocumentPublic} from "../../../firebase/commonFunctions";
import {httpsCallable} from "firebase/functions";
import {modalMaterialTheme} from "../../common/muiThemes"
import {FirebaseContext} from "../../../contexts/FirebaseContext";
import Viewer from "./Viewer";
import SidePanelRight from "./SidePanel/SidePanelRight";
import { SidePanelProvider } from '../../../contexts/SidePanelRightContext';

function DocumentViewPage({
                              signedIn,
                              setSignInStatus,
                              uiConfig
                          }) {
    const {did} = useParams();
    const [documentDisplayUrl, setDocumentDisplayUrl] = useState(null);
    const [documentDownloadUrl, setDocumentDownloadUrl] = useState(null);
    const [documentPid, setDocumentPid] = useState(null);
    const [fileEnding, setFileEnding] = useState("");
    const [permissionObject, setPermissionObject] = useState(null);
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [authUser, setAuthUser] = useState(null);
    const [openComments, setOpenComments] = useState(true);
    const [docName, setDocName] = useState("");
    const [openSidePanel, setOpenSidePanel] = useState(true);
    const [hasAccess, setHasAccess] = useState(null);
    const appFirebase = useContext(FirebaseContext);
    const viewerRef = useRef(null);
    const [viewerRefFinishedLoading, setViewerRefFinishedLoading] = useState(false);
    const [hasParts, setHasParts] = useState(null);

    const getDocumentUrl = httpsCallable(appFirebase.function, "document-getDocumentUrlForDisplay");
    // console.log(documentDisplayUrl)
    useEffect(() => {
        return onAuthStateChanged(
            appFirebase.auth,
            (user) => setAuthUser(user ?? false)
        );
    }, [appFirebase]);

    useEffect(async () => {
            if (documentPid) {
                onValue(Dref(appFirebase.database, `/${dbConst.PERMISSIONS.name}/${documentPid}`),
                    (permission => {
                        setPermissionObject(permission.val())
                    }));
            }
    }, [documentPid]);

    //Getting document url
    useEffect(() => {
        getDocumentUrl({docId: did})
            .then(result => {
                // Read result of the Cloud Function.
                const data = result.data;
                setDocumentDisplayUrl(data.documentDisplayUrl);
                setDocumentDownloadUrl(data.documentDownloadUrl);
                setFileEnding(data.fileEnding);
                setFinishedLoading(true)
                setHasAccess(true)
                setDocName(data.fileName);
                setDocumentPid(data.pid);
            })
            .catch(error => {
                // Getting the Error details.
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.error(`Error getting document URL. Code: ${code}. Message: ${message}. Details: ${details}`);
                setHasAccess(false)
            });
    }, []);

    //Adding Segment Analytics page function on mount
    useEffect(() => {
        return () => {
            //Segment pageview call
            window.analytics.page();
        };
    }, []);

    return (
        <div>
            <TopNavbar
                appFirebase={appFirebase}
                signedIn={signedIn}
                setSignInStatus={setSignInStatus}
                uiConfig={uiConfig}
                displayAvatar={true}
                isDocPage={true}
            />
            <SidePanelProvider>
            {hasAccess === false ?
                <div
                    className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-grotesk-normal text-center">
                    You do not have permission to view this document.
                </div>
                :
                <>
                        <div>
                            {documentDownloadUrl && <DocHeader

                                docId={did}
                                signedIn={signedIn}
                                url={documentDownloadUrl}
                                permissionObject={permissionObject}
                                docName={docName}
                            />}
                            {documentDisplayUrl && finishedLoading ?
                                <Viewer
                                    fileEnding={fileEnding}
                                    did={did}
                                    documentUrl={documentDisplayUrl}
                                    permissionObject={permissionObject}
                                    viewerRef={viewerRef}
                                    viewerRefFinishedLoading={viewerRefFinishedLoading}
                                    setViewerRefFinishedLoading={setViewerRefFinishedLoading}
                                    setHasParts={setHasParts}
                                />
                                :
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                    <ThemeProvider theme={modalMaterialTheme}>
                                        <CircularProgress className="align-self-center"/>
                                    </ThemeProvider>
                                </div>

                            }
                        </div>

                </>

            }
                <SidePanelRight
                    signedIn={signedIn}
                    docId={did}
                    ref={viewerRef}
                    setViewerRefFinishedLoading={setViewerRefFinishedLoading}
                    hasParts={hasParts}
                />
                </SidePanelProvider>
        </div>
    );
}

export default DocumentViewPage;

const NoAccessContainer = styled.div`
  .noAccessContainer {
    padding-top: 11em;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-family: ${theme.font.normal};
      color: #6d6d6d;
    }
  }
`;